import { GridColumn } from "semantic-ui-react";
import { useState } from "react";
import Chart from "react-apexcharts";

import { observer } from "mobx-react-lite";

interface IProps {
    companies: number[]
    products: number[]
}

const StatisticsLineChart: React.FC<IProps> = ({ companies, products }) => {
    const d = new Date();
    const months = ["Janvier", "Février", "Mars", "Avril", "Mai", "Juin", "Juillet", "Août", "Septembre", "Octobre", "Novembre", "Décembre"];
    const [state] = useState({
        options: {
            colors: ['#FEBE39', '#EA5826'],
            fill: { type: 'gradient' },
            xaxis: {
                categories: months.splice(d.getMonth() + 1, 12 - d.getMonth() + 1).concat(months.splice(0, d.getMonth() + 1)),

            },
            
        },
    })
    return (

        <GridColumn style={{ borderRadius: 5, minHeight: 350 }}>
            <span style={{ fontSize: 18, fontWeight: 700, fontFamily: 'serif', width: 300, position: 'absolute', left: 70, top: 18 }}>Nombre de produits et clients ajoutés au cours des 12 derniers mois</span>

            <Chart
                options={state.options}
                series={[

                    {
                        name: "Clients",
                        data: companies
                    },
                    {
                        name: "Produits",
                        data: products
                    },
                ]}
                type="line"
                width="90%"
            />
        </GridColumn>
    )
}
export default observer(StatisticsLineChart);