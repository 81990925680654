import { IArticle, IPhotos, IArticleEnvelope, IProduct, IProductList, IProductFournisseur, ArticleServiceEnvelope, ICFList, IVideo } from '../models/IArticle';
import axios, { AxiosResponse, AxiosError } from 'axios';
import { toast } from 'react-toastify';
import { history } from '../..';
import { IUserFromValues, IUser, IUserCompany, IPassword } from '../models/IUser';
import { IProfil, IPhoto, IUserFranchiseur } from '../models/IProfil';
import { IClient, IClientEnveloppe, IClientInfoEnveloppe, ICompanyList, IFichier, IPersonalPhoto, IRequest, ISolde, ISoldeEnveloppe } from '../models/IClient';
import { PaginatedResult } from '../models/IPagination';
import { ICompany, ICompanyPack, IFranchiseur, IFranchiseurEnveloppe, IFranchiseurIClientList, IFranchiseurList } from '../models/IFranchiseur';
import { ICountPaiement, IModePaiement } from '../models/IModePaiement';
import { ICountReglement, IModeReglement } from '../models/IModeReglement';
import { IFormeJuridique } from '../models/IFormeJuridique';
import { IFournisseur, IFournisseurEnveloppe, ISupplierDetail } from '../models/IFournisseur';
import { IFamille, IFamilleList, IFamillesEnvelope, ITreeSousFamille } from '../models/IFamille';
import { ICommandeEnvelope, ICommande, IOrderLine, ISupplierList, ISupplier, IPrices, IFournisseurList, IOrder, OrderCompanyDto, ICompanyOrder, ICommandeListEnvelope } from '../models/ICommande';
import { IDevisEnvelope, IDevis } from '../models/IDevis';
import fileDownload from 'js-file-download';
import { ITarifFournisseur } from '../models/ITarifFournisseur';
import { ITva } from '../models/ITva';
import { IGroupProductPriceList, IProductPrice, IProductPriceAdd, ITarifFranchiseur } from '../models/ITarifFranchiseur';
import { IAdresse } from '../models/IAdresse';
import { IEntrepot } from '../models/IEntrepot';
import { IAlertStockEnvelope, IArticleStock } from '../models/IArticleStock';
import { IMouvementStock, IMouvementStockEnvelope } from '../models/IMouvementStock';
import { ICommandeFournisseur, ICommandeFournisseurEnvelope, ICommandeFournisseurForAlert, IOrderDelivery, IOrderFournisseurLine, IOrderSupplier, IRelatedElement } from '../models/ICommandeFournisseur';
import { IImpression } from '../models/IImpression';
import { IOption } from '../models/IOption';
import { ISupport } from '../models/ISupport';
import { IStatistic } from '../models/IStatistic';
import { IAccount } from '../models/IAccount';
import { IReglement, IReglementEnvelope, IReglementNotConsumedByClientEnveloppe, IReglementNotConsumedEnvelope } from '../models/IReglement';
import { IArticleCompose, IArticlePack } from '../models/IArticleCompose';
import { IEntrepotByAticleEnModeAppro } from '../models/IEntrepotByAticleEnModeAppro';
import { IBonDePreparation, IDetailPreparation, IPreparation, IPreparationCommande, IPreparationEnvelope } from '../models/IPreparation';
import { IOptionArticle } from '../models/IOptionArticle';
import { ITypeMarche } from "../models/ITypeMarche";
import { IFacture, IFactureEnvelope, IGroupInvoiceDto, IInvoiceDto, IInvoiceEnvelope, IInvoiceLine, IListRelatedElements, InvoiceCompanyDto, IRemiseAvoir } from '../models/IFacture';
import { IDetailExpedition, IDispatch, IDispatchOrder, IExpeditionCommande, IExpeditionEnv, IExpeditionEnvelope, IExpeditionLines, IOrderDispatch, IReliquatsEnvelope } from '../models/IExpedition';
import { IReceptionLineUpdated, IPartialReception, IReceptionEnvelope, IReceptionLineEnvelope, IReceptionPartial, IReception } from '../models/IReception';
import { IFactureFournisseur, IFactureFournisseurAdd, IFactureFournisseurEnvelope, IFactureFournisseurLine } from '../models/IFactureFournisseur';
import { IAttributes, IProductAttributes } from '../models/IProductAttributes';
import { ICityCode } from '../models/ICityCode';
import { ICountryCode } from '../models/ICountryCode';
import { ISpecialOffer, ISpecialOfferEnveloppe, ISpecialPrices } from '../models/ISpecialOffer';
import { IPermissionsRoleModel, IRole, IUserClaims, IUserModel, IUserRoleModel } from '../models/IUsersRoles';
import { IInventory, IInventoryCompany } from '../models/IInventory';

axios.defaults.baseURL = process.env.REACT_APP_API_URL;

const sleep = (delay: number) => {
    return new Promise((resolve) => {
        setTimeout(resolve, delay)
    })
}

//we can found this modifcations in (inspector => Network => request => header)
axios.interceptors.request.use(
    config => {
        const token = window.localStorage.getItem('jwt');
        if (token) config.headers.Authorization = `Bearer ${token}`;
        return config;
    },
    error => {
        return Promise.reject(error);
    }
);

axios.interceptors.response.use(async response => {
    await sleep(1000);
    const pagination = response.headers['pagination'];
    if (pagination) {
        response.data = new PaginatedResult(response.data, JSON.parse(pagination));
        return response as AxiosResponse<PaginatedResult<any>>;
    }
    return response as AxiosResponse<PaginatedResult<any>>;

}, (error: AxiosError) => {

    const { data, status, config, headers } = error.response!;

    if (error.message === 'Network Error' && !error.response)
        toast.error('Network error - make sure API is running!')
    if (!error.response)
        toast.error("Network error - Please check your connection ");
    if (status === 404)
        history.push('/notfound');
    if (status === 401 && headers['www-authenticate'].startsWith('Bearer error="invalid_token"')) {
        window.localStorage.removeItem('jwt');
        history.push('/');
        toast.info("Your session is expired, please login again");
    }
    if (status === 400 && config.method === 'get' && data.errors.hasOwnProperty('id')) {
        if (typeof data === 'string') {
            toast.error(data);
            history.push('/');
        }
        if (config.method === 'get' && data.errors.hasOwnProperty('id')) {
            history.push('/notfound');
        }
        if (data.errors) {
            const modalStateErrors = [];
            for (const key in data.errors) {
                if (data.errors[key]) {
                    modalStateErrors.push(data.errors[key]);
                }
            }
            throw modalStateErrors.flat();
        }
    }
    if (status === 400 && config.method === 'delete') {
        toast.warning(data);
    }
    if (status === 400 && config.method === 'post') {
        toast.warning(data);
    }
    if (status === 400 && config.method === 'put') {
        toast.warning(data);
    }
    if (status === 500) {
        if (config.method === 'delete')
            toast.warning("Problème de suppréssion !!!")
        else if (config.method === 'get')
            toast.warning("Donnée introuvable !!!");
        else history.push('/server-error');
    }
    return Promise.reject(error);
});

const responseBody = <T>(response: AxiosResponse<T>) => response.data;

//Common Requests
const requests = {
    get: <T>(url: string) => axios.get<T>(url).then(responseBody),
    post: <T>(url: string, body: {}) => axios.post<T>(url, body).then(responseBody),
    put: <T>(url: string, body: {}) => axios.put<T>(url, body).then(responseBody),
    patch: <T>(url: string, body: {}) => axios.patch<T>(url, body).then(responseBody),
    del: <T>(url: string) => axios.delete<T>(url).then(responseBody),
    postForm: <T>(url: string, file: Blob) => {
        let formData = new FormData();
        formData.append('File', file);
        return axios.post<T>(url, formData, {
            headers: { 'content-type': 'multipart/form-data' }
        }).then(responseBody)
    }
}

//Response Clients API
const clientAgent = {
    list: (params: URLSearchParams) => axios.get<IClientEnveloppe>('/client', { params: params }).then(responseBody),
    listClientsFranchise: (id: number, params: URLSearchParams) => axios.get<IClientEnveloppe>(`/client/clientsFranchise/${id}`, { params: params }).then(responseBody),
    details: (id: number) => requests.get<IClient>(`/client/${id}`),
    getLastClient: (id: number, type: string) => requests.get<number>(`/client/GetLastClient/${id}/${type}`),
    update: (client: Partial<IClient>) => requests.put(`/client/${client.id}`, client),
    updateGestionCommercial: (client: Partial<IClient>) => requests.put(`/client/${client.id}/updateGestionCommercial`, client),
    updateSpecification: (client: Partial<IClient>) => requests.put(`/client/${client.id}/updateSpecification`, client),
    delete: (id: number) => requests.del(`/client/${id}`),
    enable: (id: number) => requests.post(`/client/${id}/enable`, {}),
    disable: (id: number) => requests.post(`/client/${id}/disable`, {}),
    uploadPhoto: (photo: Blob, fileName: string, id: number) => requests.postForm<IPersonalPhoto>(`/client/${id}/upload/${fileName}`, photo),
    uploadClient: (file: Blob, id: string) => requests.postForm<IPhoto>(`/client/${id}/addClient`, file),
    setMainPhoto: (id: string, idClient: number) => requests.post(`/client/${id}/${idClient}/setmain`, {}),
    deletePhoto: (id: number, idClient: number) => requests.del(`/client/${id}/${idClient}`),
    updateGestionMemo: (client: Partial<IClient>) => requests.put(`/client/${client.id}/updateGestionMemo`, client),
    uploadFichier: (fichier: Blob, id: number) => requests.postForm<IFichier>(`/client/${id}/uploadFile`, fichier),
    deleteFichier: (id: string, idClient: number) => requests.del(`/client/${id}/deleteFile/${idClient}`),
    generateAccountClient: (id: number) => requests.put(`/client/${id}/generateAccountClient`, {}),
    updateComptabilite: (client: Partial<IClient>) => requests.put(`/client/${client.id}/updateComptabilite`, client),
    create: (client: Partial<IClient>, id: string) => requests.post(`/client/addClient/${id}`, client),
    companiesList: (search?: string | null) => axios.get<ICompanyList[]>(`/client/CompaniesList/${search}`),
    listBySearch: (id: number, type: string) => requests.get<ICompanyList[]>(`/client/getBySearch/${id}/${type}`),
    addFranchise: (file: Blob) => requests.postForm<IClientInfoEnveloppe>(`/client/addFranchise`, file),
    exportExcel: (id: number, params: URLSearchParams) => axios.get(`/client/listFranchiseExporte/${id}`, { params: params, responseType: 'blob' }).then((res) => { fileDownload(res.data, res.headers["filename"]) }),
    listSolde: (obj: URLSearchParams) => axios.get<ISoldeEnveloppe>('/client/getSoldes', { params: obj }).then(responseBody),
    exportSolde: (obj: any) => axios.get('/client/exportSoldes', { responseType: 'blob', params: obj }).then((res) => { fileDownload(res.data, "Soldes.xlsx") }),
    searchClient: (search?: string | null) => axios.get<ICompanyList[]>(`/client/searchClient/${search}`),
    exportData: (obj: any) => axios.get('/client/exportData', { responseType: 'blob', params: obj }).then((res) => { fileDownload(res.data, "Statics.xlsx") }),
    changeType: (id: number) => requests.put<Partial<IClient>>(`/client/changeType`, { id }),
}
//Response Franchiseur API
const franchiseurAgent = {
    list: (params: URLSearchParams) => axios.get<IFranchiseurEnveloppe>('/franchiseur', { params: params }).then(responseBody),
    details: (id: number) => requests.get<IFranchiseur>(`/franchiseur/${id}`),
    detailCompany: (id: number) => requests.get<ICompany>(`/franchiseur/company/${id}`),
    create: (franchiseur: IFranchiseur) => requests.post(`/franchiseur/addFranchiseur`, franchiseur),
    update: (franchiseur: Partial<IFranchiseur>) => requests.put(`/franchiseur/${franchiseur.id}`, franchiseur),
    updateGestionCommercial: (franchiseur: Partial<IFranchiseur>) => requests.put(`/franchiseur/${franchiseur.id}/updateGestionCommercial`, franchiseur),
    delete: (id: number) => requests.del(`/franchiseur/${id}`),
    enable: (id: number) => requests.post(`/franchiseur/${id}/enable`, {}),
    disable: (id: number) => requests.post(`/franchiseur/${id}/disable`, {}),
    updateSpecification: (franchiseur: Partial<IFranchiseur>) => requests.put(`/franchiseur/${franchiseur.id}/updateSpecification`, franchiseur),
    uploadPhoto: (photo: Blob, id: number) => requests.postForm<IPhoto>(`/franchiseur/${id}/upload`, photo),
    setMainPhoto: (id: string, idFranchiseur: number) => requests.post(`/franchiseur/${id}/${idFranchiseur}/setmain`, {}),
    deletePhoto: (id: string, idFranchiseur: number) => requests.del(`/franchiseur/${id}/${idFranchiseur}`),
    uploadFichier: (fichier: Blob, id: number) => requests.postForm<IFichier>(`/franchiseur/${id}/uploadFile`, fichier),
    deleteFichier: (id: string, idFranchiseur: number) => requests.del(`/franchiseur/${id}/deleteFile/${idFranchiseur}`),
    updateComptabilite: (franchiseur: Partial<IFranchiseur>) => requests.put(`/franchiseur/${franchiseur.id}/updateComptabilite`, franchiseur),
    listFranchiseurIClien: (search?: string | null) => axios.get<IFranchiseurIClientList[]>(`/franchiseur/getSearch/${search}`),
    listFranchiseur: (search?: string | null) => axios.get<IFranchiseurList[]>(`/franchiseur/getSearchByFranchiseur/${search}`),
    listFranchiseurByTarifAchat: (search: string) => requests.get<IFranchiseur[]>(`/franchiseur/listByTarif/${search}`),
    getFranchiseurName: (companyName: string, socailName: string) => requests.get<boolean>(`/franchiseur/GetName/${companyName}/${socailName}`),
    exportExcel: () => axios.get('/franchiseur/listFranchisseurExporte', { responseType: 'blob' }).then((res) => { fileDownload(res.data, "franchiseurs.xlsx") }),
    exportAllCompanies: () => axios.get('/franchiseur/ExportAllCompanies', { responseType: 'blob' }).then((res) => { fileDownload(res.data, "Clients.xlsx") }),
    exportOpeningPack: (id: number) => requests.get<ICompanyPack[]>(`/franchiseur/exportOpeningPack/${id}`),
    getSearchByFranchiseurs: (search?: string | null) => axios.get<IFranchiseurList[]>(`/franchiseur/getSearchByFranchiseurs/${search}`),
    exportCommission: (obj: any) => axios.get('/franchiseur/commissions', { responseType: 'blob', params: obj }).then((res) => { fileDownload(res.data, res.headers["filename"]) }),
}
//Response Fournisseur API
const fournisseurAgent = {
    list: (params: URLSearchParams) => axios.get<IFournisseurEnveloppe>('/fournisseur', { params: params }).then(responseBody),
    details: (id: number) => requests.get<IFournisseur>(`/fournisseur/${id}`),
    create: (fournisseur: IFournisseur) => requests.post(`/fournisseur/addFournisseur`, fournisseur),
    update: (fournisseur: Partial<IFournisseur>) => requests.put(`/fournisseur/${fournisseur.fournisseurId}`, fournisseur),
    updateComptabilite: (fournisseur: Partial<IFournisseur>) => requests.put(`/fournisseur/${fournisseur.fournisseurId}/updateComptabilite`, fournisseur),
    enable: (id: number) => requests.post(`/fournisseur/${id}/enable`, {}),
    disable: (id: number) => requests.post(`/fournisseur/${id}/disable`, {}),
    uploadFichier: (fichier: Blob, id: number) => requests.postForm<IFichier>(`/fournisseur/${id}/uploadFile`, fichier),
    deleteFichier: (id: string, idFournisseur: number) => requests.del(`/fournisseur/${id}/deleteFile/${idFournisseur}`),
    updateAchat: (fournisseur: Partial<IFournisseur>) => requests.put(`/fournisseur/${fournisseur.fournisseurId}/updateAchat`, fournisseur),
    updateComplementInfos: (fournisseur: Partial<IFournisseur>) => requests.put(`/fournisseur/${fournisseur.fournisseurId}/updateComplementInfos`, fournisseur),
    listFournisseurByTarifAchat: (search: string) => requests.get<ISupplierDetail[]>(`/fournisseur/listByTarif/${search}`),
    listFournisseur: (search?: string | null) => axios.get<IFournisseurList[]>(`/fournisseur/getSearchByFournisseur/${search}`),
    uploadPhoto: (photo: Blob, id: number) => requests.postForm<IPhoto>(`/fournisseur/${id}/upload`, photo),
    setMainPhoto: (id: string, idFournisseur: number) => requests.post(`/fournisseur/${id}/${idFournisseur}/setmain`, {}),
    deletePhoto: (id: string, idFournisseur: number) => requests.del(`/fournisseur/${id}/${idFournisseur}`),
    exportAllSuppliers: () => axios.get('/fournisseur/exportSuppliers', { responseType: 'blob' }).then((res) => { fileDownload(res.data, "Fournisseurs.xlsx") }),

}

//Response User API
const userAgent = {
    register: (user: IUserFromValues) => requests.post<IUser>(`/user/register`, user),
    login: (user: IUserFromValues) => requests.post<IUser>(`/user/login`, user),
    current: () => requests.get<IUser>('/user'),
    verifyEmail: (token: string, email: string, password: string) => requests.post<IUser>(`/user/verifyEmail`, { token, email, password }),
    resendEmailConfirm: (email: string) => requests.get(`/user/resendEmailVerification?email=${email}`),
    fbLogin: (accessToken: string) => requests.post(`/user/facebook`, { accessToken }),
    refreshToken: () => requests.post<IUser>(`/user/refreshToken`, {}),
    getuser: (id: number) => requests.get<IUserCompany[]>(`/user/userCompany/${id}`),
    sendInfo: (id: number, creationDate: string) => requests.post(`/user/sendloginInfo/${id}`, { creationDate }),
    create: (user: IUserCompany) => requests.post(`/user/addUser`, user),
    update: (user: IUserCompany) => requests.put(`/user/updateUser`, user),
    updatePassword: (password: IPassword) => requests.put(`/user/updatePassword`, password),
    delete: (id: string) => requests.del<IUserCompany>(`/user/${id}`),
    setMainContact: (id: string, userCompany: IUserCompany) => requests.put(`/user/setMainContact/${id}`, userCompany),
}

//Response Adresse API
const adresseAgent = {
    list: () => requests.get<IAdresse[]>(`/adresse/getAdresses`),
    create: (adresse: IAdresse) => requests.post<string>(`/adresse`, adresse),
    delete: (id: string) => requests.del<IAdresse>(`/adresse/${id}`),
    update: (adresse: Partial<IAdresse>) => requests.put(`/adresse/${adresse.id}`, adresse),
    details: (id: string) => requests.get<IAdresse>(`/adresse/${id}`),
    setMainAdress: (id: string, isMain: string) => requests.post(`/adresse/setmain/${isMain}/${id}`, {}),
    getAdresseBycompany: (id: number) => requests.get<IAdresse[]>(`/adresse/getCompanyAdresse/${id}`),
}

//Response ModePaiement API
const modePaiementAgent = {
    list: () => requests.get<IModePaiement[]>(`/modePaiement`),
    create: (modePaiement: IModePaiement) => requests.post(`/modePaiement`, modePaiement),
    delete: (id: string) => requests.del<IModePaiement>(`/modePaiement/${id}`),
    update: (modePaiement: Partial<IModePaiement>) => requests.put(`/modePaiement/${modePaiement.paiementId}`, modePaiement),
    details: (id: string) => requests.get<IModePaiement>(`/modePaiement/${id}`),
    countPaiement: (id: string) => requests.get<ICountPaiement>(`/modePaiement/${id}/countPaiement`),
}

//Response ModeReglement API
const modeReglementAgent = {
    list: () => requests.get<IModeReglement[]>(`/modeReglement`),
    create: (modeReglement: IModeReglement) => requests.post(`/modeReglement`, modeReglement),
    delete: (id: string) => requests.del<IModeReglement>(`/modeReglement/${id}`),
    update: (modeReglement: Partial<IModeReglement>) => requests.put(`/modeReglement/${modeReglement.reglementId}`, modeReglement),
    details: (id: string) => requests.get<IModeReglement>(`/modeReglement/${id}`),
    countReglement: (id: string) => requests.get<ICountReglement>(`/modeReglement/${id}/countReglement`),
}

//Response Famille API
const familleAgent = {
    list: (params: URLSearchParams) => axios.get<IFamillesEnvelope>(`/famille`, { params: params }).then(responseBody),
    listSous_Famille: (params: URLSearchParams, id: string) => axios.get<IFamillesEnvelope>(`/famille/${id}/sous_Familles`, { params: params }).then(responseBody),
    create: (famille: IFamille) => requests.post(`/famille`, famille),
    delete: (id: string) => requests.del<IFamille>(`/famille/${id}`),
    update: (famille: Partial<IFamille>) => requests.put<IFamille>(`/famille/${famille.familleId}`, famille),
    listSousFamille: (id: string) => requests.get<ITreeSousFamille[]>(`/famille/${id}/sousFamille`),
    listFamille: (search?: string | null) => axios.get<IFamilleList[]>(`/famille/getSearchByFamille/${search}`),
}

//Response FormeJuridique API
const formeJuridiqueAgent = {
    list: () => requests.get<IFormeJuridique[]>(`/formeJuridique`),
    create: (formeJuridique: IFormeJuridique) => requests.post(`/formeJuridique`, formeJuridique),
    delete: (id: string) => requests.del<IFormeJuridique>(`/formeJuridique/${id}`),
    update: (formeJuridique: Partial<IFormeJuridique>) => requests.put(`/formeJuridique/${formeJuridique.formeJuridiqueId}`, formeJuridique),
    details: (id: string) => requests.get<IFormeJuridique>(`/formeJuridique/${id}`),
}

//Response Articles API
const articleAgent = {
    list: (params: URLSearchParams) => axios.get<IArticleEnvelope>('/article', { params: params }).then(responseBody),
    details: (id: number) => requests.get<IArticle>(`/article/${id}/getArticleById`),
    create: (article: IArticle) => requests.post(`/article/addArticles`, article),
    duplicate: (Product: IArticle) => requests.post<number>(`/article/duplicate`, Product),
    update: (article: Partial<IArticle>) => requests.put<IArticle>(`/article/${article.productId}`, article),
    uploadPhoto: (photo: Blob, id: number) => requests.postForm<IPhotos>(`/article/${id}/upload`, photo),
    uploadVideo: (video: Blob, id: number) => requests.postForm<IVideo>(`/article/${id}/uploadVideo`, video),
    updatePhoto: (photo: Blob, id: number, idPhoto: string) => requests.postForm<IPhotos>(`/article/${id}/${idPhoto}/update`, photo),
    setMainPhoto: (id: string, idArticle: number) => requests.post(`/article/${id}/${idArticle}/setmain`, {}),
    deletePhoto: (id: string, idArticle: number) => requests.del(`/article/${id}/${idArticle}`),
    enable: (id: number) => requests.post(`/article/${id}/enable`, {}),
    disable: (id: number) => requests.post(`/article/${id}/disable`, {}),
    getArticleFranchiseur: (id: number, type: string) => requests.get<IProduct[]>(`/article/getArticleByFranchiseur/${id}/${type}`),
    updateIncrementation: (article: Partial<IArticle>) => requests.put(`/article/${article.productId}/updateIncrementation`, article),
    detailsByCode: (code: number) => requests.get<IArticle>(`/article/articleByCode/${code}`),
    listForPack: (id: string) => requests.get<IProductList[]>(`/article/articlesForPack/${id}`),
    listArticlesFournisseur: (id: number) => requests.get<IProductFournisseur[]>(`/article/articlesFournisseur/${id}`),
    listArticlesFranchiseur: (id: number) => requests.get<IProductFournisseur[]>(`/article/articlesFranchiseur/${id}`),
    listDeliveryDelay: () => requests.get<string[]>(`/article/deliveryDelay`),
    listCF: (id: number) => requests.get<ICFList[]>(`/article/listCF/${id}`),
    getService: (params: URLSearchParams) => axios.get<ArticleServiceEnvelope>(`/article/getServices`, { params: params }).then(responseBody),
    getArticleCompany: (id: number, search: string | null) => axios.get<IProduct[]>(`/article/getArticleByCompany/${id}/${search}`),
    getSearchArticle: (search: string | null) => axios.get<IProduct[]>(`/article/getSearchArticle/${search}`),
    exportArticle: (obj: any) => axios.get('/article/exportArticle', { responseType: 'blob', params: obj }).then((res) => { fileDownload(res.data, "Article.xlsx") }),
    exportService: (obj: any) => axios.get('/article/exportService', { responseType: 'blob', params: obj }).then((res) => { fileDownload(res.data, "ArticleService.xlsx") }),
    uploadFichier: (fichier: Blob, id: number) => requests.postForm<IFichier>(`/article/${id}/uploadFile`, fichier),
    deleteFichier: (id: string, idProduct: number) => requests.del(`/article/${id}/deleteFile/${idProduct}`),
    deleteVideo: (id: number, idProduct: number) => requests.del(`/article/${id}/deleteVideo/${idProduct}`),
}

//Response Profil API
const profilAgent = {
    detail: (username: string) => requests.get<IProfil>(`/profil/${username}`),
    editProfile: (profil: Partial<IProfil>) => requests.put(`/profil/verifyPassword`, profil),
    uploadPhoto: (photo: Blob) => requests.postForm<IPhoto>(`/profil`, photo),
    follow: (username: string) => requests.post(`/profil/${username}/follow`, {}),
    unfollow: (username: string) => requests.del(`/profil/${username}/unfollow`),
    setMainPhoto: (id: string) => requests.post(`/profil/${id}/setmain`, {}),
    deletePhoto: (id: string) => requests.del(`/profil/${id}`),
    listFranchiseur: (username: string, predicate: string) => requests.get<IUserFranchiseur[]>(`/profil/${username}/franchiseurs?predicate=${predicate}`),
}

//Response Commande API
const commandeAgent = {
    list: (params: URLSearchParams) => axios.get<ICommandeEnvelope>('/commande', { params: params }).then(responseBody),
    details: (id: string) => requests.get<ICommande>(`/commande/${id}/getCommandeById`),
    create: (commande: Partial<ICommande>) => requests.post<number>(`/commande/addCommande`, commande),
    update: (commande: Partial<ICommande>) => requests.patch<ICommande>(`/commande/${commande.id}`, commande),
    addOrderLine: (orderLine: IOrderLine, id: number) => requests.post<IPrices>(`/commande/${id}/addOrderLine`, orderLine),
    deleteCommandeArticle: (id: number) => requests.del<IPrices>(`/commande/${id}/deleteOrderLine`),
    enable: (id: string) => requests.post(`/commande/${id}/enable`, {}),
    disable: (id: string) => requests.post(`/commande/${id}/disable`, {}),
    isRead: (id: string) => requests.post(`/commande/${id}/isRead`, {}),
    updateAdresseCommande: (commande: Partial<ICommande>) => requests.patch(`/commande/${commande.id}/updateAdressesCommande`, commande),
    remiseGlobale: (commande: Partial<ICommande>, typeRemise: string) => requests.patch(`/commande/${commande.id}/${typeRemise}/remiseGlobale`, commande),
    updateInformationClientCommande: (commande: Partial<ICommande>) => requests.patch(`/commande/${commande.id}/updateInformationClientCommande`, commande),
    duplicate: (id: number) => requests.post<number>(`/commande/${id}/duplicateCommande`, {}),
    updateOrderLine: (id: number, orderLine: Partial<IOrderLine>) => requests.put<IPrices>(`/commande/${id}`, orderLine),
    detailOrderLine: (id: number) => requests.get<IOrderLine>(`/commande/${id}/getOrderLineById`),
    acompte: (commande: Partial<ICommande>) => requests.patch(`/commande/${commande.id}/montantAcompte`, commande),
    createDevis: (commande: Partial<ICommande>) => requests.post(`/commande/addDevis`, commande),
    generatePDF: (id: string) => axios.get(`/commande/${id}/CommandePdf`, { responseType: 'blob' }).then((res) => { fileDownload(res.data, res.headers["filename"]) }),
    //detailsCommandeByArticleEnModeAppro: (id: number) => requests.get<boolean>(`/commande/getCommandeByArticleEnModeAppro/${id}`),
    isValide: (id: number) => requests.post<string>(`/commande/${id}/isValide`, {}),
    enAttente: (id: number) => requests.post(`/commande/${id}/enAttente`, {}),
    generateFacture: (id: number) => requests.post(`/commande/${id}/CommandeFacture`, {}),
    deleteOrder: (id: number) => requests.del(`/commande/${id}/DeleteOrder`),
    getFournisseurByArticle: (id: number) => requests.get<ISupplierList[]>(`/commande/${id}/getFournisseurByArticle`),
    genererOrderFournisseur: (commandeFournisseur: Partial<ISupplier>) => requests.post(`/commande/GenererCommandeFournisseur`, commandeFournisseur),
    generatePackOuverture: (id: number) => requests.put(`/commande/GenerateOrderPack/${id}`, id),
    generateFactureByListOrders: (ids: number[], type: string) => axios.post(`/commande/facturerliste`, { ids, type }),
    cancel: (id: number) => requests.put(`/commande/${id}/cancel`, id),
    editDeliveryCost: (obj: any) => requests.put<IPrices>(`/commande/editDeliveryCost`, obj),
    dispatchOrder: (id: number) => requests.post(`/commande/dispatchCommande/${id}`, id),
    updateModeApproProduct: (obj: Partial<IOrderLine>) => requests.put<string>(`/commande/updateModeApproProduct`, obj),
    getCommandeByCompany: (id: number) => requests.get<OrderCompanyDto[]>(`/commande/getCommandeByCompany/${id}`),
    getCompanyOrders: (id: number) => requests.get<ICompanyOrder[]>(`/commande/getCompanyOrders/${id}`),
    exportExcel: (obj: any) => axios.get('/commande/listCommandesExporte', { responseType: 'blob', params: obj }).then((res) => { fileDownload(res.data, "Commandes.xlsx") }),
    getCustomizedORderLines: (id: number) => requests.get<IOrderLine[]>(`/commande/getCustomizedOrderLine/${id}`),
    getCustomizedPDF: (id: number, face: string) => axios.get(`/commande/getCustomizedFile/${id}/${face}`, { responseType: 'blob' }).then((res) => { fileDownload(res.data, res.headers["filename"]) }),
    getCommandeList: (obj: URLSearchParams) => axios.get<ICommandeListEnvelope>(`/commande/listCommandes`, { params: obj }).then(responseBody),
    exportExcelCmdAttente: (obj: any) => axios.get('/commande/exportCommandeAttente', { responseType: 'blob', params: obj }).then((res) => { fileDownload(res.data, "CommandesAttente.xlsx") }),
    uploadProforma: (id: number) => axios.get(`/commande/${id}/Proforma`, { responseType: 'blob' }).then((res) => { fileDownload(res.data, res.headers["filename"]) }),


}

//Response Devis API
const devisAgent = {
    list: (params: URLSearchParams) => axios.get<IDevisEnvelope>('/devis', { params: params }).then(responseBody),
    details: (id: string) => requests.get<IDevis>(`/devis/${id}/getDevisById`),
    create: (devis: Partial<IDevis>) => requests.post(`/devis/addDevis`, devis),
    update: (devis: Partial<IDevis>) => requests.patch(`/devis/${devis.id}`, devis),
    addQuoteLine: (quoteLine: IOrderLine, id: number) => requests.post<IPrices>(`/devis/${id}/addQuoteLine`, quoteLine),
    deleteDevisArticle: (id: number) => requests.del<IPrices>(`/devis/${id}/deleteQuoteLine`),
    updateAdresseDevis: (id: number, idAdresse: string) => requests.patch(`/devis/${id}/${idAdresse}/updateAdressesDevis`, {}),
    remiseGlobale: (devis: Partial<IDevis>, typeRemise: string) => requests.patch(`/devis/${devis.id}/${typeRemise}/remiseGlobale`, devis),
    updateInformationClientDevis: (devis: Partial<IDevis>) => requests.patch(`/devis/${devis.id}/updateInformationClientDevis`, devis),
    updateQuoteLine: (id: number, quoteLine: Partial<IOrderLine>) => requests.put<IPrices>(`/devis/${id}`, quoteLine),
    detailQuoteLine: (id: number) => requests.get<IOrderLine>(`/devis/${id}/getQuoteLineById`),
    duplicate: (id: number) => requests.post(`/devis/${id}/duplicateDevis`, {}),
    transformation: (id: number) => requests.post(`/devis/${id}/DevisCommande`, {}),
    valide: (id: number) => requests.post<string>(`/devis/${id}/valide`, {}),
    enAttente: (id: number) => requests.post(`/devis/${id}/enAttente`, {}),
    exportExcel: (obj: any) => axios.get('/devis/listDevisExporte', { responseType: 'blob', params: obj }).then((res) => { fileDownload(res.data, res.headers["filename"]) }),
    generatePDF: (id: string) => axios.get(`/devis/${id}/DevisPdf`, { responseType: 'blob' }).then((res) => { fileDownload(res.data, res.headers["filename"]) }),
    deleteQuote: (id: number) => requests.del(`/devis/${id}/DeleteQuote`),
}

//Response TVA API
const tvaAgent = {
    list: () => requests.get<ITva[]>(`/tva`),
    create: (tva: ITva) => requests.post(`/tva`, tva),
    update: (tva: Partial<ITva>) => requests.put(`/tva/${tva.id}`, tva),
    disable: (id: number) => requests.post(`/tva/${id}/disable`, {}),
    details: (id: number) => requests.get<ITva>(`/tva/${id}`),
    enable: (id: number) => requests.post(`/tva/${id}/enable`, {}),
    default: (id: number) => requests.post(`/tva/${id}/isdefault`, {}),
}

//Response TarifFranchiseur API
const tarifFranchiseurAgent = {
    list: (id: number, company: number) => requests.get<ITarifFranchiseur[]>(`/tarifFranchiseur/getTarifFranchiseurByArticle/${id}/${company}`),
    listTarif: (id: number) => requests.get<IGroupProductPriceList[]>(`/tarifFranchiseur/getTarifByProduct/${id}`),
    create: (tarifFranchiseur: ITarifFranchiseur) => requests.post<IGroupProductPriceList[]>(`/tarifFranchiseur`, tarifFranchiseur),
    add: (tarifFranchiseur: IProductPriceAdd) => requests.post<IGroupProductPriceList[]>(`/tarifFranchiseur/add`, tarifFranchiseur),
    delete: (id: string) => requests.del<ITarifFranchiseur>(`/tarifFranchiseur/${id}`),
    deleteSelected: (productId: number, selected: string[]) => requests.put<IGroupProductPriceList[]>(`/tarifFranchiseur/delete/${productId}`, { selected }),
    update: (tarifFranchiseur: IProductPrice) => requests.put<IGroupProductPriceList[]>(`/tarifFranchiseur/${tarifFranchiseur.id}`, tarifFranchiseur),
    details: (id: string) => requests.get<ITarifFranchiseur>(`/tarifFranchiseur/${id}`),
}

//Response TarifFournisseur API
const tarifFournisseurAgent = {
    list: (id: number) => requests.get<ITarifFournisseur[]>(`/TarifFournisseur/getTarifByArticle/${id}`),
    listByProduct: (id: number) => requests.get<ITarifFournisseur[]>(`/TarifFournisseur/getPurchasePriceByProduct/${id}`),
    create: (tarif: ITarifFournisseur) => requests.post<ITarifFournisseur>(`/TarifFournisseur`, tarif),
    delete: (id: number) => requests.del<ITarifFournisseur>(`/TarifFournisseur/${id}`),
    update: (tarif: Partial<ITarifFournisseur>) => requests.put(`/TarifFournisseur/${tarif.id}`, tarif),
    details: (id: number) => requests.get<ITarifFournisseur>(`/TarifFournisseur/getTarifFournisseursById/${id}`),
    getAll: () => requests.get<ITarifFournisseur[]>(`/TarifFournisseur`),
    detailsTarifByQuantite: (id: number, type: string, quantite: number) => requests.get<ITarifFournisseur>(`/TarifFournisseur/getTarifFournisseurByTypeAndQuantite/${id}/${type}/${quantite}`),
}

//Response Entrepot API
const entrepotAgent = {
    list: () => requests.get<IEntrepot[]>(`/entrepot/`),
    listEntrepotActiveNonAffecte: (idarticle: number) => requests.get<IEntrepot[]>(`/entrepot/list/${idarticle}`),
    create: (entrepot: IEntrepot) => requests.post(`/entrepot`, entrepot),
    delete: (id: number) => requests.del<IEntrepot>(`/entrepot/${id}`),
    update: (entrepot: Partial<IEntrepot>) => requests.put(`/entrepot/${entrepot.entrepotsId}`, entrepot),
    details: (id: number) => requests.get<IEntrepot>(`/entrepot/${id}`),
    disable: (id: number) => requests.post<number>(`/entrepot/${id}/disable`, {}),
    enable: (id: number) => requests.post(`/entrepot/${id}/enable`, {}),
    setMain: (id: number) => requests.put(`/entrepot/${id}/setMain`, {}),
    detailsEntrepotByArticleEnModeApproByCommande: (id: number) => requests.get<IEntrepotByAticleEnModeAppro[]>(`/entrepot/getEntrepotByArticleEnModeApproByCommande/${id}`),
}

//Response ArticleStock API
const articleStockAgent = {
    list: () => requests.get<IArticleStock[]>(`/articleStocks`),
    create: (stock: IArticleStock) => requests.post(`/articleStocks`, stock),
    update: (stock: Partial<IArticleStock>) => requests.put<IArticleStock>(`/articleStocks/${stock.articleStockId}`, stock),
    listarticle: (id: number) => requests.get<IArticleStock[]>(`/articleStocks/getStockByArticle/${id}`),
    listentrepot: (id: number) => requests.get<IArticleStock[]>(`/articleStocks/getStockByEntrepot/${id}`),
    details: (id: number) => requests.get<IArticleStock>(`/articleStocks/getStockById/${id}`),
    alertStocks: (params: URLSearchParams) => axios.get<IAlertStockEnvelope>('/articleStocks/alertStock', { params: params }).then(responseBody),
}

//Response Commande Fournisseur API
const commandeFournisseurAgent = {
    list: (params: URLSearchParams) => axios.get<ICommandeFournisseurEnvelope>('/commandeFournisseur', { params: params }).then(responseBody),
    details: (id: string) => requests.get<ICommandeFournisseur>(`/commandeFournisseur/${id}/getCommandeFournisseurById`),
    create: (commandeFournisseur: Partial<ICommandeFournisseur>) => requests.post(`/commandeFournisseur/addCommandeFournisseur`, commandeFournisseur),
    createOrderAlertStock: (commandeFournisseur: Partial<ICommandeFournisseurForAlert>) => requests.post(`/commandeFournisseur/addCommandeFournisseurForAlert`, commandeFournisseur),
    exportExcel: (obj: any) => axios.get('/commandeFournisseur/listCommadeFournisseurExport', { responseType: 'blob', params: obj }).then((res) => { fileDownload(res.data, "Commande-Fournisseur.xlsx") }),
    update: (commandeFournisseur: Partial<ICommandeFournisseur>) => requests.put(`/commandeFournisseur/${commandeFournisseur.id}`, commandeFournisseur),
    updateDeliveryDates: (deliveryDates: Partial<ICommandeFournisseur>) => requests.put<IOrderDelivery[]>(`/commandeFournisseur/deliveryDates`, deliveryDates),
    duplicate: (id: number) => requests.post(`/commandeFournisseur/${id}/duplicateCommandeFournisseur`, {}),
    addOrderFournisseurLine: (commandeFournisseurLine: IOrderFournisseurLine) => requests.post<IOrderFournisseurLine[]>(`/commandeFournisseur/addOrderFournisseurLine`, commandeFournisseurLine),
    updateOrderFournisseurLine: (id: number, orderFournisseurLine: Partial<IOrderFournisseurLine>) => requests.put<ICommandeFournisseur>(`/commandeFournisseur/${id}/Line`, orderFournisseurLine),
    detailOrderFournisseurLine: (id: number) => requests.get<IOrderFournisseurLine>(`/commandeFournisseur/${id}/getOrderFournisseurLineById`),
    deleteCommandeFournisseurArticle: (id: number) => requests.del(`/commandeFournisseur/${id}/deleteOrderFournisseurLine`),
    updateAdresseCommandeFournisseur: (commandeFournisseur: Partial<ICommandeFournisseur>) => requests.patch(`/commandeFournisseur/${commandeFournisseur.id}/updateAdresses`, commandeFournisseur),
    setValid: (id: number) => requests.post<string>(`/commandeFournisseur/${id}/setValid`, {}),
    setPending: (id: number) => requests.post(`/commandeFournisseur/${id}/setPending`, {}),
    generatePdf: (id: number) => axios.get(`/commandeFournisseur/${id}/commandeFournisseurPdf`, { responseType: 'blob' }).then((res) => { fileDownload(res.data, res.headers["filename"]) }),
    deleteOrder: (id: number) => requests.del(`/commandeFournisseur/${id}/DeleteOrderFournisseur`),
    loadRelatedElements: (id: number, type: string) => requests.get<IRelatedElement[]>(`/commandeFournisseur/loadRelatedElements/${type}/${id}`),
    getSupplierOrders: (id: number) => requests.get<IOrderSupplier[]>(`/commandeFournisseur/loadSupplierOrders/${id}`),
}

//Response MouvementStock API
const mouvementStockAgent = {
    list: (params: URLSearchParams) => axios.get<IMouvementStockEnvelope>('/mouvementStock', { params: params }).then(responseBody),
    create: (stock: IMouvementStock) => requests.post<IArticleStock>(`/mouvementStock`, stock),
    update: (stock: Partial<IMouvementStock>) => requests.put(`/mouvementStock/${stock.mouvementsStockId}`, stock),
    delete: (id: number) => requests.del<IMouvementStock>(`/mouvementStock/${id}`),
    details: (id: number) => requests.get<IMouvementStock>(`/mouvementStock/${id}`),
    exportExcel: (obj: any) => axios.get('/mouvementStock/export', { responseType: 'blob', params: obj }).then((res) => { fileDownload(res.data, "mouvement.xlsx") }),
}

//Response Impression API
const impressionAgent = {
    list: () => requests.get<IImpression[]>(`/impression`),
    create: (impression: IImpression) => requests.post(`/impression`, impression),
    delete: (id: string) => requests.del<IImpression>(`/impression/${id}`),
    update: (impression: Partial<IImpression>) => requests.put(`/impression/${impression.impressionId}`, impression),
    details: (id: string) => requests.get<IImpression>(`/impression/${id}`),
}

//Response Option API
const optionAgent = {
    list: () => requests.get<IOption[]>(`/option`),
    create: (option: IOption) => requests.post(`/option`, option),
    delete: (id: string) => requests.del<IOption>(`/option/${id}`),
    update: (option: Partial<IOption>) => requests.put(`/option/${option.optionId}`, option),
    details: (id: string) => requests.get<IOption>(`/option/${id}`),
    listByArticle: (id: string) => requests.get<IOptionArticle[]>(`option/optionByArticle/${id}`),
}

//Response Support API
const supportAgent = {
    list: () => requests.get<ISupport[]>(`/support`),
    create: (support: ISupport) => requests.post(`/support`, support),
    delete: (id: string) => requests.del<ISupport>(`/support/${id}`),
    update: (support: Partial<ISupport>) => requests.put(`/support/${support.supportId}`, support),
    details: (id: string) => requests.get<ISupport>(`/support/${id}`),
}
//Response Account API
const accountAgent = {
    list: () => requests.get<IAccount[]>(`/account`),
    create: (account: IAccount) => requests.post(`/account`, account),
    delete: (id: string) => requests.del<IAccount>(`/account/${id}`),
    update: (account: Partial<IAccount>) => requests.put(`/account/${account.id}`, account),
    details: (id: string) => requests.get<IAccount>(`/account/${id}`),
}
//Response Reglement API
const reglementAgent = {
    list: (params: URLSearchParams) => axios.get<IReglementEnvelope>(`/reglement`, { params: params }).then(responseBody),
    create: (reglement: IReglement) => requests.post<string>(`/reglement`, reglement),
    createCompanyReglement: (reglement: IReglement) => requests.post<string>(`/reglement/company`, reglement),
    delete: (id: string) => requests.del<IReglement>(`/reglement/${id}`),
    update: (reglement: Partial<IReglement>) => requests.put(`/reglement/update/${reglement.idReglement}`, reglement),
    affectReglement: (reglement: Partial<IReglement>) => requests.put(`/reglement/${reglement.idReglement}`, reglement),
    details: (id: string) => requests.get<IReglement>(`/reglement/${id}`),
    getReglementByFranchiseur: (id: number, obj: any) => axios.get<IReglementEnvelope>(`/reglement/byFranchiseur/${id}`, { params: obj }).then(responseBody),
    getReglementByOrder: (id: number) => requests.get<IReglement[]>(`/reglement/byOrder/${id}`),
    getReglementByInvoice: (id: number) => requests.get<IReglement[]>(`/reglement/byInvoice/${id}`),
    getReglementNotConsumed: (id: number) => requests.get<IReglementNotConsumedEnvelope>(`/reglement/getReglementNotConsumed/${id}`),
    exportExcel: (obj: any) => axios.get('/reglement/exportReglements', { responseType: 'blob', params: obj }).then((res) => { fileDownload(res.data, "Reglements.xlsx") }),
    exportComptabilité: (obj: any) => axios.get('/reglement/reglementsAccounting', { responseType: 'blob', params: obj }).then((res) => { fileDownload(res.data, res.headers["filename"]) }),
    exportByCompany: (obj: any) => axios.get('/reglement/exportByCompany', { responseType: 'blob', params: obj }).then((res) => { fileDownload(res.data, "ReglementClient.xlsx") }),
    affectReglementFacture: (reglement: Partial<IReglement>) => requests.put<string>(`/reglement/affectInvoicePayment/${reglement.idReglement}`, reglement),
    reglementNotConsumedByClient: (obj: any) => axios.get<IReglementNotConsumedByClientEnveloppe>(`/reglement/reglementNotConsumedByClient`, { params: obj }).then(responseBody),

}

//Response Statistic API
const statisticAgent = {
    get: () => requests.get<IStatistic>(`/statistic`),
}

//Response ArticleCompose API
const articleComposeAgent = {
    list: () => requests.get<IArticleCompose[]>(`/articleCompose`),
    create: (pack: IArticleCompose) => requests.post(`/articleCompose`, pack),
    details: (id: number) => requests.get<IArticleCompose>(`/articleCompose/${id}`),
    update: (pack: Partial<IArticleCompose>) => requests.put(`/articleCompose/${pack.articleComposeId}`, pack),
    delete: (id: number) => requests.del<IArticleCompose>(`/articleCompose/${id}`),
    isInPack: (id: string) => requests.get<IArticlePack>(`/articleCompose/isInPack/${id}`),
}

//Response Preparation API
const preparationAgent = {
    list: (params: URLSearchParams) => axios.get<IPreparationEnvelope>('/preparation', { params: params }).then(responseBody),
    details: (id: number) => requests.get<IPreparationCommande>(`/preparation/${id}`),
    create: (pack: IPreparation) => requests.post(`/preparation`, pack),
    save: (pack: IBonDePreparation) => requests.post(`/preparation/pack`, pack),
    detailsPreparationLines: (id: number) => requests.get<IDetailPreparation[]>(`/preparation/${id}/preparationLines`),
    listEntrepotByPreparationLines: (id: number) => requests.get<IEntrepot[]>(`/preparation/ListEntrepotByOrderPrepareLinesItem/${id}`),
    generatePDF: (id: number, idEntrepot: number) => axios.get(`/preparation/${id}/${idEntrepot}/preparationPdf`, { responseType: 'blob' }).then((res) => { fileDownload(res.data, res.headers["filename"]) }),
    update: (orderPrepareLine: Partial<IDetailPreparation>) => requests.put(`/preparation/${orderPrepareLine.idPreparationLines}`, orderPrepareLine),
    detailsPrepareLines: (id: number) => requests.get<IDetailPreparation>(`/preparation/${id}/GetOrderPrepareLineItems`),
    updateStatus: (id: number) => requests.put(`/preparation/updateStatus/${id}`, id),
    cancel: (id: number) => requests.put(`/preparation/${id}/cancel`, id),
    valideTous: (id: number) => requests.put(`/preparation/valideTous/${id}`, id),
    deleteOrderPrepare: (id: number) => requests.del(`/preparation/${id}/DeleteOrderPrepare`),
    exportExcel: (obj: any) => axios.get('/preparation/exportPreparations', { responseType: 'blob', params: obj }).then((res) => { fileDownload(res.data, "Preparation.xlsx") }),

}

const typeMarcheAgent = {
    list: () => requests.get<ITypeMarche[]>(`/typeMarche`),
    create: (typeMarche: ITypeMarche) => requests.post(`/typeMarche`, typeMarche),
    delete: (id: string) => requests.del<ITypeMarche>(`/typeMarche/${id}`),
    update: (typeMarche: Partial<ITypeMarche>) => requests.put(`/typeMarche/${typeMarche.typeMarcheId}`, typeMarche),
    details: (id: string) => requests.get<ITypeMarche>(`/typeMarche/${id}`),
}

const factureAgent = {
    create: (facture: Partial<IFacture>) => requests.post(`/facture/addFacture`, facture),
    getByUser: (id: string) => requests.get<IFacture[]>(`/facture/getListFactureByUser/${id}`),
    listAllPdf: () => requests.get(`/facture/generateAllPDF`),
    list: (params: URLSearchParams) => axios.get<IFactureEnvelope>(`/facture`, { params: params }).then(responseBody),
    listFactureExporter: (obj: any) => axios.get<IInvoiceEnvelope>(`/facture/export`, { params: obj }).then(responseBody),
    details: (id: string) => requests.get<IFacture>(`/facture/${id}/getFactureById`),
    update: (facture: Partial<IFacture>) => requests.patch<string>(`/facture/${facture.id}`, facture),
    updateInformationClientFacture: (facture: Partial<IFacture>) => requests.patch(`/facture/${facture.id}/updateInformationClientFacture`, facture),
    addInvoiceLine: (invoiceLine: IInvoiceLine, id: number) => requests.post<IPrices>(`/facture/${id}/addInvoiceLine`, invoiceLine),
    updateInvoiceLine: (id: number, invoiceLine: Partial<IInvoiceLine>) => requests.put<IPrices>(`/facture/${id}`, invoiceLine),
    updateLine: (id: number, invoiceLine: Partial<IInvoiceLine>) => requests.put<IPrices>(`/facture/updateLine/${id}`, invoiceLine),
    deleteInvoiceArticle: (id: number) => requests.del<IPrices>(`/facture/${id}/deleteInvoiceLine`),
    generatePDF: (id: string, ref: string) => axios.get(`/facture/${id}/${ref}/FacturePdf`, { responseType: 'blob' }).then((res) => { fileDownload(res.data, res.headers["filename"]) }),
    valide: (id: number) => requests.post<string>(`/facture/${id}/valide`, {}),
    brouillon: (id: number) => requests.post(`/facture/${id}/brouillon`, {}),
    relatedObject: (id: number, type: string) => requests.get<IListRelatedElements[]>(`/facture/${id}/getRelatedObject/${type}`),
    remiseAvoirListe: (id: number) => requests.get<IRemiseAvoir[]>(`/facture/getRemiseAvoirByUser/${id}`),
    updateRemiseAv: (idRemise: number, idFacturTarget: number) => requests.patch(`/facture/updateRemiseAv/${idRemise}/${idFacturTarget}`, idFacturTarget),
    remiseAvoirByFacture: (id: number) => requests.get<IRemiseAvoir[]>(`/facture/getAffectedRemiseAv/${id}`),
    remainingPay: (id: number) => requests.get<number>(`/facture/getRemainingPay/${id}`),
    FranchiseurRemise: (id: number) => requests.get<IRemiseAvoir[]>(`/facture/getRemiseByFranchiseur/${id}`),
    PartitionRemiseAvoir: (values: Partial<IRemiseAvoir>) => requests.post<IRemiseAvoir>(`/facture/PartitionRemiseAv`, values),
    deleteFacture: (id: number) => requests.del(`/facture/${id}/DeleteFacture`),
    generatePDFByListFactures: (ids: number[]) => axios.post(`/facture/FacturePdfList`, ids, { responseType: 'blob' }).then((res: any) => { fileDownload(res.data, res.headers["filename"]) }),
    exportExcel: (obj: any) => axios.get('/facture/exportInvoices', { responseType: 'blob', params: obj }).then((res) => { fileDownload(res.data, "Factures.xlsx") }),
    exportComptabilité: (obj: any) => axios.get('/facture/accountingExport', { responseType: 'blob', params: obj }).then((res) => { fileDownload(res.data, res.headers["filename"]) }),
    exportVentes: (obj: any) => axios.get('/facture/salesExport', { responseType: 'blob', params: obj }).then((res) => { fileDownload(res.data, res.headers["filename"]) }),
    getFactureByCompany: (id: number) => requests.get<InvoiceCompanyDto[]>(`/facture/getFactureByCompany/${id}`),
    getFactureByMonth: () => requests.get<IGroupInvoiceDto[]>(`/facture/getFactureByMonth`),
    cloturerFacture: (obj: any) => requests.post(`/facture/cloturerFacture`, obj),
    sendMails: (ids: number[]) => requests.post('/facture/sendMails', { ids }),
    sendMail: (id: number) => requests.post('/facture/sendMail', { id }),
    exportPDF: (obj: any) => axios.get('/facture/exportPDF/FromAzureStorage', { params: obj }).then(responseBody),
    checkForInvoiceDate: (date: Date) => requests.get<boolean>(`/facture/checkForInvoiceDate/${date}`),
}

//Response ExpeditionCommande API
const expeditionAgent = {
    list: (params: URLSearchParams) => axios.get<IExpeditionEnvelope>('/expedition', { params: params }).then(responseBody),
    listReliquats: (params: URLSearchParams) => axios.get<IReliquatsEnvelope>('/expedition/reliquats', { params: params }).then(responseBody),
    create: (id: number) => requests.post(`/expedition/${id}`, {}),
    sortirReliquats: (id: number, dateBl: string) => requests.post<number>(`/expedition/linesOut`, { id, dateBl }),
    detailsDispatchLines: (id: number) => requests.get<IDetailExpedition[]>(`/expedition/${id}/dispatchLines`),
    generatePDF: (id: number) => axios.get(`/expedition/${id}/expeditionPdf`, { responseType: 'blob' }).then((res) => { fileDownload(res.data, res.headers["filename"]) }),
    downloadBLValorisé: (id: number) => axios.get(`/expedition/${id}/downloadValuedBL`, { responseType: 'blob' }).then((res) => { fileDownload(res.data, res.headers["filename"]) }),
    listEntrepotByPreparationLines: (id: number) => requests.get<IEntrepot[]>(`/expedition/ListEntrepotByOrderPrepareLinesItem/${id}`),
    updateStatusOrderDispatchLine: (id: number) => requests.put(`/expedition/updateStatus/${id}`, id),
    updateStatusOrderDispatch: (id: number) => requests.put(`/expedition/updateStateOrderDispatch/${id}`, id),
    details: (id: number) => requests.get<IDispatchOrder>(`/expedition/${id}/Orderdispatch`),
    generateFacture: (id: number) => requests.post(`/expedition/${id}/OrderDispatchFacture`, {}),
    generateFactureByListExpeditions: (ids: number[], invoiceDate: string) => axios.post<number[]>(`/expedition/facturerliste`, { ids, invoiceDate }),
    listArticlesByIdOrderDispatch: (id: number) => requests.get<IDetailExpedition[]>(`/expedition/${id}/ListAticlesByIdOrderDispatch`),
    update: (orderDispatchLine: Partial<IDetailExpedition>) => requests.put<number>(`/expedition/${orderDispatchLine.id}`, orderDispatchLine),
    updateOrderDispatchDate: (obj: Partial<IOrderDispatch>) => requests.put<number>(`/expedition/updateDateOrderDispatch`, obj),
    updateOrderDispatchNbrColis: (obj: Partial<IOrderDispatch>) => requests.put<number>(`/expedition/updateOrderDispatchNbrColis`, obj),
    updateOrderDispatchLineDate: (obj: any) => requests.put<number>(`/expedition/updateDateOrderDispatchLines`, obj),
    BonDeRetour: (orderDisO: any) => requests.put<number>(`/expedition/BonDeRetour`, orderDisO),
    valideTous: (id: number) => requests.put(`/expedition/valideTous/${id}`, id),
    deleteOrderDispatch: (id: number) => requests.del(`/expedition/${id}/DeleteOrderDispatch`),
    cancel: (id: number) => requests.put(`/expedition/${id}/cancel`, id),
    exportExcel: (obj: any) => axios.get('/expedition/exportBlToBeInvoiced', { responseType: 'blob', params: obj }).then((res) => { fileDownload(res.data, "BLaFacturee.xlsx") }),
    exportBrToBeInvoiced: (obj: any) => axios.get('/expedition/exportBrToBeInvoiced', { responseType: 'blob', params: obj }).then((res) => { fileDownload(res.data, "BRaFacturee.xlsx") }),
    getBlToBeInvoiced: (obj: any) => axios.get<IExpeditionEnv>(`/expedition/getBlToBeInvoiced`, { params: obj }).then(responseBody),
    getBrToBeInvoiced: (obj: any) => axios.get<IExpeditionEnv>(`/expedition/getBrToBeInvoiced`, { params: obj }).then(responseBody),
    exportReliquat: (obj: any) => axios.get('/expedition/exportReliquat', { responseType: 'blob', params: obj }).then((res) => { fileDownload(res.data, "Reliquat.xlsx") }),
    exportExpeditionExcel: (obj: any) => axios.get('/expedition/exportExpeditionExcel', { responseType: 'blob', params: obj }).then((res) => { fileDownload(res.data, "Expedition.xlsx") }),
    checkMvt: () => requests.get(`/expedition/checkMvt`),

}
//Response Reception Commande Fournisseur API
const receptionAgent = {
    create: (reception: Partial<IPartialReception>) => requests.post(`/reception/addReception`, reception),
    list: (params: URLSearchParams) => axios.get<IReceptionEnvelope>('/reception', { params: params }).then(responseBody),
    detailsReceptionLines: (id: number) => requests.get<IReceptionLineEnvelope>(`/reception/${id}/receptionLines`),
    addReceivedQuantity: (data: IReceptionLineUpdated) => requests.put<number>(`/reception/addReceivedQuantity`, data),
    addReceptionPartiel: (reception: Partial<IReceptionPartial>) => requests.post<number>(`/reception/addReceptionPartiel`, reception),
    updateDate: (reception: any) => requests.put(`/reception/updateDate`, reception),
    delete: (id: number) => requests.del(`/reception/delete/${id}`),
    receptionnerTous: (id: number) => requests.put(`/reception/valideTous/${id}`, id),
    details: (id: number) => requests.get<IReception>(`/reception/${id}/OrderReception`),
    generateFactureByReception: (ids: number[]) => axios.post<number[]>(`/reception/facturerByReception`, { ids }),
    updateReceptionLineDate: (obj: any) => requests.put<number>(`/reception/updateReceptionLineDate`, obj),
    exportReception: (obj: any) => axios.get('/reception/exportReception', { responseType: 'blob', params: obj }).then((res) => { fileDownload(res.data, "Reception.xlsx") }),
    generatePDF: (id: number) => axios.get(`/reception/${id}/download`, { responseType: 'blob' }).then((res) => { fileDownload(res.data, res.headers["filename"]) }),
}
const factureFournisseurAgent = {
    create: (facture: Partial<IFactureFournisseurAdd>) => requests.post(`/factureFournisseur/addFacture`, facture),
    list: (params: URLSearchParams) => axios.get<IFactureFournisseurEnvelope>(`/factureFournisseur`, { params: params }).then(responseBody),
    details: (id: string) => requests.get<IFactureFournisseur>(`/factureFournisseur/${id}`),
    valide: (id: number) => requests.post<string>(`/factureFournisseur/${id}/valide`, {}),
    brouillon: (id: number) => requests.post(`/factureFournisseur/${id}/brouillon`, {}),
    facturerCommande: (id: number) => requests.post(`/factureFournisseur/facturerCommande/${id}`, {}),
    deleteInvoiceArticle: (id: number) => requests.del(`/factureFournisseur/${id}/deleteInvoiceLine`),
    addInvoiceLine: (invoiceLine: IFactureFournisseurLine, id: number) => requests.post<number>(`/factureFournisseur/${id}/addInvoiceLine`, invoiceLine),
    updateInvoiceLine: (id: number, invoiceLine: Partial<IFactureFournisseurLine>) => requests.put<IFactureFournisseur>(`/factureFournisseur/${id}/updateInvoiceLine`, invoiceLine),
    generatePdf: (id: number) => axios.get(`/factureFournisseur/${id}/FactureFournisseurPdf`, { responseType: 'blob' }).then((res) => { fileDownload(res.data, res.headers["filename"]) }),
    deleteFactureFournisseur: (id: number) => requests.del(`/factureFournisseur/${id}/DeleteFactureFournisseur`),
    update: (purchaseInvoice: Partial<IFactureFournisseur>) => requests.put(`/factureFournisseur/update`, purchaseInvoice),
    exportExcel: (obj: any) => axios.get('/factureFournisseur/exportInvoicesSupplier', { responseType: 'blob', params: obj }).then((res) => { fileDownload(res.data, "FactureFournisseur.xlsx") }),
    exportAchat: (obj: any) => axios.get('/factureFournisseur/exportAchat', { responseType: 'blob', params: obj }).then((res) => { fileDownload(res.data, "Achat.xlsx") }),
}
const productAttributesAgent = {
    liste: (id: number) => requests.get<IProductAttributes[]>(`/productAttributes/${id}/getProductAttributes`),
    create: (attribute: IAttributes) => requests.post<IProductAttributes>(`/productAttributes`, attribute),
    delete: (id: number) => requests.del<IProductAttributes>(`/productAttributes/${id}`),
    update: (attribute: Partial<IProductAttributes>) => requests.put(`/productAttributes/${attribute.id}`, attribute),
    details: (id: number) => requests.get<IProductAttributes>(`/productAttributes/getById/${id}`),
}
const fichierAgent = {
    listByCompany: (id: number) => requests.get<IFichier[]>(`/fichier/${id}`),

}

const cityCodeAgent = {
    liste: (search?: string | null) => axios.get<ICityCode[]>(`/citycode/list/${search}`)
}

const countryCodeAgent = {
    liste: (search?: string | null) => axios.get<ICountryCode[]>(`/country/list/${search}`)
}

const specialOfferAgent = {
    listSpecialOfferByCompany: (params: URLSearchParams, company: number) => axios.get<ISpecialOfferEnveloppe>(`/SpecialOffer/getListOfferByCompany/${company}`, { params: params }).then(responseBody),
    create: (offer: ISpecialOffer) => requests.post<ISpecialOffer>(`/SpecialOffer/addSpecialOffer/`, offer),
    delete: (id: number) => requests.del(`/SpecialOffer/${id}/deleteoffer`),
    listcard: () => axios.get<ISpecialOffer[]>(`/SpecialOffer/getCardfOffer`),
    list: (params: URLSearchParams) => axios.get<ISpecialOfferEnveloppe>(`/SpecialOffer`, { params: params }).then(responseBody),
    updateDateFin: (id: number, dateFin: string) => requests.put(`/SpecialOffer/updateDateFin/${id}/${dateFin}`, dateFin),
    addSpecialPrice: (price: ISpecialPrices) => requests.post<ISpecialPrices>(`/SpecialOffer/addSpecialPrice/`, price)
}

const usersRolesAgent = {
    listeUsers: () => axios.get<IUserModel[]>(`/UsersRolesClaim/allUsers`),
    listeManageRoles: (id: string) => axios.get<IUserRoleModel>(`/UsersRolesClaim/manageRoles/${id}`),
    updateRroles: (model: Partial<IUserRoleModel>) => requests.put(`/UsersRolesClaim/updateRoles`, model),
    listeRoles: () => axios.get<IRole[]>(`/UsersRolesClaim/allRoles`),
    createRole: (name: string) => requests.post(`/UsersRolesClaim/addRole`, name),
    getPermissions: (id: string) => requests.get<IPermissionsRoleModel>(`/UsersRolesClaim/managePermissions/${id}`),
    updatePermissions: (model: IPermissionsRoleModel) => requests.post(`/UsersRolesClaim/updatePermissions`, model),
    getUsersByRole: (roleName: string) => requests.get<string[]>(`/UsersRolesClaim/getUsersByRole/${roleName}`),
    deleteRole: (roleName: string) => requests.del(`/UsersRolesClaim/deleteRole/${roleName}`),
    checkClaimsByRole: (claim: string) => requests.get<boolean>(`/UsersRolesClaim/checkClaimsByRole/${claim}`),
    getUserClaims: () => requests.get<IUserClaims[]>(`/UsersRolesClaim/ClaimsByCurrentUser`)
}
const inventoryAgent = {
    list: () => requests.get<IInventory[]>(`/Inventory`),
    listCompanies: (id: number) => requests.get<IInventoryCompany[]>(`/Inventory/InventoryCompanies/${id}`),
    closeInventory: (id: number, date: string) => requests.put<IInventory>(`/Inventory/closeInventory/${id}`, { date }),
    getInventory: (id: number) => requests.get<IInventory>(`/Inventory/loadInventory/${id}`),
    add: (inv: Partial<IInventory>) => requests.post<IInventory>(`/Inventory`, inv),
    addInventoryCompany: (inv: Partial<IInventoryCompany>) => requests.post<IInventoryCompany>(`/Inventory/addCompany`, inv),
    deleteInventoryCompany: (id: number) => requests.del(`/Inventory/deleteCompany/${id}`),
    addInventoryProducts: (InventoryCompanies: IInventoryCompany[], id: number) => requests.post<IInventoryCompany[]>(`/Inventory/InventoryCompanies/addCompanyProducts/${id}`, { InventoryCompanies }),
    downloadInventoryCompanies: (id: number, company: string) => axios.get(`/Inventory/InventoryCompanies/download/${id}/${company}`, { responseType: 'blob' }).then((res) => { fileDownload(res.data, res.headers["filename"]) }),
    exportInventory: (id: number) => axios.get(`/Inventory/download/${id}`, { responseType: 'blob' }).then((res) => { fileDownload(res.data, res.headers["filename"]) }),
    uploadInventoryCompanies: (file: Blob, id: number, inventoryId: number) => requests.postForm<IInventoryCompany[]>(`/Inventory/InventoryCompanies/upload/${id}/${inventoryId}`, file),

}

const requestAgent = {
    getRequests: () => requests.get<IRequest[]>(`/request`),
    getRequest: (id: number) => requests.get<IRequest>(`/request/${id}`),
    deleteRequest: (id: number) => requests.del(`/request/${id}`),
    updateRequest: (id: number, companyId: number) => requests.put<IRequest>(`/request/${id}/${companyId}`, {}),

}

const exportObject = {
    articleAgent,
    profilAgent,
    userAgent,
    clientAgent,
    franchiseurAgent,
    adresseAgent,
    modePaiementAgent,
    modeReglementAgent,
    formeJuridiqueAgent,
    fournisseurAgent,
    familleAgent,
    commandeAgent,
    devisAgent,
    tarifFournisseurAgent,
    tvaAgent,
    tarifFranchiseurAgent,
    entrepotAgent,
    articleStockAgent,
    commandeFournisseurAgent,
    mouvementStockAgent,
    impressionAgent,
    optionAgent,
    supportAgent,
    statisticAgent,
    accountAgent,
    reglementAgent,
    articleComposeAgent,
    preparationAgent,
    typeMarcheAgent,
    factureAgent,
    expeditionAgent,
    receptionAgent,
    factureFournisseurAgent,
    productAttributesAgent,
    fichierAgent,
    cityCodeAgent,
    countryCodeAgent,
    specialOfferAgent,
    usersRolesAgent,
    inventoryAgent,
    requestAgent
}

export default exportObject;