import React, { useContext, useState } from 'react';
import { Form, Button, Grid, Input, } from 'semantic-ui-react';
import { Form as FinalForm, Field } from 'react-final-form';
import { combineValidators, composeValidators, createValidator, isRequired } from 'revalidate';
import { BaseStoreContext } from '../../../app/store/baseStore';
import TextInput from '../../../app/common/form/inputText';
import ErrorMessage from '../../../app/common/form/ErrorMessage';
import { observer } from 'mobx-react-lite';
import Select from 'react-select';
import InputNumber from '../../../app/common/form/inputNumber';
import { IEntrepot } from '../../../app/models/IEntrepot';
import { toast } from 'react-toastify';
import { IAttributeItems, IProductAttributes } from '../../../app/models/IProductAttributes';
import { convertStringToArray } from '../../../app/common/util/util';

const isValid = createValidator(
    message => value => {
        if (value && !/^-?[1-9]+([0-9]+)*$/i.test(value)) {
            return message;
        }
    },
    'Invalid number'
);

const validate = combineValidators({
    quantite: composeValidators(
        isRequired, isValid({ message: "Veuillez saisir un entier positif " }))({
            message: 'Veuillez saisir la quantite'
        }),
});
const customStyles = {
    control: (baseStyles: any, state: any) => ({
        ...baseStyles,
        height: '43.35px',
        minHeight: '43.35px',
    }),
}


const MouvementStockForm: React.FC = () => {
    const baseRepo = useContext(BaseStoreContext);
    const { article } = baseRepo.articleStore;
    const { create, stocks } = baseRepo.articleStockStore;
    const { entrepots, loadingInitial } = baseRepo.entrepotStore;
    const [entrepot, setEntrepot] = useState<IEntrepot>(entrepots[entrepots.findIndex(x => x.isMain == true)]);
    const [exist, setExist] = useState(stocks.filter(x => x.entrepots == entrepots[entrepots.findIndex(x => x.isMain == true)]?.entrepotsId).length > 0);
    const [attributes, setAttributes] = useState<IAttributeItems[]>([]);
    const [date, setDate] = useState<string>(new Date().toISOString());

    const handleChangeAttributes = (values: string, productAttribute: IProductAttributes) => {
        var index = attributes.findIndex(x => x.id == productAttribute.id);
        var tab = attributes;
        if (index == -1) {
            tab.push({ id: productAttribute.id, item: values, isMain: false })
        }
        else {
            tab[index].item = values;
        }
        setAttributes(tab);
    }
    const handleFinalFormSubmit = (values: any) => {
        if (attributes.length != article?.productAttributes.length) {
            toast.warning("Vous devez séléctionner les attributs")
        } else {
            let newStock = {
                ...values,
                libelle: exist ? values.libelle : values.description,
                entrepots: entrepot?.entrepotsId! ?? entrepots[entrepots.findIndex(x => x.isMain == true)]?.entrepotsId!,
                article: article?.productId,
                productAttributes: attributes,
                date: date
            };
            create(newStock);
            baseRepo.mouvementStockStore.changeButtonStateAdd(!baseRepo.mouvementStockStore.addStock)
        }
    }

    return (
        <FinalForm
            onSubmit={handleFinalFormSubmit}
            validate={validate}
            render={({ handleSubmit, submitting, submitError, invalid, pristine, dirtySinceLastSubmit }) => (

                <Form onSubmit={handleSubmit} error>
                    <div style={{ marginTop: '50px' }}>

                        <Grid >
                            <Grid.Column width={3} style={{ padding: "0 3px" }}>
                                <Select
                                    name='entrepots'
                                    placeholder={entrepots[entrepots.findIndex(x => x.isMain == true)]?.libelle ?? "Séléctionnez un entrepôt.."}
                                    isLoading={loadingInitial}
                                    options={entrepots}
                                    getOptionLabel={e => e!.libelle}
                                    getOptionValue={e => e!.entrepotsId.toString()}
                                    onChange={e => { setEntrepot(e!); setExist(stocks.filter(x => x.entrepots == e!.entrepotsId).length > 0); }}
                                    styles={customStyles}
                                />
                            </Grid.Column>
                            {!loadingInitial && entrepot?.entrepotsId != 0 &&
                                <>
                                    {exist ? (
                                        <Grid.Column width={5} style={{ padding: "0 3px" }}>
                                            <Field name='libelle' component={TextInput} placeholder='Description' />
                                        </Grid.Column>
                                    ) : (
                                        <Grid.Column width={5} style={{ padding: "0 3px" }}>
                                            <Field name='description' component={TextInput} defaultValue='Stock Initial' />
                                        </Grid.Column>
                                    )}
                                    {article?.productAttributes.map(productAttribute => (
                                        <Grid.Column width={3} style={{ padding: "0 3px" }}>
                                            <Select
                                                name='attribute'
                                                placeholder={"Attribut..."}
                                                options={convertStringToArray(productAttribute.possibleValues)}
                                                getOptionLabel={e => e!.text}
                                                getOptionValue={e => e!.value}
                                                onChange={e => { handleChangeAttributes(e!.value, productAttribute) }}
                                            />
                                        </Grid.Column>

                                    ))}
                                    <Grid.Column width={3} style={{ padding: "0 3px" }}>

                                        <Input name='creationDate' onChange={(e: any) => setDate(e.target.value)} value={date!} style={{ height: 43.35 }} type='date' />
                                    </Grid.Column>

                                    <Grid.Column width={2} style={{ padding: "0 3px" }}>
                                        <Field name='quantite' component={InputNumber} type="number" placeholder='Qte' />
                                    </Grid.Column>
                                    <Grid.Column width={2} style={{ paddingRight: 0, paddingTop: 0 }}>
                                        <Button
                                            style={{ height: 43.35, marginTop: 0 }}
                                            floated='right'
                                            disabled={(invalid && !dirtySinceLastSubmit) || pristine}
                                            loading={submitting}
                                            color='blue'
                                            content='Enregistrer'
                                        />
                                    </Grid.Column>
                                </>
                            }
                            {submitError && !dirtySinceLastSubmit && (
                                <ErrorMessage
                                    error={submitError}
                                    text=' invalide'
                                />
                            )}
                        </Grid>
                    </div>
                </Form>
            )} />
    );
}
export default observer(MouvementStockForm);