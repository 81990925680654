import { observer } from "mobx-react-lite";
import { useState } from "react";
import { useContext } from "react";
import { Form as FinalForm } from 'react-final-form';
import { Button, Checkbox, Form, FormInput, Grid, Segment } from "semantic-ui-react";
import { BaseStoreContext } from "../../app/store/baseStore";
import Breadcrumbs from '../dashboard/breadcrumbs';
import { DatePicker } from 'antd';
import dayjs from 'dayjs';
import type { Dayjs } from 'dayjs';
import AsyncSelect from 'react-select/async';
import { components } from "react-select";

const ExportData: React.FC = () => {
    const { RangePicker } = DatePicker;
    const baseStore = useContext(BaseStoreContext);
    const { loadingExport, exportData } = baseStore.clientStore;
    const [type, setType] = useState<string>("Commande");
    const [isOrderWaitingPaymentIncluded, setIsOrderWaitingPaymentIncluded] = useState<boolean>(false);
    const [searchResultsClient, setSearchResultsClient] = useState<any>(0);
    const [searchResultsClientAll, setSearchResultsClientAll] = useState(false);
    const [selectedLabel, setSelectedLabel] = useState<any>("Client");
    const [selectedArticle, setSelectedArticle] = useState<any>("Article");
    const [searchArticle, setSearchArticle] = useState<any>('');
    const [searchDateCommande, setSearchDateCommande] = useState<any>('');
    const [searchDateCommandeTo, setSearchDateCommandeTo] = useState<any>('');
    const [agencyName, setAgencyName] = useState<any>('');

    const rangePresets: {
        label: string;
        value: [Dayjs, Dayjs];
    }[] = [
            { label: '7 derniers jours', value: [dayjs().add(-7, 'd'), dayjs()] },
            { label: '14 derniers jours', value: [dayjs().add(-14, 'd'), dayjs()] },
            { label: '30 derniers jours', value: [dayjs().add(-30, 'd'), dayjs()] },
            { label: '90 derniers jours', value: [dayjs().add(-90, 'd'), dayjs()] },
        ];
    const handleInputChangeArticle = (value: any) => {
        setSelectedArticle(value.productId+"-"+value.label)
        setSearchArticle(value.productId);
    }
    const handleChangeClient = (value: any) => {
        setSearchResultsClient(value.id);
        setAgencyName(value.agencyName)
        setSearchResultsClientAll(value.all);
        setSelectedLabel(value!.agencyName + "-" + value!.companyName + (value!.all ? "-Tous les réseaux" : ""));
    }

    const onRangeChange = (dates: null | (Dayjs | null)[]) => {
        if (dates) {
            setSearchDateCommande(dates[0]?.toISOString());
            setSearchDateCommandeTo(dates[1]?.toISOString());
        }
    };
    const handleFinalFormSubmit = () => {
        let obj = {
            searchClient: searchResultsClient,
            article: searchArticle,
            type: type,
            searchResultsClientAll: searchResultsClientAll,
            dateCommande: searchDateCommande,
            dateCommandeTo: searchDateCommandeTo,
            selectedLabel: selectedLabel,
            isInclude: isOrderWaitingPaymentIncluded,
        };
        exportData(obj);

    }
    
    return (
        <>
            <Breadcrumbs
                items={[
                    { key: 0, name: "Statistique", root: "" },
                    { key: 1, name: "Export statistique", root: "/statics" },
                ]}
            />
            <Grid>
                <Grid.Row>
                    <Grid.Column width={16}>
                        <FinalForm
                            onSubmit={handleFinalFormSubmit}

                            render={({ handleSubmit }) => (
                                <Form onSubmit={handleSubmit} error >
                                    <Segment>
                                        <Grid columns={16}>
                                            <Grid.Row style={{ paddingLeft: 50 }}>
                                                <span style={{ fontSize: 18, fontWeight: 700, color: "#db2828" }}>Veillez sélectionner un choix pour l'exportation</span>
                                            </Grid.Row >

                                            <Grid.Row style={{ textAlign: "center" }} >
                                                <Grid.Column width={16} style={{ margin: 0 }}>
                                                    <div style={{ textAlign: "center" }}>
                                                        <Checkbox style={{ marginRight: 40 }} checked={type == "Commande"} onClick={(e, value) => value.checked ? setType("Commande") : setType("Commande")} label="Export par commande" />
                                                        <Checkbox style={{ marginRight: 40 }} checked={type == "Facture"} onClick={(e, value) => value.checked ? setType("Facture") : setType("Commande")} label="Export par facture" />
                                                    </div>
                                                </Grid.Column>
                                                {type == "Commande" &&
                                                    <Grid.Column width={16} style={{ margin: 0 }}>
                                                        <div style={{ textAlign: "center" }}>
                                                            <Checkbox checked={isOrderWaitingPaymentIncluded} onClick={(e, value) => setIsOrderWaitingPaymentIncluded(!isOrderWaitingPaymentIncluded)} label="Inclure les commandes en attente de paiement" />
                                                        </div>
                                                        <br />
                                                    </Grid.Column>
                                                }
                                                <Grid.Column width={5}>

                                                    <AsyncSelect
                                                        name="form-field-name"
                                                        noOptionsMessage={() => "Code introuvable"}
                                                        cacheOptions
                                                        defaultOptions
                                                        placeholder={selectedLabel}
                                                        value={searchResultsClient}
                                                        getOptionLabel={e => e!.agencyName + "-" + e!.companyName + (e!.all ? "-Tous les réseaux" : "")}
                                                        loadOptions={baseStore.clientStore.loadOptions}
                                                        onChange={handleChangeClient}
                                                    />
                                                </Grid.Column>
                                                <Grid.Column width={5}>
                                                    <AsyncSelect
                                                        name="form-field-name"
                                                        noOptionsMessage={() => "Code introuvable"}
                                                        cacheOptions
                                                        defaultOptions
                                                        placeholder={selectedArticle}
                                                        value={searchArticle}
                                                        getOptionLabel={e => e!.productId+"-"+e!.label}
                                                        loadOptions={baseStore.articleStore.loadSearchArticleOptions}
                                                        onChange={handleInputChangeArticle}
                                                    />
                                                </Grid.Column>
                                                <Grid.Column width={5}>
                                                    <RangePicker
                                                        presets={rangePresets}
                                                        onChange={onRangeChange}
                                                        format={'DD/MM/YYYY'}
                                                        placeholder={["Début", "Fin"]}
                                                        style={{ width: '100%', height: '39.201px' }}
                                                    />

                                                </Grid.Column>
                                                {(loadingExport) &&
                                                    <Grid.Column width={16}>
                                                        <span style={{ color: "red", fontWeight: 700 }}>Le fichier est en cours de création. Merci pour votre attente !!</span>
                                                    </Grid.Column>

                                                }

                                                <Grid.Column width={16}>
                                                    <Button type="submit" basic active color="green" content="Exporter" loading={loadingExport} disabled={searchArticle == '' && searchResultsClient == 0  } />
                                                </Grid.Column>
                                            </Grid.Row>
                                        </Grid>
                                    </Segment>

                                </Form>

                            )}
                        />
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        </>
    );
}
export default observer(ExportData);