import React, { useRef } from 'react';
import Cropper from 'react-cropper';
import 'cropperjs/dist/cropper.css';

interface IProps {
    setImage: (file: Blob) => void;
    imagePreview: string;
    imageType: string;
    imageName: string;
}

const PhotoWidgetCropper: React.FC<IProps> = ({ setImage, imagePreview, imageType, imageName }) => {
    const cropper = useRef<Cropper>(null);

    const cropImage = () => {
        if (
            cropper.current &&
            typeof cropper.current.getCroppedCanvas() === 'undefined'
        ) {
            return;
        }
        cropper &&
            cropper.current &&
            cropper.current.getCroppedCanvas().toBlob((blob: any) => {
                blob.fileName= imageName;
                setImage(blob);
            }, imageType);
    };

    return (
        <Cropper
            ref={cropper}
            src={imagePreview}
            name={imageName}
            style={{ height: 200, width: '100%' }}
            // Cropper.js options        
            preview='.img-preview'
            guides={false}
            viewMode={1}
            dragMode='move'
            scalable={true}
            cropBoxMovable={true}
            cropBoxResizable={true}
            crop={cropImage}
        />
    );
};

export default PhotoWidgetCropper;
