import React, { useContext, useEffect, useState } from 'react';
import { GridColumn, Grid, Button, Table, Modal, Icon } from 'semantic-ui-react';
import { BaseStoreContext } from '../../app/store/baseStore';
import { observer } from 'mobx-react-lite';
import ListItemPlaceholder from '../../app/common/form/ListItemPlaceholder';
import Breadcrumbs from '../dashboard/breadcrumbs';
import { IRequest } from '../../app/models/IClient';
import NoResult from '../devis/devisDashboard/erreurNoResult';
import { history } from '../../index';
import AsyncSelect from 'react-select/async';


const RequestDashboard: React.FC = () => {
    const baseStore = useContext(BaseStoreContext);
    const { loadRequests, loadingInitial, requests, deleteRequest, deleting, updateRequest, updating } = baseStore.requestStore;

    const [modalOpen, setModalOpen] = useState(false);
    const [modalUpdateOpen, setModalUpdateOpen] = useState(false);
    const [deleteTarget, setDeleteTarget] = useState(0);
    const [updateTarget, setUpdateTarget] = useState(0);
    const [companyId, setCompanyId] = useState(0);

    useEffect(() => {
        loadRequests();
    }, []);

    return (


        <div>

            <Breadcrumbs
                items={[
                    { key: 0, name: "Tiers", root: "" },
                    { key: 1, name: "Demandes", root: "/requests" },
                ]}
            />


            <Grid>
                <Grid.Row style={{ padding: 0, margin: "0 20px 0 20px" }}>


                </Grid.Row>
                <GridColumn width={16} style={{ marginTop: 2 }}>
                    {loadingInitial ? (<ListItemPlaceholder />) : (
                        <>
                            {(!loadingInitial && requests.length < 1) ?
                                (< NoResult name="Demande" />)
                                :
                                (
                                    <Grid style={{ marginTop: 2 }}>
                                        <GridColumn width={16}>
                                            <Table compact='very' style={{ marginTop: '0em' }} textAlign='center' >
                                                <Table.Header>
                                                    <Table.Row>
                                                        <Table.HeaderCell>DATE DE LA DEMANDE</Table.HeaderCell>
                                                        <Table.HeaderCell>RESEAU</Table.HeaderCell>
                                                        <Table.HeaderCell>NOM D'AGENCE</Table.HeaderCell>
                                                        <Table.HeaderCell>NOM DE SOCIETE</Table.HeaderCell>
                                                        <Table.HeaderCell>Nom du contact</Table.HeaderCell>
                                                        <Table.HeaderCell>Téléphone</Table.HeaderCell>
                                                        <Table.HeaderCell>Mail</Table.HeaderCell>
                                                        <Table.HeaderCell>ADRESSE DE FACTURATION</Table.HeaderCell>
                                                        <Table.HeaderCell>ADRESSE DE LIVRAISON</Table.HeaderCell>
                                                        <Table.HeaderCell></Table.HeaderCell>
                                                    </Table.Row>
                                                </Table.Header>
                                                <Table.Body>
                                                    {requests && requests.map((request: IRequest, key) => (
                                                        <>
                                                            <Table.Row key={request.id} >
                                                                <Table.Cell>{new Date(request.requestDate).toLocaleDateString('en-GB')}</Table.Cell>
                                                                <Table.Cell>  {request.agencyName == 0 ? <>{request.network} <span><Icon name="edit outline" onClick={() => { setUpdateTarget(request.id); setModalUpdateOpen(true) }}/></span></> : request.agencyName + "-" + request.network}  </Table.Cell>
                                                                <Table.Cell>  {request.companyName}    </Table.Cell>
                                                                <Table.Cell> {request.socialName} </Table.Cell>
                                                                <Table.Cell> {request.deliveryFirstName} {request.deliveryLastName} </Table.Cell>
                                                                <Table.Cell> {request.phone} </Table.Cell>
                                                                <Table.Cell> {request.email} </Table.Cell>
                                                                <Table.Cell>{request.invoiceCountry == null ? "FR" : request.invoiceAddress1} {request.invoicePostalCode} {request.invoiceCity}, {request.invoiceCountry}</Table.Cell>
                                                                <Table.Cell>{request.deliveryCountry == null ? "FR" : request.deliveryAddress1} {request.deliveryPostalCode} {request.deliveryCity}, {request.deliveryCountry}</Table.Cell>
                                                                <Table.Cell>
                                                                    <Button.Group fluid style={{flexDirection:'row-reverse'}}>
                                                                        <Button
                                                                            style={{ marginLeft: "5px", maxWidth: 40, float: "right" }}
                                                                            basic
                                                                            size="small"
                                                                            color='red'
                                                                            icon='trash'
                                                                            loading={deleting && deleteTarget == request.id}
                                                                            onClick={(e) => {
                                                                                setDeleteTarget(request.id);
                                                                                setModalOpen(true);
                                                                            }}
                                                                        />
                                                                        {request.networkId != 0 &&
                                                                            <Button
                                                                                basic
                                                                                size="small"
                                                                                color='green'
                                                                                icon='check'
                                                                                content="Créer"
                                                                                onClick={(e) => {
                                                                                    history.push(`/generateClient/${request.networkId}/${request.id}`);
                                                                                }}
                                                                            />
                                                                        }
                                                                    </Button.Group>
                                                                </Table.Cell>
                                                            </Table.Row>
                                                        </>
                                                    ))}
                                                </Table.Body>
                                            </Table>
                                        </GridColumn>


                                    </Grid>)}

                        </>
                    )}
                </GridColumn>


            </Grid>

            <Modal
                open={modalOpen}
                size='tiny'
                closeOnEscape={true}
                closeOnRootNodeClick={true}
            >
                <Modal.Header icon='add' style={{ marginLeft: 20 }} >
                    Suppression demande
                </Modal.Header>

                <Modal.Content >
                    <p>
                        Voulez vous supprimer cette demande ?
                    </p>
                </Modal.Content>

                <Modal.Actions>
                    <Button.Group >
                        <Button onClick={() => setModalOpen(false)}>
                            <Icon name='remove' /> Annuler
                        </Button>
                        <Button
                            color='green'
                            onClick={(e) => {
                                deleteRequest(deleteTarget);
                                setModalOpen(false);
                            }}>
                            <Icon name='checkmark' /> Confirmer
                        </Button>
                    </Button.Group>

                </Modal.Actions>
            </Modal>

            <Modal
                open={modalUpdateOpen}
                size='tiny'
                closeOnEscape={true}
                closeOnRootNodeClick={true}
            >
                <Modal.Header icon='add' style={{ marginLeft: 20 }} >
                    mettre à jour le nom du réseau
                </Modal.Header>

                <Modal.Content>
                    <div className="field" style={{ width: 400, justifyContent: 'center' }}>
                        <h5>Réseau  </h5>
                        <AsyncSelect
                            name="form-field-name"
                            noOptionsMessage={() => "Code introuvable"}
                            cacheOptions
                            placeholder='Selectionnez le réseau...'
                            defaultOptions
                            getOptionLabel={e => e!.text}
                            getOptionValue={e => e!.value}
                            loadOptions={baseStore.franchiseurStore.loadCompanyOptions}
                            onChange={e => setCompanyId(parseInt(e!.key))}
                        />
                    </div>
                </Modal.Content>

                <Modal.Actions>
                    <Button.Group>
                        <Button onClick={() => { setModalUpdateOpen(false); setCompanyId(0) }}>
                            <Icon name='remove' /> Annuler
                        </Button>
                        <Button
                            color='green'
                            disabled={companyId == 0}
                            loading={updating}
                            onClick={(e) => {
                                updateRequest(updateTarget, companyId);
                                setModalUpdateOpen(false);
                                setCompanyId(0)
                            }}>
                            <Icon name='checkmark' /> Confirmer
                        </Button>
                    </Button.Group>

                </Modal.Actions>
            </Modal>
        </div>
    );
}
export default observer(RequestDashboard);