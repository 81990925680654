import React, { useContext, useEffect, useState } from 'react';
import { Form, Button, Header, Segment, Radio, Grid, Accordion, Checkbox } from 'semantic-ui-react';
import { Form as FinalForm, Field } from 'react-final-form';
import { combineValidators, composeValidators, createValidator, isRequired } from 'revalidate';
import { BaseStoreContext } from '../../../app/store/baseStore';
import TextInput from '../../../app/common/form/inputText';
import { Link, RouteComponentProps } from 'react-router-dom';
import Breadcrumbs from '../../dashboard/breadcrumbs';
import { observer } from 'mobx-react-lite';
import InputNumber from '../../../app/common/form/inputNumber';
import Loading from '../../../app/layout/Loading';
import InputSelect from '../../../app/common/form/inputSelect';
import { IInterfaceList, ITListBillingType, ITListJoursFermeture, ITListTypeCivilite } from '../../../app/common/options/IAchat';
import AsyncSelect from "react-select/async";
import Select from 'react-select';
import { toast } from 'react-toastify';
import { IClient } from '../../../app/models/IClient';

const customStyles = {
    option: (provided: any, state: { isSelected: any; }) => ({
        ...provided,
        color: state.isSelected ? 'orange' : 'grey',
        backgroundColor: state.isSelected ? "#eee" : "white",
        borderBottom: '1px solid #eee',
    }),
    valueContainer: (props: any) => ({
        ...props,
        paddingTop: 0,
        height: '39.201px',
        minHeight: '39.201px',
    }),
    control: (baseStyles: any, state: any) => ({
        ...baseStyles,
        height: '39.201px',
        minHeight: '39.201px',
    }),
}

const isValidtelephone = createValidator(
    message => value => {
        if (value && !/^((\+||00)33||0)*[1-9]([ .-]*[0-9]{2})*$/i.test(value)) {
            return message;
        }
    },
    'Invalid number'
);

const isValidEmail = createValidator(
    message => value => {
        if (value && !/^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[a-zA-Z]{2,4}$/i.test(value)) {
            return message;
        }
    },
    'Invalid email address'
);

const validate = combineValidators({
    companyName: isRequired({ message: 'Veuillez saisir le Nom commercial' }),
    socialName: isRequired({ message: 'Veuillez saisir la raison sociale' }),
    nomResponsable: isRequired({ message: 'Veuillez saisir le nom du responsable' }),
    prenomResponsable: isRequired({ message: 'Veuillez saisir le prenom du responsable' }),
    mobile: composeValidators(isRequired, isValidtelephone({
        message: 'Entrer un numéro de tel valide'
    }))
        ({
            message: "Veuillez saisir le telephone de l'agence"
        }),
    email: composeValidators(
        isRequired, isValidEmail({
            message: 'Entrez une adresse valide. Exemple : contact@nom.com'
        })
    )({
        message: 'Veuillez saisir l email de la boutique'
    }),

});

interface RouteParams {
    id: string
    requestId: string
}

interface IProps extends RouteComponentProps<RouteParams> { }
const AddClient: React.FC<IProps> = ({ match }) => {
    const baseRepo = useContext(BaseStoreContext);
    const [franchise, setFranchise] = useState(1);
    const [succursale, setSuccursale] = useState(0);
    const { create, countClient, getLastClient, loadingInitial } = baseRepo.clientStore;
    const { loadCompany, company, loadingFranchiseur } = baseRepo.franchiseurStore;
    const { loadRequest, request } = baseRepo.requestStore;
    const { loadModePaiements, ITListModePaiements } = baseRepo.modePaiementStore;
    const { loadModeReglements, ITListModeReglement } = baseRepo.modeReglementStore;
    const { loadFormeJuridiques, ITListFormeJuridique } = baseRepo.formeJuridiqueStore;
    const [mAdresse, setMAdresse] = useState(false);
    const [deliveryAddress1, setdeliveryAddress1] = useState<any>();
    const [deliveryAddress2, setdeliveryAddress2] = useState<any>();
    const [deliveryCity, setdeliveryCity] = useState<any>();
    const [invoiceAddress1, setinvoiceAddress1] = useState<any>();
    const [invoiceAddress2, setinvoiceAddress2] = useState<any>();
    const [deliveryCountry, setdeliveryCountry] = useState<any>("FR");
    const [deliveryEmails, setdeliveryEmails] = useState<any>();
    const [invoiceCity, setinvoiceCity] = useState<any>();
    const [typeCommission, setTypeCommission] = useState(false);
    const [invoiceCountry, setinvoiceCountry] = useState<any>("FR");
    const [invoiceEmails, setinvoiceEmails] = useState<any>();
    const [invoicePhone, setInvoicePhone] = useState<any>();
    const [deliveryPhone, setdeliveryPhone] = useState<any>();
    const [deliveryCompany, setdeliveryCompany] = useState<any>();
    const [invoiceCompany, setinvoiceCompany] = useState<any>();
    const [state, setState] = useState({ activeIndex: 0 });
    const [codePostalLiv, setCodePostalLiv] = useState<any>(null);
    const [codePostalFac, setCodePostalFac] = useState<any>(null);
    const [requestDays, setRequestDays] = useState<IInterfaceList[]>([]);
    const [socialName, setSocialName] = useState<string>("");
    const [companyName, setCompanyName] = useState<string>("");
    const [billedType, setBilledType] = useState<string>("BillByCompany");
    const [modePaiement, setModePaiement] = useState<any>("");
    const [labelModePaiement, setLabelModePaiement] = useState("Mode de Paiement");
    const [modeReglement, setModeReglement] = useState<any>();
    const [labelModeReglement, setLabelModeReglement] = useState("Echéance");

    const handleChange = (e: any, result: any) => {
        var days: IInterfaceList[] = [];
        e.map((item: IInterfaceList) => (
            days.push(item)
        ))
        setRequestDays(days);
    }

    const handleClick = (e: any, titleProps: any) => {
        const { index } = titleProps
        const { activeIndex } = state
        const newIndex = activeIndex === index ? -1 : index
        setState({ activeIndex: newIndex })
    }
    const handleClickNext = () => {
        const { activeIndex } = state
        const newIndex = activeIndex + 1
        setState({ activeIndex: newIndex })
    }
    const handleClickPrev = () => {
        const { activeIndex } = state
        const newIndex = activeIndex - 1
        setState({ activeIndex: newIndex })
    }
    const { activeIndex } = state
    const handleChangeDeliveryAddress1 = (e: any, result: any) => {
        setdeliveryAddress1(result.value);
    }
    const handleChangeDeliveryCompany = (e: any, result: any) => {
        setdeliveryCompany(result.value);
    }
    const handleChangedeliveryAddress2 = (e: any, result: any) => {
        setdeliveryAddress2(result.value);
    }
    const handleChangedeliveryCity = (e: any, result: any) => {
        setdeliveryCity(result.value);
    }
    const handleChangeinvoiceAddress1 = (e: any, result: any) => {
        setinvoiceAddress1(result.value);
    }
    const handleChangeinvoiceCompany = (e: any, result: any) => {
        setinvoiceCompany(result.value);
    }
    const handleChangeinvoiceAddress2 = (e: any, result: any) => {
        setinvoiceAddress2(result.value);
    }
    const handleChangeinvoicePostalCode = (value: any) => {
        setCodePostalFac(value.codePostal);
        setinvoiceCity(value.city);
    }
    const handleChangedeliveryPostalCode = (value: any) => {
        setCodePostalLiv(value.codePostal);
        setdeliveryCity(value.city);
    }
    const handleChangedeliveryEmail = (e: any, result: any) => {
        setdeliveryEmails(result.value);
    }
    const handleChangedeliveryPhone = (e: any, result: any) => {
        setdeliveryPhone(result.value);
    }
    const handleChangePostalCode = (e: any, result: any) => {
        setCodePostalLiv(result.value);
    }
    const handleChangePostalCodeinvoice = (e: any, result: any) => {
        setCodePostalFac(result.value);
    }
    const handleChangeinvoiceCity = (e: any, result: any) => {
        setinvoiceCity(result.value);
    }
    const handleChangeinvoiceCountry = (value: any) => {
        setinvoiceCountry(value.iso);
    }
    const handleChangedeliveryCountry = (value: any) => {
        setdeliveryCountry(value.iso);
    }
    const handleChangeinvoiceEmails = (e: any, result: any) => {
        setinvoiceEmails(result.value);
    }
    const handleChangeinvoicePhone = (e: any, result: any) => {
        setInvoicePhone(result.value);
    }
    const handleChangeSocialName = (e: any, result: any) => {
        setSocialName(result.value);
    }
    const handleChangeCompanyName = (e: any, result: any) => {
        setCompanyName(result.value);
    }
    useEffect(() => {
        loadCompany(parseInt(match.params.id));
        if (match.params.requestId) {
            loadRequest(parseInt(match.params.requestId)).then(
                res => {
                    setdeliveryCountry(res?.deliveryCountry);
                    setinvoiceCountry(res?.invoiceCountry);
                    setdeliveryCity(res?.deliveryCity);
                    setinvoiceCity(res?.invoiceCity);
                    setSocialName(res?.socialName!);
                    setCompanyName(res?.companyName!);
                    setdeliveryAddress1(res?.deliveryAddress1);
                    setdeliveryAddress2(res?.deliveryAddress2);
                    setinvoiceAddress1(res?.invoiceAddress1);
                    setinvoiceAddress2(res?.invoiceAddress2);
                    setdeliveryCompany(res?.deliveryCompany);
                    setinvoiceCompany(res?.socialName);

                    var iDays: IInterfaceList[] = [];

                    if (res?.closingDays != null)
                    {
                        ITListJoursFermeture.map((item) => {
                            if (res?.closingDays.toUpperCase().indexOf(item.text.toUpperCase()) != -1) {
                                iDays.push(item);
                            }
                        });

                        if (res?.closingDays?.toUpperCase().indexOf("WEEK") != -1) {
                            iDays.push(ITListJoursFermeture[5]);
                            iDays.push(ITListJoursFermeture[6]);
                        }
                    }

                    setRequestDays(iDays);
                    setdeliveryEmails(res?.deliveryEmail);
                    setinvoiceEmails(res?.invoiceEmail);
                    setdeliveryPhone(res?.deliveryPhone);
                    setInvoicePhone(res?.invoicePhone);

                    if (res?.deliveryCountry == "FR")
                        setCodePostalLiv(parseInt(res?.deliveryPostalCode!));
                    else
                        setCodePostalLiv(res?.deliveryPostalCode!);

                    if (res?.invoiceCountry == "FR")
                        setCodePostalFac(parseInt(res?.invoicePostalCode!));
                    else
                        setCodePostalFac(res?.invoicePostalCode!);
                    if (res?.modePaiementId) {
                        setModePaiement(res?.modePaiementId)
                        setLabelModePaiement(res?.paiementMode)
                    }
                }
            );
        }
        loadModePaiements();
        loadFormeJuridiques()
        loadModeReglements();
        getLastClient(parseInt(match.params.id), "Franchise");
    }, [])
    const handleFinalFormSubmit = async (values: any) => {
        var stringDays = "";
        if (requestDays.length == 0)
            stringDays = "NR";
        else {
            for (let i = 0; i < requestDays.length; i++) {
                var text = requestDays[i].text;
                if (i == requestDays.length - 1 && requestDays.length > 1)
                    text = " et " + text;
                if (i > 0 && i < requestDays.length - 1)
                    text = " , " + text;
                stringDays += text;
            }
        }
        var commission = typeCommission ? values.commissionValue : 0;
        let newFranchiseur: IClient = {
            ...values,
            typeCommission: typeCommission,
            valeurCommission: commission,
            isActive: true,
            mAdresse: mAdresse,
            deliveryAddress1: deliveryAddress1,
            deliveryAddress2: deliveryAddress2,
            deliveryCity: deliveryCity,
            deliveryPostalCode: codePostalLiv,
            invoiceAddress1: mAdresse ? deliveryAddress1 : invoiceAddress1,
            invoiceAddress2: mAdresse ? deliveryAddress2 : invoiceAddress2,
            invoiceCity: mAdresse ? deliveryCity : invoiceCity,
            invoicePostalCode: mAdresse ? codePostalLiv : codePostalFac,
            compteAuxilliere: countClient,
            franchisorId: parseInt(match.params.id),
            companyType: franchise == 1 ? '1' : '2',
            deliveryCountry: deliveryCountry,
            invoiceCountry: mAdresse ? deliveryCountry : invoiceCountry,
            deliveryEmails: deliveryEmails,
            invoiceEmails: mAdresse ? deliveryEmails : invoiceEmails,
            invoicePhone: mAdresse ? deliveryPhone : invoicePhone,
            deliveryPhone: deliveryPhone,
            deliveryCompany: deliveryCompany ?? companyName,
            invoiceCompany: (mAdresse && invoiceCompany == null) ? deliveryCompany : invoiceCompany,
            deliveryClosingDays: stringDays,
            socialName: socialName,
            companyName: companyName,
            billingType: billedType,
            modePaiement: modePaiement,
            modeReglement: labelModePaiement == 'Carte Bancaire' ? 'Comptant' : labelModeReglement,
            requestId: match.params.requestId,
        };
        if (companyName == "" || socialName == "" || values.nomResponsable == undefined || values.prenomResponsable == undefined || values.email == undefined || values.mobile == undefined || modePaiement == "" || (labelModePaiement != 'Carte Bancaire' && modeReglement == undefined)) {
            toast.warning("Vérifier vos champs");
        }
        else {
            create(newFranchiseur);
        }
    }
    if (loadingFranchiseur && loadingInitial)
        return (<Loading content='Loading Franchiseur ...' />)
    else
        return (
            <div>
                <Breadcrumbs
                    items={[
                        { key: 0, name: "Tiers", root: "" },
                        { key: 1, name: "Réseaux", root: "/franchiseurDashboard" },
                        { key: 2, name: `${company?.companyName}`, root: `/detailFranchiseur/${match.params.id}` },
                        { key: 3, name: "Ajouter client", root: `/createClient${match.params.id}` },
                    ]}
                />
                <Grid>
                    <Grid.Row>
                        <Grid.Column width={16}>
                            <Segment >
                                <FinalForm
                                    onSubmit={handleFinalFormSubmit}
                                    render={({ handleSubmit, submitting, invalid, pristine }) => (
                                        <div style={{ padding: 25 }}>
                                            <Form onSubmit={handleSubmit} style={{ maxWidth: "100%" }} >
                                                <Header as='h2' content={`Ajouter Client Pour ${company ? company?.companyName : ""}`} color='orange' textAlign='center' />
                                                <div style={{ margin: 40, textAlign: 'center' }}>
                                                    <Radio
                                                        label='Succursale'
                                                        name='radioGroup'
                                                        value={0}
                                                        checked={succursale == 1}
                                                        onClick={() => {
                                                            setSuccursale(1); setFranchise(0); getLastClient(parseInt(match.params.id), "Succursale");
                                                        }}
                                                    />
                                                    &nbsp;
                                                    &nbsp;
                                                    <Radio
                                                        label='Franchise'
                                                        name='radioGroup'
                                                        value={1}
                                                        checked={franchise == 1}

                                                        onClick={() => { setFranchise(1); setSuccursale(0); getLastClient(parseInt(match.params.id), "Franchise"); }}
                                                    />
                                                </div>
                                                <Accordion fluid styled>
                                                    <Accordion.Title
                                                        active={activeIndex === 0}
                                                        index={0}
                                                        onClick={handleClick}
                                                        style={{ fontSize: 'large', color: 'black' }}>
                                                        <i className="dropdown icon" ></i>
                                                        Informations Agence
                                                    </Accordion.Title>
                                                    <Accordion.Content active={activeIndex === 0}>
                                                        <Form.Group unstackable widths={2}>
                                                            <div className="field">
                                                                <label>Enseigne/Réseau * </label>
                                                                <Form.Input name='companyName' component={TextInput} defaultValue={match.params.requestId ? request?.companyName : null} placeholder="Enseigne/Réseau" onChange={handleChangeCompanyName}
                                                                />
                                                            </div>
                                                            <div className="field">
                                                                <label>Raison Social * </label>
                                                                <Form.Input name='socialName' component={TextInput} placeholder='Raison Social ' defaultValue={match.params.requestId ? request?.socialName : null} onChange={handleChangeSocialName} />
                                                            </div>
                                                        </Form.Group>
                                                        <Form.Group unstackable widths={3}>
                                                            <div className="field">
                                                                <label>Nom du responsable * </label>
                                                                <Field name='nomResponsable' component={TextInput} defaultValue={match.params.requestId ? request?.deliveryLastName : null} placeholder='Nom du responsable' />
                                                            </div>
                                                            <div className="field">
                                                                <label>Prenom du responsable *</label>
                                                                <Field name='prenomResponsable' component={TextInput} defaultValue={match.params.requestId ? request?.deliveryFirstName : null} placeholder='Prenom du responsable' />
                                                            </div>
                                                            <div className="field">
                                                                <label>Civilite </label>
                                                                <Field component={InputSelect} options={ITListTypeCivilite} name='civilite' defaultValue="Monsieur" placeholder="Choisir civilite" />
                                                            </div>
                                                        </Form.Group>
                                                        <Form.Group unstackable widths={3}>
                                                            <div className="field">
                                                                <label>Téléphone Mobile *</label>
                                                                <Field name='mobile' component={TextInput} defaultValue={match.params.requestId ? request?.phone : null} placeholder="Telephone mobile" />
                                                            </div>
                                                            <div className="field">
                                                                <label>Téléphone</label>
                                                                <Field name='telephone' component={InputNumber} placeholder="Telephone" maxLength={4} />
                                                            </div>
                                                            <div className="field">
                                                                <label>Email *</label>
                                                                <Field name='email' component={TextInput} defaultValue={match.params.requestId ? request?.email : null} placeholder="E-mail" />
                                                            </div>
                                                        </Form.Group>
                                                        <div style={{ width: '100%', display: 'inline-block' }}>
                                                            <Button
                                                                onClick={handleClickNext}
                                                                color='orange'
                                                                content='suivant'
                                                                style={{ width: '20%', float: 'right' }}
                                                                fluid
                                                                as={Link}
                                                            />
                                                        </div>
                                                    </Accordion.Content>
                                                </Accordion>
                                                <Accordion fluid styled>
                                                    <Accordion.Title
                                                        onClick={handleClick}
                                                        active={activeIndex === 1}
                                                        index={1}
                                                        style={{ fontSize: 'large', color: 'black' }}>
                                                        <i className="dropdown icon"></i>
                                                        Informations Adresses

                                                    </Accordion.Title>
                                                    <Accordion.Content active={activeIndex === 1}>
                                                        <Grid columns={2}>
                                                            <div className="field" style={{ marginLeft: '90%' }}>
                                                                <label>Même adresse </label>
                                                                <Checkbox toggle value="mAdresse" onChange={() => setMAdresse(!mAdresse)} />
                                                            </div>
                                                            <Grid.Row>

                                                                <Grid.Column>
                                                                    <div className="field">
                                                                        <label>Société de livraison </label>
                                                                        <Form.Input name='deliveryCompany' placeholder='Société de livraison' defaultValue={match.params.requestId ? request?.deliveryCompany : null} onChange={handleChangeDeliveryCompany} />
                                                                    </div>
                                                                    <div className="field">
                                                                        <label>Adresse de livraison </label>
                                                                        <Form.Input name='deliveryAddress1' placeholder='Adresse de livraison' defaultValue={match.params.requestId ? request?.deliveryAddress1 : null}
                                                                            onChange={handleChangeDeliveryAddress1} />
                                                                    </div>
                                                                    <div className="field">
                                                                        <label>Complément d'adresse livraison</label>
                                                                        <Form.Input name='deliveryAddress2' placeholder='Complément d adresse livraison' defaultValue={match.params.requestId ? request?.deliveryAddress2 : null}
                                                                            onChange={handleChangedeliveryAddress2} />
                                                                    </div>
                                                                    <div className="field">
                                                                        <label>Pays de livraison </label>
                                                                        <AsyncSelect
                                                                            name="form-field-name"
                                                                            noOptionsMessage={() => "Code introuvable"}
                                                                            cacheOptions
                                                                            defaultOptions
                                                                            placeholder={deliveryCountry}
                                                                            value={deliveryCountry}
                                                                            getOptionLabel={e => e!.pays + '(' + e!.iso + ')'}
                                                                            getOptionValue={e => e!.iso}
                                                                            loadOptions={baseRepo.countryCodeStore.getCountryCodes}
                                                                            onChange={handleChangedeliveryCountry}
                                                                        />
                                                                    </div>
                                                                    {deliveryCountry == "FR" ?
                                                                        <div className="field">
                                                                            <label>Code postal livraison</label>
                                                                            <AsyncSelect
                                                                                name="form-field-name"
                                                                                noOptionsMessage={() => "Code introuvable"}
                                                                                cacheOptions
                                                                                defaultOptions
                                                                                placeholder={codePostalLiv}
                                                                                value={codePostalLiv}
                                                                                getOptionLabel={e => e!.city + '(' + e!.codePostal + ')'}
                                                                                getOptionValue={e => e!.codePostal}
                                                                                loadOptions={baseRepo.cityCodeStore.getCityCodes}
                                                                                onChange={handleChangedeliveryPostalCode}
                                                                            />
                                                                        </div> :
                                                                        <Grid.Column>
                                                                            <label>Code postal livraison</label>
                                                                            <Form.Input name='postalCode' component={TextInput} type='text' defaultValue={match.params.requestId ? request?.deliveryPostalCode : null} onChange={handleChangePostalCode} placeholder="Code postal facturation" />
                                                                        </Grid.Column>
                                                                    }
                                                                    <div className="field">
                                                                        <label>Ville de livraison </label>
                                                                        <Form.Input name='deliveryCity' value={deliveryCity} placeholder="Ville de Livraison" defaultValue={match.params.requestId ? request?.deliveryCity : null} onChange={handleChangedeliveryCity} />
                                                                    </div>

                                                                    <div className="field">
                                                                        <label>Email de livraison </label>
                                                                        <Form.Input name='deliveryEmails' placeholder="Email de Livraison" defaultValue={match.params.requestId ? request?.deliveryEmail : null} onChange={handleChangedeliveryEmail} />
                                                                    </div>
                                                                    <div className="field">
                                                                        <label>Téléphone de livraison </label>
                                                                        <Form.Input name='deliveryPhone' placeholder="Téléphone de Livraison" defaultValue={match.params.requestId ? request?.deliveryPhone : null} onChange={handleChangedeliveryPhone} />
                                                                    </div>
                                                                    <div className="field">
                                                                        <label>Zone d'intervention</label>
                                                                        <Field name='interventionZone' component={TextInput} placeholder="Zone d'intervention" />
                                                                    </div>
                                                                </Grid.Column>
                                                                <Grid.Column>
                                                                    <div className="field">
                                                                        <label>Société de facturation </label>
                                                                        <Form.Input name='invoiceCompany' placeholder='Société de Facturation' disabled={mAdresse && invoiceCompany == null} defaultValue={match.params.requestId ? request?.socialName : mAdresse ? deliveryCompany : null} onChange={handleChangeinvoiceCompany} />
                                                                    </div>
                                                                    <div className="field">
                                                                        <label>Adresse de facturation </label>
                                                                        <Form.Input name='invoiceAddress1' placeholder='adresse de Facturation' disabled={mAdresse} defaultValue={match.params.requestId ? request?.invoiceAddress1 : mAdresse ? deliveryAddress1 : null} onChange={handleChangeinvoiceAddress1} />
                                                                    </div>
                                                                    <div className="field">
                                                                        <label>Complément d'adresse facturation</label>
                                                                        <Form.Input name='invoiceAddress2' placeholder='Complément d adresse facturation' disabled={mAdresse} defaultValue={match.params.requestId ? request?.invoiceAddress2 : mAdresse ? deliveryAddress2 : null} onChange={handleChangeinvoiceAddress2} />
                                                                    </div>
                                                                    <div className="field">
                                                                        <label>Pays de facturation </label>
                                                                        <AsyncSelect
                                                                            name="form-field-name"
                                                                            noOptionsMessage={() => "Code introuvable"}
                                                                            cacheOptions
                                                                            defaultOptions
                                                                            isDisabled={mAdresse}
                                                                            defaultValue={mAdresse ? deliveryCountry : null}
                                                                            placeholder={mAdresse ? deliveryCountry : invoiceCountry}
                                                                            value={mAdresse ? deliveryCountry : invoiceCountry}
                                                                            getOptionLabel={e => e!.pays + '(' + e!.iso + ')'}
                                                                            getOptionValue={e => e!.iso}
                                                                            loadOptions={baseRepo.countryCodeStore.getCountryCodes}
                                                                            onChange={handleChangeinvoiceCountry}
                                                                        />
                                                                    </div>
                                                                    {invoiceCountry == "FR" ?
                                                                        <div className="field">
                                                                            <label>Code postal facturation </label>
                                                                            <AsyncSelect
                                                                                name="form-field-name"
                                                                                noOptionsMessage={() => "Code introuvable"}
                                                                                cacheOptions
                                                                                defaultOptions
                                                                                isDisabled={mAdresse}
                                                                                defaultValue={mAdresse ? codePostalLiv : null}
                                                                                placeholder={mAdresse ? codePostalLiv : codePostalFac}
                                                                                value={mAdresse ? codePostalLiv : codePostalFac}
                                                                                getOptionLabel={e => e!.city + '(' + e!.codePostal + ')'}
                                                                                getOptionValue={e => e!.codePostal}
                                                                                loadOptions={baseRepo.cityCodeStore.getCityCodes}
                                                                                onChange={handleChangeinvoicePostalCode}
                                                                            />
                                                                        </div> :
                                                                        <div className="field">
                                                                            <label>Code postal facturation</label>
                                                                            <Form.Input name='postalCode' component={TextInput} type='text' disabled={mAdresse} defaultValue={match.params.requestId ? request?.invoicePostalCode : null} onChange={handleChangePostalCodeinvoice} placeholder="Code postal facturation" />
                                                                        </div>
                                                                    }
                                                                    <div className="field">
                                                                        <label>Ville de facturation </label>
                                                                        <Form.Input name='invoiceCity' placeholder='Ville de Facturation' disabled={mAdresse} defaultValue={match.params.requestId ? request?.invoiceCity : mAdresse ? deliveryCity : invoiceCity} onChange={handleChangeinvoiceCity} />
                                                                    </div>
                                                                    <div className="field">
                                                                        <label>Email de facturation </label>
                                                                        <Form.Input name='invoiceEmails' placeholder='Email de Facturation' disabled={mAdresse} defaultValue={match.params.requestId ? request?.invoiceEmail : mAdresse ? deliveryEmails : invoiceEmails} onChange={handleChangeinvoiceEmails} />
                                                                    </div>
                                                                    <div className="field">
                                                                        <label>Téléphone de facturation </label>
                                                                        <Form.Input name='invoicePhone' placeholder='Téléphone de Facturation' disabled={mAdresse} defaultValue={match.params.requestId ? request?.invoicePhone : mAdresse ? deliveryPhone : invoicePhone} onChange={handleChangeinvoicePhone} />
                                                                    </div>
                                                                </Grid.Column>
                                                            </Grid.Row>
                                                        </Grid>
                                                        <div >
                                                            <Button
                                                                onClick={handleClickPrev}
                                                                color='orange'
                                                                content='Retour'
                                                                style={{ width: '20%', display: 'inline-block', marginLeft: '2%' }}
                                                                fluid
                                                                basic
                                                                as={Link}
                                                            />
                                                            <Button
                                                                onClick={handleClickNext}
                                                                color='orange'
                                                                content='Suivant'
                                                                style={{ width: '20%', display: 'inline-block', float: 'right' }}
                                                                fluid
                                                                as={Link}
                                                            />
                                                        </div>
                                                    </Accordion.Content>
                                                </Accordion>
                                                <Accordion fluid styled>
                                                    <Accordion.Title
                                                        onClick={handleClick}
                                                        active={activeIndex === 2}
                                                        index={2}
                                                        style={{ fontSize: 'large', color: 'black' }}>
                                                        <i className="dropdown icon" ></i>
                                                        Spécification
                                                    </Accordion.Title>
                                                    <Accordion.Content active={activeIndex === 2}>
                                                        <Grid columns={2}>
                                                            <Grid.Column>
                                                                <h5>Jours de fermeture</h5>
                                                                <Select
                                                                    isMulti
                                                                    placeholder={'Choisisez les jours ..'}
                                                                    options={ITListJoursFermeture}
                                                                    value={requestDays}
                                                                    getOptionLabel={e => e!.text}
                                                                    getOptionValue={e => e!.value}
                                                                    classNamePrefix="select"
                                                                    onChange={handleChange}
                                                                />
                                                            </Grid.Column>
                                                            <Grid.Column>
                                                                <h5>Horaires d'ouverture</h5>
                                                                <Field name='deliveryOpeningHours' component={TextInput} defaultValue={match.params.requestId ? request?.deliveryOpeningHours : null} placeholder="Horaires d'ouverture" />
                                                            </Grid.Column>
                                                            <Grid.Column>
                                                                <h5>Digicode</h5>
                                                                <Field name='deliveryDigiCode' component={TextInput} placeholder='Digicode' type='text' />
                                                            </Grid.Column>
                                                            <Grid.Column>
                                                                <h5>Interphone</h5>
                                                                <Field name='deliveryInterphone' component={TextInput} placeholder='Interphone' type='text' />
                                                            </Grid.Column>
                                                        </Grid>
                                                        <div style={{ width: '100%', marginTop: '20px' }}>
                                                            <Button
                                                                onClick={handleClickPrev}
                                                                color='orange'
                                                                content='Retour'
                                                                style={{ width: '20%', display: 'inline-block' }}
                                                                fluid
                                                                basic
                                                                as={Link}
                                                            />
                                                            <Button
                                                                onClick={handleClickNext}
                                                                color='orange'
                                                                content='Suivant'
                                                                style={{ width: '20%', display: 'inline-block', float: 'right' }}
                                                                fluid
                                                                as={Link}
                                                            />
                                                        </div>
                                                    </Accordion.Content>
                                                </Accordion>
                                                <Accordion fluid styled>
                                                    <Accordion.Title
                                                        onClick={handleClick}
                                                        active={activeIndex === 3}
                                                        index={3}
                                                        style={{ fontSize: 'large', color: 'black' }}>
                                                        <i className="dropdown icon" ></i>
                                                        Informations Commerciales
                                                    </Accordion.Title>
                                                    <Accordion.Content active={activeIndex === 3}>
                                                        <Grid columns={2}>
                                                            {/*<Grid.Column unstackable>*/}
                                                            {/*    <div className="field">*/}
                                                            {/*        <label>Compte Collectif </label>*/}
                                                            {/*        <Field component={TextInput} name='compteCollectif' defaultValue='411000' placeholder='Compte Collectif ' />*/}
                                                            {/*    </div>*/}
                                                            {/*</Grid.Column>*/}
                                                            {/*<Grid.Column>*/}
                                                            {/*    <div className="field">*/}
                                                            {/*        <label>Compte Auxiliere </label>*/}
                                                            {/*        <Field component={TextInput} name='compteAuxiliere' placeholder={countClient} />*/}
                                                            {/*    </div>*/}
                                                            {/*</Grid.Column>*/}
                                                            <Grid.Column>
                                                                <div className="field">
                                                                    <label>Mode de paiement *</label>
                                                                    <Select
                                                                        options={ITListModePaiements}
                                                                        styles={customStyles}
                                                                        getOptionLabel={e => e!.text}
                                                                        value={modePaiement}
                                                                        onChange={e => { setLabelModePaiement(e!.text); setModePaiement(e!.key); }}
                                                                        placeholder={labelModePaiement}
                                                                    />
                                                                </div>
                                                            </Grid.Column>
                                                            <Grid.Column>
                                                                <div className="field">
                                                                    <label>Echéance *</label>
                                                                    {/*<Field component={InputSelect} options={ITListModeReglement} name='modeReglement' placeholder='Echéance' />*/}
                                                                    <Select
                                                                        options={ITListModeReglement}
                                                                        styles={customStyles}
                                                                        getOptionLabel={e => e!.text}
                                                                        value={modeReglement}
                                                                        onChange={e => { labelModePaiement == 'Carte Bancaire' ? setModeReglement('comptant') : setLabelModeReglement(e!.text); setModeReglement(e!.value); }}
                                                                        placeholder={labelModePaiement == 'Carte Bancaire' ? 'comptant' : labelModeReglement}
                                                                        isDisabled={labelModePaiement == 'Carte Bancaire'}
                                                                    />
                                                                </div>
                                                            </Grid.Column>
                                                            <Grid.Column>
                                                                <h5>Facturer par</h5>
                                                                <Select
                                                                    placeholder={'Choisisez un choix ..'}
                                                                    options={ITListBillingType}
                                                                    getOptionLabel={e => e!.text}
                                                                    getOptionValue={e => e!.value}
                                                                    classNamePrefix="select"
                                                                    onChange={(e) => { setBilledType(e!.value) }}
                                                                />
                                                            </Grid.Column>
                                                            {/*<Grid.Column>*/}
                                                            {/*    <div className="field">*/}
                                                            {/*    <label>Commission</label>*/}
                                                            {/*    <Checkbox value="commissionType" onChange={() => setTypeCommission(!typeCommission)} defaultChecked={franchiseur?.commissionType} />*/}
                                                            {/*    </div>*/}
                                                            {/*</Grid.Column>*/}
                                                            {/*<Grid.Column>*/}
                                                            {/*    {typeCommission &&*/}
                                                            {/*        <div className="field">*/}
                                                            {/*    <label>Valeur commission</label>*/}
                                                            {/*    <Field component={InputNumber} name='commissionValue' placeholder='Valeur commission' type="number" defaultValue={franchiseur?.commissionValue} />*/}
                                                            {/*        </div>*/}
                                                            {/*    }*/}
                                                            {/*</Grid.Column>*/}
                                                        </Grid>
                                                        <div style={{ width: '100%', marginTop: '20px' }}>
                                                            <Button
                                                                onClick={handleClickPrev}
                                                                color='orange'
                                                                content='Retour'
                                                                style={{ width: '20%', display: 'inline-block' }}
                                                                fluid
                                                                basic
                                                                as={Link}
                                                            />
                                                            <Button
                                                                onClick={handleClickNext}
                                                                color='orange'
                                                                content='Suivant'
                                                                style={{ width: '20%', display: 'inline-block', float: 'right' }}
                                                                fluid
                                                                as={Link}
                                                            />
                                                        </div>
                                                    </Accordion.Content>
                                                </Accordion>
                                                <Accordion fluid styled>
                                                    <Accordion.Title
                                                        onClick={handleClick}
                                                        active={activeIndex === 4}
                                                        index={4}
                                                        style={{ fontSize: 'large', color: 'black' }}>
                                                        <i className="dropdown icon" ></i>
                                                        Informations Juridiques
                                                    </Accordion.Title>
                                                    <Accordion.Content active={activeIndex === 4}>
                                                        <Form.Group unstackable widths={3}>
                                                            <div className="field">
                                                                <h5>Forme juridique</h5>
                                                                <Field component={InputSelect} options={ITListFormeJuridique} name='formeJuridique' />
                                                            </div>
                                                            <div className="field">
                                                                <label>Capital</label>
                                                                <Field name='capitalValue' component={InputNumber} type="number" placeholder='Capital' />
                                                            </div>
                                                            <div className="field">
                                                                <label>Montant pack Ouverture</label>
                                                                <Field name='openingPackAmount' component={TextInput} type="number" placeholder="Montant pack Ouverture" />
                                                            </div>
                                                        </Form.Group>
                                                        <Form.Group unstackable widths={2}>
                                                            <div className="field">
                                                                <label>RCS</label>
                                                                <Field name='rcs' component={TextInput} placeholder='RCS' />
                                                            </div>
                                                            <div className="field">
                                                                <label>Siret </label>
                                                                <Field name='siretNumber' component={TextInput} defaultValue={match.params.requestId ? request?.siretNumber : null} placeholder='Siret' />
                                                            </div>
                                                        </Form.Group>
                                                        <Form.Group unstackable widths={2}>
                                                            <div className="field">
                                                                <label>Tva IntraCommunautaire</label>
                                                                <Field name='europeanTvaNumber' component={TextInput} placeholder='Tva IntraCommunautaire' />
                                                            </div>
                                                            <div className="field">
                                                                <label>Code Ape</label>
                                                                <Field name='apeCode' component={TextInput} placeholder='Code Ape' />
                                                            </div>
                                                        </Form.Group>
                                                        <Form.Group unstackable widths={2}>
                                                            <div className="field">
                                                                <label>Site Web </label>
                                                                <Field name='webSite' component={TextInput} placeholder='Site Web' />
                                                            </div>
                                                            <div className="field">
                                                                <label>Numéro d'Agrement</label>
                                                                <Field name='agreementNumber' component={TextInput} placeholder="Numéro d'Agrement" />
                                                            </div>
                                                        </Form.Group>
                                                        <div style={{ width: '100%' }}>
                                                            <Button
                                                                onClick={handleClickPrev}
                                                                color='orange'
                                                                content='Retour'
                                                                style={{ width: '20%', display: 'inline-block' }}
                                                                fluid
                                                                basic
                                                                as={Link}
                                                            />
                                                            <Button
                                                                style={{ width: '20%', display: 'inline-block', marginLeft: '59.3%' }}
                                                                disabled={invalid || pristine}
                                                                loading={submitting}
                                                                color='orange'
                                                                content='Sauvegarder'
                                                                fluid
                                                            />
                                                        </div>
                                                    </Accordion.Content>
                                                </Accordion>

                                            </Form>
                                        </div>
                                    )}
                                />
                            </Segment >
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </div>
        );
}
export default observer(AddClient);
