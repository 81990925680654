import { BaseStore } from "./baseStore";
import { observable, action, runInAction, reaction, toJS, computed } from 'mobx';
import agent from '../agent/agent';
import { history } from '../../index';
import { toast } from "react-toastify";
import { IClient, IPhoto, IFichier, IClientList, ICompanyList, IClientInfoEnveloppe, IInfo, ISolde, ISoldeEnveloppe, IRequest, IPersonalPhoto } from "../models/IClient";
import { setClientProps } from "../common/util/util";
import { IAdresse } from "../models/IAdresse";

const LIMIT = 15;

export default class ClientStore {
    _baseStore: BaseStore;
    constructor(baseStore: BaseStore) {
        this._baseStore = baseStore;
        reaction(
            () => this.predicate.keys(),
            () => {
                this.page = 0;
                this.clientRegestry.clear();
                this.loadClientsFranchise(this.idFranchiseur);
            }
        )
        reaction(
            () => this.predicateSolde.keys(),
            () => {
                this.pageSolde = 0;
                this.loadSoldes();
            }
        )
        reaction(
            () => this.activeTab,
            activeTab => {
                if (activeTab != '')
                    return true;
            }
        )
    }
    @observable loadingAddresseAction = false;
    @observable loadingPhotoAction = false;
    @observable loadingInitial = false;
    @observable transforming = false;
    @observable loadingClient = false;
    @observable loadingSoldes = false;
    @observable exporting = false;
    @observable loadingExport = false;
    @observable submitting = false;
    @observable loadFile = false;
    @observable deletingClient = false;
    @observable uploadingPhoto = false;
    @observable uploadingFile = false;
    @observable client: IClient | null = null;
    @observable clients: IClient[] = [];
    @observable infos: IInfo[] = [];
    @observable count=0;
    @observable clientRegestry = new Map();
    @observable clientsCount = 0;
    @observable soldes: ISolde[] = [];
    @observable page = 0
    @observable pageSolde = 0
    @observable predicate = new Map();
    @observable predicateSolde = new Map();
    @observable activeTab: string = "";
    @observable idFranchiseur: number = 0;
    @observable ITListClient: IClientList[] = [];
    @observable companies: ICompanyList[] = [];
    @observable SoldesCount = 0;
    @observable countClient = 0;
    @observable totalSolde = 0;
    @observable totalCredit = 0;
    @observable totalDebit = 0;
    @observable setModal = false;



    @computed get axiosParams() {
        const params = new URLSearchParams();
        params.append('limit', String(LIMIT));
        params.append('offset', `${this.page ? this.page * LIMIT : 0}`);
        params.append('offsetSolde', `${this.pageSolde ? this.pageSolde * LIMIT : 0}`);
        this.predicate.forEach((value, key) => {
            if (key === 'startDate') {
                params.append(key, value.toISOString())
            } else {
                params.append(key, value)
            }
        })
        this.predicateSolde.forEach((value, key) => {
            if (key === 'startDate') {
                params.append(key, value.toISOString())
            } if (key === 'searchMultiple') {
                var searchClient = [...value][0];
                var solde = [...value][1];
                var dateCommande = [...value][2];
                var dateCommandeTo = [...value][3];
                var searchResultsClientAll = [...value][4];
                params.append(searchClient[0], searchClient[1])
                params.append(solde[0], solde[1])
                params.append(dateCommande[0], dateCommande[1])
                params.append(dateCommandeTo[0], dateCommandeTo[1])
                params.append(searchResultsClientAll[0], searchResultsClientAll[1])
            }else {
                params.append(key, value)
            }
        })
        return params;
    }

    @computed get totalPages() {
        return Math.ceil(this.clientsCount / LIMIT);
    }
    @computed get totalPagesSolde() {
        return Math.ceil(this.SoldesCount / LIMIT);
    }


    @action setPage = (page: number) => {
        this.page = page;
    }
    @action setPageSolde = (page: number) => {
        this.pageSolde = page;
    }

    @action setChangeStateModal = (state: boolean) => {
        this.setModal = state;
    }

    @action setInfo = (address: IAdresse, type: string) => {
        if (type == "Invoice") {
            this.client!.invoiceAddress2 = address.additionalAddress;
            this.client!.invoiceAddress1 = address.address;
            this.client!.invoiceCity = address.city;
            this.client!.invoiceCountry = address.country;
            this.client!.invoicePostalCode = address.postalCode;
            this.client!.invoiceCompany = address.companyName;
            this.client!.invoicePhone = address.phone;
        }
        else {
            this.client!.deliveryAddress2 = address.additionalAddress;
            this.client!.deliveryAddress1 = address.address;
            this.client!.deliveryCity = address.city;
            this.client!.deliveryCountry = address.country;
            this.client!.deliveryPostalCode = address.postalCode;
            this.client!.deliveryCompany = address.companyName;
            this.client!.deliveryPhone = address.phone;
        }
    }

    @action updateAdress = (address: IAdresse) => {
        if (address.isMainInvoiceAddress) {
            this.client!.invoiceAddress2 = address.additionalAddress;
            this.client!.invoiceAddress1 = address.address;
            this.client!.invoiceCity = address.city;
            this.client!.invoiceCountry = address.country;
            this.client!.invoicePostalCode = address.postalCode;
            this.client!.invoiceCompany = address.companyName;
            this.client!.invoicePhone = address.phone;
        }
        if (address.isMainDeliveryAddress) {
            this.client!.deliveryAddress2 = address.additionalAddress;
            this.client!.deliveryAddress1 = address.address;
            this.client!.deliveryCity = address.city;
            this.client!.deliveryCountry = address.country;
            this.client!.deliveryPostalCode = address.postalCode;
            this.client!.deliveryCompany = address.companyName;
            this.client!.deliveryPhone = address.phone;
        }
    }
    @observable editClientMode: boolean = false;


    @computed get clientByCodeClient() {
        return this.sortByCodeClient(Array.from(this.clientRegestry.values()));
    }

    @action setPredicate = (predicate: string, value: string | Date) => {
        this.predicate.clear();
        if (predicate !== 'all') {
            this.predicate.set(predicate, value);
        }
    }
    @action setPredicateSolde = (predicate: string, value: any) => {
        if (this.predicateSolde.get(predicate)) {
            this.soldes = [];
            this.predicateSolde.clear();
        }
        if (predicate == 'all') {
            this.predicateSolde.clear();
        }
        else {
            this.predicateSolde.set(predicate, value);
        }
    }


    @action setActiveTab = async (activeIndex: string) => {
        runInAction(() => {
            this.activeTab = activeIndex;
        })
    }

    sortByCodeClient(clients: IClient[]) {
        const sotedByFranchiseur = clients.sort((a, b) => a.agencyName.localeCompare(b.agencyName));
        return Object.entries(
            sotedByFranchiseur.reduce(
                (clients, client) => {
                    const code = client.agencyName;
                    clients[code] = clients[code]
                        ? [...clients[code], client]
                        : [client];
                    return clients;
                },
                {} as { [key: string]: IClient[] }
            )
        );
    }

    @action loadClients = async () => {
        this.ITListClient = [];
        this.loadingInitial = true;
        try {
            const clientEnveloppe = await agent.clientAgent.list(this.axiosParams);
            const { clients, clientCount } = clientEnveloppe;
            runInAction(() => {
                clients.forEach((client) => {
                    setClientProps(client);
                    this.clientRegestry.set(client.agencyName, client);
                    let clients: IClientList = {
                        key: client.agencyName,
                        text: client.agencyName + ", " + client.companyName,
                        value: client.agencyName,
                    }
                    this.ITListClient.push(clients)
                })
                this.clientsCount = clientCount;
                this.loadingInitial = false;
            })
        } catch (e) {
            runInAction(() => {
                this.loadingInitial = false;
            })
            console.log(e);
        }
    }

    @action loadClientsFranchise = async (id: number) => {
        this.loadingInitial = true;
        try {
            if (this.idFranchiseur != id)
                this.clientRegestry.clear();
            this.idFranchiseur = id;
            const clientEnveloppe = await agent.clientAgent.listClientsFranchise(this.idFranchiseur, this.axiosParams);
            const { clients, clientCount } = clientEnveloppe;
            runInAction(() => {
                if (this.page == 0)
                    this.clients = [];
                clients.forEach((client) => {
                    this.clients.push(client);
                })
                this.clientsCount = clientCount;
                this.loadingInitial = false;
            })
        } catch (e) {
            runInAction(() => {
                this.loadingInitial = false;
            })
            console.log(e);
        }
    }
    @action loadClientsFranchiseCustom = async (id: number) => {
        this.loadingInitial = true;
        this.idFranchiseur = id;
        const paramsObj = { limit: '', offset: '' };
        const searchParams = new URLSearchParams(paramsObj);
        const clientEnveloppe = await agent.clientAgent.listClientsFranchise(id, searchParams);
        runInAction(() => {
            this.loadingInitial = false;
        })
        return clientEnveloppe;

    }

    @action changeButtonState = async (newState: boolean) => {
        this.editClientMode = newState;
    }

    @action loadClient = async (id: number) => {
        this.loadingClient = true;
        let client = this.getClient(id);
        if (client) {
            this.client = client;
            this.loadingClient = false;
            return toJS(client);
        }
        else {
            try {
                client = await agent.clientAgent.details(id);
                runInAction(() => {
                    client.dateCreation = client.dateCreation.split('T')[0];
                    this.client = client;
                    this.clientRegestry.set(client.clientId, client);
                    this.loadingClient = false;
                })
                return client;
            } catch (e) {
                runInAction(() => {
                    history.push('/franchiseurDashboard');
                    this.loadingClient = false;
                })
                console.log(e);
            }
        }
    }

    @action editClientComptabilite = async (values: Partial<IClient>) => {
        this.submitting = true;
        try {
            await agent.clientAgent.updateComptabilite(values);
            runInAction(() => {
                this.client = { ...this.client!, ...values };
                this.submitting = false;
                this.changeButtonState(false);
                toast.success("Fournisseur modifié avec succès");
            })
        } catch (e) {
            runInAction(() => {
                this.submitting = false;
            })
            toast.error("Error submitting Data");
        }
    }

    getClient = (id: number) => {
        return this.clientRegestry.get(id);
    }

    @action uploadClient = async (file: Blob, id: string) => {
        this.submitting = true;
        try {
            await agent.clientAgent.uploadClient(file, id);
            runInAction(() => {
                this.submitting = false;
                toast.success("Chargement de franchisé(s) éffectué avec succès");
            })
            history.push(`/clients/${id}`);
        } catch (e) {
            runInAction(() => {
                this.submitting = false;
            })
            toast.error("Erreur Chargement de franchisé(s)");
            history.push(`/addClient/${id}`);
        }
    };

    @action editClient = async (values: Partial<IClient>) => {
        this.submitting = true;
        try {
            await agent.clientAgent.update(values);
            runInAction(() => {
                this.clientRegestry.set(values.id, values);
                this.client = { ...this.client!, ...values };
                this.submitting = false;
                this.changeButtonState(false);
                toast.success("Franchisé modifié avec succès");
            })
        } catch (e) {
            runInAction(() => {
                this.submitting = false;
            })
            toast.error("Error submitting Data");
        }
    }

    @action editClientGestionCommercial = async (values: Partial<IClient>) => {
        this.submitting = true;
        try {
            await agent.clientAgent.updateGestionCommercial(values);
            runInAction(() => {
                this.client = { ...this.client!, ...values };
                this.submitting = false;
                this.changeButtonState(false);
                toast.success("Franchisé modifié avec succès");
            })
        } catch (e) {
            runInAction(() => {
                this.submitting = false;
            })
        }
    }

    @action editClientspecification = async (values: Partial<IClient>) => {
        this.submitting = true;
        try {
            await agent.clientAgent.updateSpecification(values);
            runInAction(() => {
                this.client = { ...this.client!, ...values };
                this.submitting = false;
                this.changeButtonState(false);
                toast.success("Spécification du Franchisé a été modifié avec succès");
            })
        } catch (e) {
            runInAction(() => {
                this.submitting = false;
            })
            toast.error("Error submitting Data");
        }
    }
    @action disable = async (id: number) => {
        this.deletingClient = true;
        try {
            await agent.clientAgent.disable(id);
            runInAction(() => {
                this.deletingClient = false;
                this.client!.isActive = false;
                toast.success("Franchisé désactivé avec succès");
            })
        } catch (e) {
            console.log(e);
            toast.error("Problème désactivation client!");
            runInAction(() => {
                this.deletingClient = false;
            })
        }
    }

    @action enable = async (id: number) => {
        this.deletingClient = true;
        try {
            await agent.clientAgent.enable(id);
            runInAction(() => {
                this.deletingClient = false;
                this.client!.isActive = true;
                toast.success("Franchisé activé avec succès");
            })
        } catch (e) {
            console.log(e);
            toast.error("Problème d'activation client!");
            runInAction(() => {
                this.deletingClient = false;
            })
        }
    };

    @action uploadPhoto = async (file: any, id: number) => {
        this.uploadingPhoto = true;
        try {
            const photo = await agent.clientAgent.uploadPhoto(file, file.fileName, id);
            runInAction(() => {
                if (this.client) {
                    this.client.personalPhotos.push(photo);
                }
                this.uploadingPhoto = false;
                toast.success("Chargement de photo effectué avec succès");
            })
        } catch (e) {
            console.log(e);
            toast.error("Problème de mise à jour photo!");
            runInAction(() => {
                this.uploadingPhoto = false;
            })
        }
    }

    @action uploadFile = async (file: Blob, id: number) => {
        this.uploadingFile = true;
        try {
            const fichier = await agent.clientAgent.uploadFichier(file, id);
            runInAction(() => {
                if (this.client) {
                    this.client.fichiers.push(fichier);
                }
                this.uploadingFile = false;
                toast.success("Chargement de fichier est effectué avec succès");
            })
        } catch (e) {
            console.log(e);
            toast.error("Problème de mise à jour fichier!");
            runInAction(() => {
                this.uploadingFile = false;
            })
        }
    }

    @action deleteFile = async (file: IFichier, id: number) => {
        this.deletingClient = true;
        try {
            await agent.clientAgent.deleteFichier(file.id, id);
            runInAction(() => {
                this.client!.fichiers = this.client!.fichiers.filter(a => a.id !== file.id);
                this.deletingClient = false;
                toast.success("Suppression de fichier est effectué avec succès");
            })
        } catch (e) {
            console.log(e);
            toast.error("Problème de suppresion du fichier!");
            runInAction(() => {
                this.deletingClient = false;
            })
        }
    }

    @action setMainPhoto = async (photo: IPhoto, id: number) => {
        this.loadingPhotoAction = true;
        try {
            await agent.clientAgent.setMainPhoto(photo.id, id);
            runInAction(() => {
                this._baseStore.userStore.user!.image = photo.url;
                this.client!.photos.find(a => a.isMain)!.isMain = false;
                this.client!.photos.find(a => a.id === photo.id)!.isMain = true;
                this.client!.image = photo.url;
                this.loadingPhotoAction = false;
                toast.success("Photo affectée avec succès");
            })
        } catch (e) {
            console.log(e);
            toast.error("Problème de mise à jour photo de franchisé!");
            runInAction(() => {
                this.loadingPhotoAction = false;
            })
        }
    };

    @action deletePhoto = async (photo: IPersonalPhoto, id: number) => {
        this.loadingPhotoAction = true;
        try {
            await agent.clientAgent.deletePhoto(photo.id, id);
            runInAction(() => {
                this.client!.personalPhotos = this.client!.personalPhotos.filter(a => a.id !== photo.id);
                this.loadingPhotoAction = false;
                toast.success("Photo supprimée avec succès");
            })
        } catch (e) {
            console.log(e);
            toast.error("Problème de suppresion de la photo!");
            runInAction(() => {
                this.loadingPhotoAction = false;
            })
        }
    }

    @action editClientGestionMemo = async (values: Partial<IClient>) => {
        this.submitting = true;
        try {
            await agent.clientAgent.updateGestionMemo(values);
            runInAction(() => {
                this.client = { ...this.client!, ...values };
                this.submitting = false;
                this.changeButtonState(false);
                toast.success("Modification effectuée avec succès");
            })
        } catch (e) {
            runInAction(() => {
                this.submitting = false;
            })
            toast.error("Error submitting Data");
        }
    }

    @action generateAccountClient = async (id: number) => {
        this.submitting = true;
        try {
            await agent.clientAgent.generateAccountClient(id);
            runInAction(() => {
                this.submitting = false;
                toast.success("Le compte a été généré avec succes");
            })
        } catch (e) {
            runInAction(() => {
                this.submitting = false;
            })
            toast.error("Error submitting Data");
        }
    }

    @action create = async (client: IClient) => {
        this.submitting = true;
        try {
            var id = await agent.clientAgent.create(client, client.franchisorId.toString());
            runInAction(() => {
                this.submitting = false;
                toast.success("Chargement de franchisé(s) éffectué avec succès");
            })
            history.push(`/detailClient/${id}`);
        } catch (e) {
            runInAction(() => {
                this.submitting = false;
            })
            toast.error("Erreur Chargement de franchisé(s)");
        }
    }

    @action loadCompaniesList = async (franchiseur: number, type: string) => {
        this.loadingInitial = true;
        try {
            const company = await agent.clientAgent.listBySearch(franchiseur, type);
            runInAction(() => {
                this.companies = company;
                this.loadingInitial = false;
            })
        }
        catch (e) {
            runInAction(() => {
                this.loadingInitial = false;
            })
            console.log(e);
        }
    }

    loadOptions = async (inputValue: string | null) => {
        if (!inputValue || !inputValue.trim() || inputValue.length < 2) {
            return await agent.clientAgent.companiesList(null).then(r => r.data);
        } else
            return await agent.clientAgent.companiesList(inputValue).then(r => r.data);
    };
    loadClientOptions = async (inputValue: string | null) => {
        if (!inputValue || !inputValue.trim() || inputValue.length < 2) {
            return await agent.clientAgent.searchClient(null).then(r => r.data);
        } else
            return await agent.clientAgent.searchClient(inputValue).then(r => r.data);
    };
    @action addFranchise = async (file: Blob) => {
        this.loadFile = true;
        try {
            var clientInfoEnveloppe = await agent.clientAgent.addFranchise(file);
            const { info, count } = clientInfoEnveloppe;
            runInAction(() => {
                this.loadFile = false;
                this.infos = info;
                this.count = count;
                count == 0 ? toast.warning('duplication des franchises !! ') : this.setModal=true;
            })


        } catch (e) {
            runInAction(() => {
                this.loadFile = false;
            })
            toast.error("Erreur Chargement de franchisé(s)");
        }
    };
    @action getLastClient = async (id: number, type: string) => {
        this.loadingInitial = true;
        try {
            const countClient = await agent.clientAgent.getLastClient(id, type);
            runInAction(() => {
                this.countClient = countClient;
                this.loadingInitial = false;
            })
        } catch (e) {
            runInAction(() => {
                this.loadingInitial = false;
            })
            console.log(e);
        }
    }

    @action ExportFranchise = async (id: number) => {
        this.exporting = true;
        try {
            await agent.clientAgent.exportExcel(id, this.axiosParams);
            runInAction(() => {
                this.exporting = false;
            })
        } catch (e) {
            runInAction(() => {
                this.exporting = false;
            })
            console.log(e);
        }
    }
    @action loadSoldes = async () => {
        this.loadingSoldes = true;
        try {
            const soldesEnveloppe = await agent.clientAgent.listSolde(this.axiosParams);
            const { soldesList, soldesCount, totalSolde, totalCredit,totalDebit } = soldesEnveloppe;
            runInAction(() => {
                soldesList.forEach((solde) => {
                    if (this.pageSolde == 0) {
                        this.soldes = soldesList;
                    } else {
                        this.soldes.push(solde);
                    }
                })
                this.SoldesCount = soldesCount;
                this.totalSolde = totalSolde;
                this.totalDebit = totalDebit;
                this.totalCredit = totalCredit;
                this.loadingSoldes = false;
            })
        } catch (e) {
            runInAction(() => {
                this.loadingSoldes = false;
            })
            console.log(e);
        }
    }
    @action ExportSolde = async (obj: any) => {
        this.loadingExport = true;
        try {
            await agent.clientAgent.exportSolde(obj);
            runInAction(() => {
                this.loadingExport = false;
            })
        } catch (e) {
            runInAction(() => {
                this.loadingExport = false;
            })
            console.log(e);
        }
    }
    @action exportData = async (obj: any) => {
        this.loadingExport = true;
        try {
            await agent.clientAgent.exportData(obj);
            runInAction(() => {
                this.loadingExport = false;
            })
        } catch (e) {
            runInAction(() => {
                this.loadingExport = false;
            })
            console.log(e);
        }
    }

    @action changeType = async (id: number) => {
        this.transforming = true;
        try {
            var client = await agent.clientAgent.changeType(id);
            runInAction(() => {
                if (this.client && this.client.id == client.id)
                {
                    this.client!.agencyName = client.agencyName!;
                    this.client!.companyType = client.companyType!;
                }
                this.transforming = false;
            })
        } catch (e) {
            runInAction(() => {
                this.transforming = false;
            })
            console.log(e);
        }
    }

   
}