import { useContext, useEffect, useState } from 'react';
import { Tab, Header, Button, Grid, Table, Progress, Modal } from 'semantic-ui-react';
import { BaseStoreContext } from '../../../app/store/baseStore';
import { observer } from 'mobx-react-lite';
import AddCommandeArticle from './AddCommandeArticle';
import AucunProduit from './AucunProduit';
import DetailOrderLine from './DetailOrderLine';
import DeleteOrderLineModal from './DeleteOrderLineModal';
import { IOrderLine, IOrderDilevryInfo } from '../../../app/models/ICommande';
import EditCommandeArticleModal from './EditCommandeArticleModal';
import Select from 'react-select/';
import { ITListModeAppro } from '../../../app/common/options/IAchat';
import _ from 'lodash';
import { CgMoreVerticalO } from 'react-icons/cg';
import { Helmet } from 'react-helmet';

const getStatus = (status: string) => {
    switch (status) {
        case 'VenteDirectePersonalise':
            return 'Prod interne';
        case 'Stock':
            return 'Stock';
        case 'VenteDirecte':
            return 'Vente Directe';
        default:
            return '';
    }
}

const getLineState = (status: string) => {
    switch (status) {
        case 'PREPARING':
            return 'En cours de préparation';
        case 'RECEIVED':
            return 'Livré';
        case 'CONTROLLING':
            return 'En cours de contrôle';
        case 'READY':
            return 'Prêt pour expédition';
        case 'SUPPLYING':
            return 'En cours de réapprovisionnement';
        case 'DELIVERING':
            return 'En cours de livraison';
        case 'FILEOK':
            return 'Fichier normalisé';
        case 'MAKING':
            return 'En cours de fabrication';
        case 'PARTIALLY_DELIVERED':
            return 'livré partiellement';
        default:
            return '';
    }
}

export const checkDeliveryState = (orderDeliveryInfoes: IOrderDilevryInfo[], quantity: number) => {
    var received = orderDeliveryInfoes.find(x => x.status == "RECEIVED" && x.deliveredQuantity == quantity);
    if (received != undefined)
        return received
    var qttReceived = orderDeliveryInfoes.filter(x => x.status == "RECEIVED");
    var qtt = 0;
    qttReceived.map((info) => (qtt += info.deliveredQuantity));
    if (qtt >= quantity) {
        var orderDeliveryInfo: IOrderDilevryInfo =
        {
            deliveredQuantity: quantity,
            deliveryService: "",
            status: "RECEIVED",
            id: "",
            trackingRef: "",
            updateDate: new Date()
        }
        return orderDeliveryInfo
    }
    else if (qtt > 0) {
        var orderDeliveryInfo: IOrderDilevryInfo =
        {
            deliveredQuantity: qtt,
            deliveryService: "",
            status: "RECEIVED",
            id: "",
            trackingRef: "",
            updateDate: new Date()
        }
        return orderDeliveryInfo
    }
    else {
        var restQtt = quantity - qtt;
        var partialReceived = orderDeliveryInfoes.find(x => x.deliveredQuantity == restQtt);
        if (restQtt != quantity && partialReceived != undefined)
            return partialReceived
        else if (orderDeliveryInfoes.length > 0)
            return orderDeliveryInfoes.sort((a, b) => (a.updateDate > b.updateDate ? -1 : 1))[0]
        else
            return (
                {
                    deliveredQuantity: quantity,
                    deliveryService: "",
                    status: "CONTROLLING",
                    id: "",
                    trackingRef: "",
                    updateDate: new Date()
                }
            )
    }
}



const CommandeAticle = () => {
    const baseStore = useContext(BaseStoreContext)
    const { commande, deletingCommandeArticle, progress, submitting, orderline, editModeAppro } = baseStore.commandeStore;
    const { packs, loadListe } = baseStore.articleComposeStore;
    const [orderLine, setOrderLine] = useState<IOrderLine>();
    const [modalOpen, setModalOpen] = useState(false);
    const [addArticleMode, setAddArticleMode] = useState(false);
    const [detailArticleMode, setDetailArticleMode] = useState(false);
    const [deleteTarget, setDeleteTarget] = useState<number | undefined>(undefined);
    const { openModal } = baseStore.modalStore;
    const [ids, setIds] = useState<number[]>([]);
    const [targetId, setTargetId] = useState<number | null>();
    const [appro, setAppro] = useState("");

    const handleChangeMode = (e: any) => {
        setAppro(e);
    }
    const handleAffect = (e: any, listIds: number[]) => {
        let newObj: any = {
            ids: listIds,
            modeAppro: e,
        };
        editModeAppro(newObj);
    }

    const handleCheck = (key: number, value: any) => {
        if (value.target.checked) {
            setIds([...ids, key]);
        } else {
            setIds(_.without(ids, key));
        }
    }

    const handleCheckAll = (value: any) => {
        var tab: number[] = [];
        if (value.target.checked) {
            commande?.orderLines.map((line) => {
                if (checkDeliveryState(line.orderDeliveryInfos, line.quantity).status != "RECEIVED" && !line.isControling)
                    tab.push(line.id);
            });
            setIds(tab);
        } else {
            setIds([]);
        }
    }



    useEffect(() => {
        loadListe();
    }, [])
    return (
        <Tab.Pane>
            <Modal
                open={modalOpen}
                size='large'
                closeOnEscape={true}
                closeOnRootNodeClick={true}
            >
                <Modal.Header icon='add' >
                    Modifier l'article
                    <Button
                        style={{ height: 35 }}
                        type='button' icon='x' color='red'
                        floated='right'
                        onClick={() => { setModalOpen(false) }}
                    >
                    </Button>
                </Modal.Header>
                <Modal.Content>
                    <EditCommandeArticleModal orderLine={orderLine!} order={commande!} setModalOpen={setModalOpen} />
                </Modal.Content>
            </Modal>
            {detailArticleMode ? (
                <Grid>
                    <Grid.Column width={16} style={{ paddingBottom: 0 }}>
                        <Header floated='left' icon='file' content='Detail ligne de commande:' />
                        <Button
                            onClick={() => setDetailArticleMode(!detailArticleMode)}
                            floated='right'
                            basic
                            content={"Retour"}
                        />
                    </Grid.Column>
                    &nbsp;
                    &nbsp;
                    <DetailOrderLine order={orderline!} />
                </Grid>
            ) : (
                <Grid>
                    <Grid.Column width={16} style={{ paddingBottom: 0 }}>
                        <Header floated='left' icon='shopping basket' content='Articles' />
                        <Button
                            onClick={() => setAddArticleMode(!addArticleMode)}
                            floated='right'
                            basic
                            content={addArticleMode ? 'Annuler' : 'Ajouter article'}
                        />
                        {ids.length > 0 &&
                            <div style={{ display: 'flex', width: '500px' }}>
                                <span style={{ width: 200, marginLeft: 20, }} >
                                    <Select
                                        getOptionLabel={e => e.text}
                                        getOptionValue={e => e.value}
                                        components={{
                                            IndicatorSeparator: () => null
                                        }}
                                        options={ITListModeAppro}
                                        placeholder="mode Appro"
                                        onChange={(e, id: any) => handleChangeMode(e!.value)}
                                        theme={(theme) => ({
                                            ...theme,

                                            colors: {
                                                ...theme.colors,
                                                text: 'grey',
                                                primary25: 'grey',
                                                primary: 'grey',

                                            },
                                        })}
                                    />
                                </span>
                                <Button
                                    onClick={(e: any) => handleAffect(appro, ids)}
                                    basic
                                    color='green'
                                    style={{ display: appro == "" ? 'none' : 'block', marginLeft: 20, paddingBottom: 2 }}
                                    content={'Affecter'}
                                />

                            </div>
                        }

                    </Grid.Column>
                    <Grid.Column width={16}>
                        {addArticleMode ? (
                            <AddCommandeArticle
                                commandeId={commande!.id}
                                setAddArticleMode={setAddArticleMode}
                            />
                        ) : (
                            <>
                                {submitting &&
                                    <Progress color='orange' percent={progress} size='tiny' />
                                }
                                {commande?.orderLines.length == 0 ? (
                                    <AucunProduit />
                                ) :
                                    (
                                        <Table loading={false} className="productTable">
                                            <Table.Header >
                                                <Table.Row className='productTableHeader'>
                                                    {/*<Table.HeaderCell>Image</Table.HeaderCell>*/}
                                                    <Table.HeaderCell>
                                                        <input style={{ marginRight: 10, position: 'absolute', top: 30, left: 30 }} type="checkbox" onChange={(value) => handleCheckAll(value)} />
                                                        Article
                                                    </Table.HeaderCell>
                                                    <Table.HeaderCell style={{ color: "#db2828" }}>Circuit APPRO</Table.HeaderCell>
                                                    <Table.HeaderCell>Quantité</Table.HeaderCell>
                                                    <Table.HeaderCell>ÉTAT</Table.HeaderCell>
                                                    <Table.HeaderCell>Remise</Table.HeaderCell>
                                                    <Table.HeaderCell style={{ whiteSpace: 'nowrap' }}>Montant HT</Table.HeaderCell>
                                                    <Table.HeaderCell></Table.HeaderCell>
                                                </Table.Row>
                                            </Table.Header>
                                            <Table.Body >
                                                {commande &&
                                                    commande.orderLines.map((orderLine, index) => (
                                                        <>
                                                            <Table.Row key={orderLine.productId} >
                                                                {/*<Table.Cell><Image src={"https://etikeo.blob.core.windows.net/product-images/product-" + order.productId + ".jpg"} /></Table.Cell>*/}
                                                                <Table.Cell rowspan={orderLine.comment == "" || orderLine.comment == null ? 1 : 2} >
                                                                    <Grid style={{ display: 'flex' }}>

                                                                        <div style={{ padding: 0, width: '100%' }}>
                                                                            {orderLine.isService ?
                                                                                <input disabled={orderLine?.isControling} type="checkbox" style={{ marginRight: 10, position: 'relative', top: 0, left: 0 }} checked={ids.includes(orderLine.id)} onChange={(value) => handleCheck(orderLine.id, value)} />
                                                                                : (
                                                                                    <>
                                                                                        {checkDeliveryState(orderLine.orderDeliveryInfos, orderLine.quantity).status != "RECEIVED" && <input disabled={orderLine?.isControling} type="checkbox" style={{ marginRight: 10, position: 'relative', top: 0, left: 0 }} checked={ids.includes(orderLine.id)} onChange={(value) => handleCheck(orderLine.id, value)} />}
                                                                                    </>
                                                                                )
                                                                            }
                                                                            <a href={orderLine.isService ? `/detailService/${orderLine.productId}` : `/detailArticle/${orderLine.productId}`} >
                                                                                {orderLine.articleCode} - {orderLine.articleName}
                                                                            </a>
                                                                            <CgMoreVerticalO style={{ backgroundColor: 'none', color: "grey", cursor: 'pointer', float: 'right' }} onMouseOver={(e) => setTargetId(orderLine?.id)} onMouseOut={(e) => setTargetId(null)} />

                                                                        </div>
                                                                        {targetId == orderLine.id && orderLine.isService == false &&
                                                                            <div id="infoMore" style={{ position: 'absolute', border: '1px solid #eee', borderRadius: 5, backgroundColor: 'white', zIndex: 1 }}>
                                                                                {orderLine.paperFormat &&
                                                                                    <span style={{ minHeight: 'auto', border: "none" }}><b>Format:&nbsp;</b>{orderLine.paperFormat}</span>
                                                                                }
                                                                                {orderLine.paperWeight &&
                                                                                    <span style={{ minHeight: 'auto', border: "none" }}><b>Support:&nbsp;</b>{orderLine.paperWeight}</span>
                                                                                }
                                                                                {orderLine.printing &&
                                                                                    <span style={{ minHeight: 'auto', border: "none" }}><b>Impression:&nbsp;</b>{orderLine.printing}</span>
                                                                                }
                                                                                <span style={{ minHeight: 'auto', border: "none" }}><b>Poids:&nbsp;</b>{orderLine.weight} Kg</span>
                                                                                <span style={{ minHeight: 'auto', border: "none" }}><b>Finition:&nbsp;</b>{orderLine.option1}, {orderLine.option2}, {orderLine.option3}</span>

                                                                            </div>}
                                                                        <Grid style={{ paddingRight: 0 }}>
                                                                            {orderLine.orderPacks &&
                                                                                orderLine.orderPacks.map((pack) => (
                                                                                    <>
                                                                                        <label style={{ fontSize: 12, fontWeight: 700, paddingLeft: 10, paddingRight: 0 }}>{pack.productId} - {pack.label} / {pack.quantity}</label>

                                                                                    </>
                                                                                ))}
                                                                        </Grid>
                                                                        <Grid>
                                                                            {orderLine.productAttributeValues.map(productAttributeValue => (
                                                                                <>
                                                                                    <br />
                                                                                    <label style={{ fontSize: 12, paddingLeft: 30 }}>
                                                                                        <b>{productAttributeValue.productAttributeName}: </b>
                                                                                        {productAttributeValue.value}</label>
                                                                                </>
                                                                            ))
                                                                            }
                                                                        </Grid>
                                                                    </Grid>
                                                                </Table.Cell>
                                                                <Table.Cell>
                                                                    <span style={{ justifyContent: 'right' }}>

                                                                        <Select
                                                                            isDisabled={orderLine?.isControling}
                                                                            getOptionLabel={e => e.text}
                                                                            getOptionValue={e => e.value}
                                                                            components={{
                                                                                IndicatorSeparator: () => null
                                                                            }}
                                                                            options={ITListModeAppro}
                                                                            placeholder={getStatus(orderLine?.modeAppro)}
                                                                            onChange={(e, id: any) => handleAffect(e!.value, [orderLine.id])}

                                                                            theme={(theme) => ({
                                                                                ...theme,
                                                                                borderRadius: 0,
                                                                                colors: {
                                                                                    ...theme.colors,
                                                                                    text: 'red',
                                                                                    primary25: 'red',
                                                                                    primary: 'red',
                                                                                },
                                                                            })}
                                                                        />
                                                                    </span>
                                                                </Table.Cell>
                                                                <Table.Cell>
                                                                    <span style={{ justifyContent: 'right' }}>
                                                                        {orderLine.quantity}
                                                                    </span>
                                                                </Table.Cell>
                                                                <Table.Cell>
                                                                    {orderLine.isService ?
                                                                        <span>
                                                                            Service
                                                                        </span> :
                                                                        <span>
                                                                            {checkDeliveryState(orderLine.orderDeliveryInfos, orderLine.quantity).deliveredQuantity} | {getLineState(checkDeliveryState(orderLine.orderDeliveryInfos, orderLine.quantity).status)}
                                                                        </span>
                                                                    }
                                                                </Table.Cell>
                                                                <Table.Cell>
                                                                    <span style={{ justifyContent: 'right' }}>
                                                                        {orderLine.remise} %
                                                                    </span>
                                                                </Table.Cell>
                                                                <Table.Cell>
                                                                    <span style={{ justifyContent: 'right' }}>
                                                                        {(orderLine.quantity * orderLine.unitPrice - (orderLine.quantity * orderLine.unitPrice) * orderLine.remise / 100).toFixed(2)} €
                                                                    </span>
                                                                </Table.Cell>


                                                                {(!orderLine?.isControling || (checkDeliveryState(orderLine.orderDeliveryInfos, orderLine.quantity).status != 'RECEIVED' || (checkDeliveryState(orderLine.orderDeliveryInfos, orderLine.quantity).status == 'RECEIVED' && checkDeliveryState(orderLine.orderDeliveryInfos, orderLine.quantity).deliveredQuantity != orderLine.quantity))) &&
                                                                    <Table.Cell>
                                                                        <Button.Group fluid widths={3}>
                                                                            <Button
                                                                                onClick={(e) => {
                                                                                    setModalOpen(true);
                                                                                    setOrderLine(orderLine);
                                                                                }}
                                                                                basic
                                                                                color="blue"
                                                                                icon='edit outline'
                                                                                name={orderLine.id}
                                                                                style={{ marginRight: '2%' }}
                                                                            />
                                                                            {!orderLine?.isControling &&
                                                                                <Button
                                                                                    name={orderLine.productId}
                                                                                    onClick={(e) => {
                                                                                        openModal(<DeleteOrderLineModal OrderLine={orderLine!} OrderId={commande.id} />)
                                                                                        setDeleteTarget(parseInt(e.currentTarget.name))
                                                                                    }}
                                                                                    loading={deletingCommandeArticle && deleteTarget === orderLine.productId}
                                                                                    basic
                                                                                    negative
                                                                                    icon='trash'
                                                                                />
                                                                            }
                                                                        </Button.Group>
                                                                    </Table.Cell>
                                                                }

                                                            </Table.Row>
                                                            {orderLine.comment != "" && orderLine.comment != null &&
                                                                <Table.Row style={{ marginLeft: 5, }}>
                                                                    <Table.Cell colspan={5} style={{ borderTop: 'none', paddingTop: 0, fontSize: 12, fontWeight: 700 }}>
                                                                        <div style={{ padding: '3px 0 0 10px', borderLeft: '1px solid rgba(34,36,38,.1)', borderTop: '1px solid rgba(34,36,38,.1)', }}>
                                                                            {orderLine.comment}
                                                                        </div>
                                                                    </Table.Cell>
                                                                </Table.Row>
                                                            }
                                                        </>
                                                    ))}
                                            </Table.Body>
                                        </Table>)}
                            </>
                        )}
                    </Grid.Column>
                </Grid>
            )}
            <Helmet>
                <script>
                    document.getElementById("infoMore").style.left="400px";
                </script>
            </Helmet>
        </Tab.Pane>
    );
};
export default observer(CommandeAticle);