import React, { useContext, useState } from 'react';
import { Tab, Header, Card, Image, Button, Grid } from 'semantic-ui-react';
import { BaseStoreContext } from '../../../app/store/baseStore';
import PhotoUploadWidget from '../../../app/common/photoUpload/PhotoUploadWidget';
import { observer } from 'mobx-react-lite';

const ClientPhoto = () => {
    const baseStore = useContext(BaseStoreContext)
    const { client, uploadPhoto, uploadingPhoto, deletePhoto, loadingPhotoAction } = baseStore.clientStore;

    const [addPhotoMode, setAddPhotoMode] = useState(false);
    const [deleteTarget, setDeleteTarget] = useState<string | undefined>(undefined);
    const handleUploadImage = (photo: Blob, id = client!.id) => {
        uploadPhoto(photo, id).then(() => setAddPhotoMode(false));
    }


    return (
        <Tab.Pane>
            <Grid>
                <Grid.Column width={16} style={{ paddingBottom: 0 }}>
                    <Header floated='left' icon='image' content='Logo' />
                    <Button
                        onClick={() => setAddPhotoMode(!addPhotoMode)}
                        floated='right'
                        basic
                        content={addPhotoMode ? 'Annuler' : 'Ajouter Photo'}
                    />
                </Grid.Column>
                <Grid.Column width={16}>
                    {addPhotoMode ? (
                        <PhotoUploadWidget
                            uploadPhoto={handleUploadImage}
                            loading={uploadingPhoto}
                        />
                    ) : (
                        <Card.Group itemsPerRow={5}>
                            {client &&
                                client.personalPhotos.map(photo => (
                                    <Card key={photo.id}>
                                        <Image src={photo.url} />
                                        {(
                                            <Button.Group fluid widths={2}>
                                                <Button
                                                    name={photo.id}
                                                    onClick={(e) => {
                                                        deletePhoto(photo, client!.id);
                                                        setDeleteTarget(e.currentTarget.name)
                                                    }}
                                                    loading={loadingPhotoAction && deleteTarget === photo.id.toString()}
                                                    basic
                                                    negative
                                                    icon='trash'
                                                />
                                            </Button.Group>
                                        )}
                                    </Card>
                                ))}
                        </Card.Group>
                    )}
                </Grid.Column>
            </Grid>
        </Tab.Pane>
    );
};

export default observer(ClientPhoto);
