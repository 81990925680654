import { BaseStore } from "./baseStore";
import { observable, action, runInAction } from 'mobx';
import agent from '../agent/agent';
import { IRequest } from "../models/IClient";
import { toast } from "react-toastify";

export default class RequestStore {
    _baseStore: BaseStore;
    constructor(baseStore: BaseStore) {
        this._baseStore = baseStore;
    }
    @observable loadingInitial = false;
    @observable deleting = false;
    @observable updating = false;
    @observable requests: IRequest[] = [];
    @observable request: IRequest | null = null;


    @action loadRequests = async () => {
        this.loadingInitial = true;
        try {
            var requests = await agent.requestAgent.getRequests();
            runInAction(() => {
                this.requests = requests;
                this.loadingInitial = false;
            })
        } catch (e) {
            runInAction(() => {
                this.loadingInitial = false;
            })
        }
    }

    @action loadRequest = async (id: number) => {
        this.loadingInitial = true;
        try {
            var request = await agent.requestAgent.getRequest(id);
            runInAction(() => {
                this.request = request;
                this.loadingInitial = false;
            })
            return request;
        } catch (e) {
            runInAction(() => {
                this.loadingInitial = false;
            })
        }
    }


    @action deleteRequest = async (id: number) => {
        this.deleting = true;
        try {
            await agent.requestAgent.deleteRequest(id);
            runInAction(() => {
                this.requests = this.requests.filter(x => x.id != id);
                this.deleting = false;
                this._baseStore.statisticStore.requestsCount--;
                toast.success("suppression éffectuée avec succès");
            })
        } catch (e) {
            runInAction(() => {
                this.deleting = false;
            })
        }
    }

    @action updateRequest = async (id: number, compnyId: number) => {
        this.updating = true;
        try {
            let request = await agent.requestAgent.updateRequest(id, compnyId);
            runInAction(() => {
                let index = this.requests.findIndex(x => x.id == id);
                this.requests[index].agencyName = request.agencyName;
                this.requests[index].network = request.network;
                this.requests[index].networkId = request.networkId;
                this.updating = false;
                toast.success("La demande a été modifiée avec succès");
            })
        } catch (e) {
            runInAction(() => {
                this.updating = false;
            })
        }
    }
}