import React, { useContext, useEffect } from 'react';
import { Form as FinalForm, Field } from 'react-final-form';
import { Form, Button, Grid, Divider } from 'semantic-ui-react';
import TextInput from '../../../app/common/form/inputText';
import { observer } from 'mobx-react-lite';
import { IClient } from '../../../app/models/IClient';
import { BaseStoreContext } from '../../../app/store/baseStore';
import InputSelect from '../../../app/common/form/inputSelect';
import { ITListTypeCivilite } from '../../../app/common/options/IAchat';
import InputNumber from '../../../app/common/form/inputNumber';

interface IProps {
    editClient: (profile: Partial<IClient>) => void;
    client: IClient;
}

const ProfileEditForm: React.FC<IProps> = ({ editClient, client }) => {
    const baseStore = useContext(BaseStoreContext);
    const { loadFormeJuridiques, ITListFormeJuridique } = baseStore.formeJuridiqueStore;
    const handleFinalFormSubmit = (values: any) => {

        let newFranchiseur: Partial<IClient> = {
            ...values,

        };
        editClient(newFranchiseur);
        baseStore.clientStore.changeButtonState(false)

    }
    useEffect(() => {
        loadFormeJuridiques();
    }, [loadFormeJuridiques])

    return (
        <FinalForm
            onSubmit={handleFinalFormSubmit}
            initialValues={client!}
            render={({ handleSubmit, invalid, pristine, submitting }) => (
                <Form onSubmit={handleSubmit} error>
                    <Grid columns={2}  >
                        <Grid.Column>
                            <h5 >Nom agence</h5>
                            <Field name='companyName' component={TextInput} placeholder='Nom agence' type='text' value={client!.companyName} />
                        </Grid.Column>
                        <Grid.Column>
                            <h5 >Denomination social</h5>
                            <Field name='socialName' component={TextInput} placeholder='Denomination social' type='text' value={client!.socialName} />
                        </Grid.Column>
                    </Grid>
                    <Divider />
                    <Grid columns={3}  >
                        <Grid.Column>
                            <h5 >Civilité</h5>
                            <Field name='deliverySalutations' component={InputSelect} options={ITListTypeCivilite} value={client!.deliverySalutations} />
                        </Grid.Column>
                        <Grid.Column>
                            <h5>Nom responsable</h5>
                            <Field name='deliveryLastName' component={TextInput} placeholder='Nom responsable' type='text' value={client!.deliveryLastName} />
                        </Grid.Column>
                        <Grid.Column>
                            <h5>Prenom responsable</h5>
                            <Field name='deliveryFirstName' component={TextInput} placeholder='Prenom responsable' type='text' value={client!.deliveryFirstName} />
                        </Grid.Column>
                    </Grid> 
                    <Divider />
                    <Grid columns={3}>

                        <Grid.Column>
                            <h5>Zone d'intervention</h5>
                            <Field name='interventionZone' component={TextInput} placeholder="Zone d'intervention" type='text' value={client!.interventionZone} />
                        </Grid.Column>
                        <Grid.Column>
                            <h5>capital</h5>
                            <Field name='capitalValue' component={TextInput} placeholder='capital' type='number' value={client!.capital} />
                        </Grid.Column>
                        <Grid.Column>
                            <h5>Montant pack ouverture</h5>
                            <Field name='openingPackAmount' component={InputNumber} placeholder='Montant pack ouverture' type='number' value={client!.openingPackAmount} />
                        </Grid.Column>
                        <Grid.Column>
                            <h5>RCS</h5>
                            <Field name='rcs' component={TextInput} placeholder='RCS' type='text' value={client!.rcs} />
                        </Grid.Column>
                        <Grid.Column>
                            <h5>Siret</h5>
                            <Field name='siretNumber' component={TextInput} placeholder='Siret' type='text' value={client!.siretNumber} />
                        </Grid.Column>
                        <Grid.Column>
                            <h5>Numéro d'agrement</h5>
                            <Field name='agreementNumber' component={TextInput} placeholder="Numéro d' agrement" type='text' value={client!.agreementNumber} />
                        </Grid.Column>
                        <Grid.Column>
                            <h5>Code APE</h5>
                            <Field name='apeCode' component={TextInput} placeholder='Code APE' type='text' value={client!.apeCode} />
                        </Grid.Column>
                        <Grid.Column>
                            <h5>Site Web</h5>
                            <Field name='website' component={TextInput} placeholder='Site Web' type='text' value={client!.website} />
                        </Grid.Column>
                        <Grid.Column>
                            <h5>Tva intra communautaire</h5>
                            <Field name='europeanTvaNumber' component={TextInput} placeholder='Tva intra communautaire' type='text' value={client!.europeanTvaNumber} />
                        </Grid.Column>
                        <Grid.Column>
                            <h5>Forme juridique</h5>
                            <Field component={InputSelect} options={ITListFormeJuridique} name='formeJuridique' defaultValue={client!.formeJuridique} value={client!.formeJuridique} />
                        </Grid.Column>
                        <Grid.Column>
                            <h5>Emails de validation en copies</h5>
                            <Field name='validationCopyEmails' component={TextInput} placeholder='Emails de validation en copies' type='text' value={client!.validationCopyEmails} />
                        </Grid.Column>
                    </Grid >

                    <div>
                        <Button.Group floated="right">
                            <Button disabled={submitting} onClick={() => { baseStore.clientStore.changeButtonState(!baseStore.clientStore.editClientMode) }} floated='right' type='button'>Annuler</Button>
                            <Button.Or />
                            <Button positive disabled={submitting || invalid || pristine} loading={submitting} floated='right' type='submit'>Confirmer</Button>
                        </Button.Group>
                    </div>
                </Form>
            )}
        />
    );
};

export default observer(ProfileEditForm);