import { observer } from 'mobx-react-lite';
import React, { useContext, useEffect } from 'react';
import { Button } from 'react-bootstrap';
import { Header, Message, Tab, Table } from 'semantic-ui-react';
import { string } from 'yup';
import Loading from '../../../app/layout/Loading';
import { BaseStoreContext } from '../../../app/store/baseStore';
import AucunProduit from './AucunProduit';

interface IProps {
    orderId: number;
}


const CustomizedOrderLine: React.FC<IProps> = ({ orderId }) => {

    const baseStore = useContext(BaseStoreContext);
    const { GetCustomizedFileByOrder, loadingCustomization, customOrderlineList, GetCustomizedFilePDFAzure } = baseStore.commandeStore;

    useEffect(() => {
        GetCustomizedFileByOrder(orderId);
    }, [orderId]);

    function changeBackground(e:any) {
        e.target.style.color = 'red';
    }
    function returnBackground(e:any) {
        e.target.style.color = 'black';
    }

    if (loadingCustomization) return <Loading content="Chargement des Fichiers ..." />;
    return (
        <Tab.Pane>
            <Header floated='left' icon='file pdf' content='Fichiers Personnalisés' />
            {customOrderlineList.length == 0 ? (
                <div style={{ marginTop: '50px' }}>
                    <Message
                        error
                        list={[
                            "Cette Commande ne contient pas de fichiers Personnalisés",
                        ]}
                    />
                </div>
            ) :
                (
                    <Table loading={false} >
                        <Table.Header >
                            <Table.Row >
                                <Table.HeaderCell>Nom du Document</Table.HeaderCell>
                                <Table.HeaderCell>Type</Table.HeaderCell>
                                <Table.HeaderCell>Commentaire</Table.HeaderCell>
                                <Table.HeaderCell style={{ color: "#db2828" }}>Face</Table.HeaderCell>
                            </Table.Row>
                        </Table.Header>
                        <Table.Body >
                            {customOrderlineList.map(orderL => (
                                <Table.Row key={orderL.id} >
                                    <Table.Cell><span style={{ border: 'none', justifyContent: 'center' }}>{orderL.articleName}</span></Table.Cell>
                                    <Table.Cell><span style={{ border: 'none', justifyContent: 'center' }}>{orderL.customizationType}</span></Table.Cell>
                                    <Table.Cell><span style={{ border: 'none', justifyContent: 'center' }}>{orderL.customizationComment}</span></Table.Cell>
                                    <Table.Cell><span style={{ border: 'none', justifyContent: 'center' }}>
                                        {!!orderL.customDocumentRecto &&
                                            <Button style={{ cursor: 'pointer' }} onMouseOver={changeBackground} onMouseLeave={returnBackground} onClick={() => GetCustomizedFilePDFAzure(orderL.id, "recto")}>
                                                Recto
                                            </Button>
                                        }<br /><br />
                                        {!!orderL.customDocumentVerso &&
                                            <Button style={{ cursor: 'pointer' }} onMouseOver={changeBackground} onMouseLeave={returnBackground} onClick={() => GetCustomizedFilePDFAzure(orderL.id, "verso")}>
                                                Verso
                                        </Button>
                                        }
                                    </span></Table.Cell>
                                </Table.Row>
                                ))}
                        </Table.Body >
                    </Table>
            )}
        </Tab.Pane>
    );
}

export default observer(CustomizedOrderLine);