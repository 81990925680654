import { IconType } from "react-icons";
import { GridColumn, Icon } from 'semantic-ui-react';

interface IProps {
    backgroundIcon: string,
    backgroundColor: string,
    total: number,
    count: number,
    minus: number,
    name: string,
    iconReact: IconType,
    isDashboard: boolean
}

const TotalCount: React.FC<IProps> = ({ backgroundIcon, backgroundColor, minus, count, name, total, iconReact, isDashboard }) => {
    return (
        <GridColumn style={{ borderRight: "1px solid #ddd", paddingTop: 0, paddingBottom: 0, marginBottom: 0 }}>
            <div style={{ display: "flex", flexWrap: "wrap", alignItems: "center", width: "100%", marginBottom: 10, textAlign: "center" }}>
                <Icon as={iconReact} style={{ background: backgroundIcon, height: 40, width: 40, padding: 7, marginRight: 10, borderRadius: 50, color: "white", float: "left" }} />
                <div style={{ fontSize: 20, fontWeight: 400, marginRight: 10, width: "70%" }}>
                    <span>{name}</span>
                    <div style={{ width: "100%", backgroundColor: "#ddd", height: 3, marginTop: 10 }}>
                        <div style={{ width: ((total - minus) / total * 100).toString() + "%", height: 3, backgroundColor: backgroundColor }}></div>
                    </div>
                </div>
                {isDashboard ?
                    <span style={{ fontSize: 24, fontWeight: 700, marginTop: 10, width: "100%", textAlign: "right" }} className="counter" data-target={count}>{count}</span> :
                    <span style={{ fontSize: 24, fontWeight: 700, marginTop: 10, width: "100%", textAlign: "right" }} >{count}</span>
                }
            </div>
        </GridColumn>
    )
}
export default TotalCount;