import { useContext, useEffect, useState } from 'react';
import { Tab, Header, Button, Grid, Checkbox, Item, ItemContent, Modal } from 'semantic-ui-react';
import { BaseStoreContext } from '../../../app/store/baseStore';
import { observer } from 'mobx-react-lite';
import AddFranchiseurAdresse from './AddFranchiseurAdresse';
import { MdDeleteForever } from 'react-icons/md';
import { FaPlus } from 'react-icons/fa';
import { IAdresse } from '../../../app/models/IAdresse';
import DeleteAdressModal from '../../clients/clientDetails/DeleteAdressModal';
import UpdateAddressModal from '../../clients/clientDetails/UpdateAddressModal';


const FranchiseurAdresse = () => {
    const baseStore = useContext(BaseStoreContext)
    const { adresses, loadAdressesByClient, isMain, loadingIsMain } = baseStore.adresseStore;
    const { franchiseur } = baseStore.franchiseurStore;
    const [modalOpen, setModalOpen] = useState(false);
    const [modalOpenDelete, setModalOpenDelete] = useState(false);
    const [adress, setAdress] = useState<IAdresse>();

    useEffect(() => {
        loadAdressesByClient(franchiseur!.id);
        baseStore.clientStore.changeButtonState(false);
    }, [])


    return (
        <Tab.Pane>
            <Modal
                open={modalOpen}
                size='large'
                closeOnEscape={true}
                closeOnRootNodeClick={true}
            >
                <Modal.Header icon='add' >
                    Modifier l'adresse
                    <Button
                        style={{ height: 35 }}
                        type='button' icon='x' color='red'
                        floated='right'
                        onClick={() => { setModalOpen(false) }}
                    >
                    </Button>
                </Modal.Header>
                <Modal.Content>
                    <UpdateAddressModal adresse={adress!} setModalOpen={setModalOpen} type="franchiseur" />
                </Modal.Content>
            </Modal>
            <Modal
                open={modalOpenDelete}
                size='mini'
                closeOnEscape={true}
                closeOnRootNodeClick={true}
            >

                <Modal.Header icon='add' >
                    Supprimer l'adresse

                </Modal.Header>
                <Modal.Content>
                    <DeleteAdressModal Adress={adress!} setModalOpen={setModalOpenDelete} />
                </Modal.Content>
            </Modal>
            <Grid>
                <Grid.Column width={16} style={{ paddingBottom: 0 }}>
                    <Header floated='left' icon='shopping basket' content={baseStore.franchiseurStore.editFranchiseurMode ? 'Ajout Adresse' : ' Adresses'} />
                    <Button
                        onClick={() => { baseStore.franchiseurStore.changeButtonState(!baseStore.franchiseurStore.editFranchiseurMode) }}
                        floated='right'
                        basic
                        content={baseStore.franchiseurStore.editFranchiseurMode ? 'Annuler' : 'Ajouter Adresse'}
                    />
                </Grid.Column>
                <Grid.Column width={16}>
                    {baseStore.franchiseurStore.editFranchiseurMode ? (
                        <AddFranchiseurAdresse match={franchiseur!.id}
                        />
                    ) : (
                        <>
                            <Grid>
                                {adresses &&
                                    adresses.map(adresse => (
                                        <Grid.Column width={8} >
                                            <Item style={{ borderRadius: 5, border: "1px solid grey" }}>
                                                <ItemContent style={{ padding: 10 }}>
                                                    <h5>{adresse.companyName}</h5>
                                                    <h5>{adresse.phone}</h5>
                                                    <label>{adresse.email?.split(';').map(x => x + "  ")}</label>
                                                    <h5>{adresse.address}</h5>
                                                    <label>{adresse.additionalAddress}</label>
                                                    {adresse.additionalAddress &&
                                                        <label>, </label>
                                                    }
                                                    <label>{adresse.postalCode}, {adresse.city}, {adresse.country} </label>
                                                    <br />
                                                    <Grid >
                                                        <Grid.Column width={8}>
                                                            <Checkbox label="Livraison" disabled={loadingIsMain || adresse.isMainDeliveryAddress} checked={adresse.isMainDeliveryAddress}
                                                                onChange={e => {
                                                                    isMain(adresse!.id, "Delivery","franchiseur");
                                                                }}
                                                            />
                                                        </Grid.Column>
                                                        <Grid.Column width={8}>
                                                            <Checkbox label="Facturation" disabled={loadingIsMain || adresse.isMainInvoiceAddress} checked={adresse.isMainInvoiceAddress}
                                                                onChange={e => {
                                                                    isMain(adresse!.id, "Invoice","franchiseur");
                                                                }}
                                                            />
                                                        </Grid.Column>
                                                    </Grid>
                                                </ItemContent>
                                                <Grid style={{ borderTop: "1px solid grey" }} divided textAlign="center">
                                                    <Grid.Column width={8} >
                                                        <a style={{ cursor: "pointer" }}
                                                            onClick={(e) => {
                                                                setModalOpen(true);
                                                                setAdress(adresse);
                                                            }}>
                                                            <FaPlus />
                                                            <span>Mettre à jour</span></a>
                                                    </Grid.Column>
                                                    <Grid.Column width={8} >
                                                        <a style={{ cursor: "pointer" }}
                                                            onClick={(e) => {
                                                                setModalOpenDelete(true);
                                                                setAdress(adresse);
                                                            }}
                                                        >
                                                            <MdDeleteForever />
                                                            <span>Supprimer</span></a>

                                                    </Grid.Column>
                                                </Grid>
                                            </Item>
                                        </Grid.Column>
                                    ))}
                            </Grid>
                        </>
                    )}
                </Grid.Column>
            </Grid>
        </Tab.Pane>
    );
};
export default observer(FranchiseurAdresse);
