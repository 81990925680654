import React, { Fragment, useState, useEffect } from 'react';
import { Header, Grid, Button } from 'semantic-ui-react';
import { observer } from 'mobx-react-lite';
import PhotoWidgetDropzone from './PhotoWidgetDropzone';
import PhotoWidgetCropper from './PhotoWidgetCropper';

interface IProps {
    loading: boolean;
    uploadPhoto: (photo: Blob) => void;
    updatePhoto?: (photo: Blob) => void;
    isUpdate?: boolean;
}
const PhotoUploadWidget: React.FC<IProps> = ({ loading, uploadPhoto, updatePhoto, isUpdate }) => {

    const [files, setFiles] = useState<any[]>([]);
    const [image, setImage] = useState<Blob | null>(null);

    useEffect(() => {
        return () => {
            files.forEach(file => URL.revokeObjectURL(file.preview));
        };
    });

    return (
        <Fragment>
            <span style={{ color: "blue" }}>{isUpdate ? "Modifier la photo" : "Ajouter une photo"}</span>
            <br />
            <Grid>

                <Grid.Column width={4}>
                    <Header color='teal' sub content='Etape 1 - Ajouter image' />
                    <PhotoWidgetDropzone setFiles={setFiles} />
                </Grid.Column>
                <Grid.Column width={1} />
                <Grid.Column width={4}>
                    <Header sub color='teal' content='Etape 2 - Regler image' />
                    {files.length > 0 &&
                        <PhotoWidgetCropper setImage={setImage} imagePreview={files[0].preview} imageType={files[0].type} imageName={files[0].name}/>
                    }
                </Grid.Column>
                <Grid.Column width={1} />
                <Grid.Column width={4}>
                    <Header sub color='teal' content='Etape 3 - Telechargement' />
                    {files.length > 0 &&
                        <Fragment>
                            <div className='img-preview' style={{ minHeight: '200px', overflow: 'hidden' }} />
                            <div style={{ textAlign: 'center', fontSize: 'medium', fontWeight: 'bold', width: "100%", textOverflow: "ellipsis", overflow: "hidden", whiteSpace: "nowrap" }}>
                                <label >{files[0].name!}</label>
                            </div>
                            <Button.Group widths={2}>
                                <Button positive icon='check' loading={loading} onClick={() => isUpdate ? updatePhoto!(image!) : uploadPhoto(image!)} />
                                <Button negative icon='close' disabled={loading} onClick={() => setFiles([])} />
                            </Button.Group>
                        </Fragment>
                    }
                </Grid.Column>
            </Grid>
        </Fragment>
    );
};

export default observer(PhotoUploadWidget);
