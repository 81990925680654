import React, { useContext, useEffect } from 'react';
import { Menu, Dropdown, Image, Search } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import { BaseStoreContext } from '../../app/store/baseStore';
import { observer } from 'mobx-react-lite';
import { BsBellFill } from 'react-icons/bs';
import { FaHome } from 'react-icons/fa';
import _ from 'lodash';


const source: { title: string; description: string; href: string; }[] = [];
source.push({ title: "ajouter article", description: "ajouter article", href: "/addArticles" });
source.push({ title: "ajouter devis", description: "ajouter devis", href: "/addDevis" });
source.push({ title: "liste articles", description: "liste articles", href: "/articles" });
source.push({ title: "liste fournisseurs", description: "liste fournisseurs", href: "/fournisseurDashboard" });
source.push({ title: "liste franchiseurs", description: "liste franchiseurs", href: "/franchiseurDashboard" });
source.push({ title: "liste commandes", description: "liste commandes", href: "/commandeDashboard" });

const initialState = {
    loading: false,
    results: [],
    value: '',
}

function exampleReducer(state: any, action: any) {
    switch (action.type) {
        case 'CLEAN_QUERY':
            return initialState
        case 'START_SEARCH':
            return { ...state, loading: true, value: action.query }
        case 'FINISH_SEARCH':
            return { ...state, loading: false, results: action.results }
        case 'UPDATE_SELECTION':
            return { ...state, value: action.selection }
        default:
            throw new Error()
    }
}

const NavBar: React.FC = () => {
    const baseStore = useContext(BaseStoreContext);
    const { user, logout } = baseStore.userStore;
    const { notificationCommande } = baseStore.commandeStore;
    const { produitHSCount, load } = baseStore.statisticStore;

    const [state, dispatch] = React.useReducer(exampleReducer, initialState)
    const { loading, results, value } = state
    const timeoutRef = React.useRef<any>()
    const handleSearchChange = React.useCallback((e, data) => {
        clearTimeout(timeoutRef.current)
        dispatch({ type: 'START_SEARCH', query: data.value })
        timeoutRef.current = setTimeout(() => {
            if (data.value.length === 0) {
                dispatch({ type: 'CLEAN_QUERY' })
                return
            }
            const re = new RegExp(_.escapeRegExp(data.value), 'i')
            const isMatch = (result: { title: string; }) => re.test(result.title)
            dispatch({
                type: 'FINISH_SEARCH',
                results: _.filter(source, isMatch),
            })
        }, 300)
    }, [])
    useEffect(() => {
        load();
        return () => {
            clearTimeout(timeoutRef.current)
        }
    }, []);

    return (
        <Menu fixed='top' borderless className="nav-menu"  >
            <a className="toc item toggle-sidebar">
                <i className=" text-white iconfont icon-ziyuan114" style={{ fontSize: "25px", color: "#fff!important" }}></i>
            </a>
            <a className="toc item" href="/menuPage">
                <FaHome style={{ fontSize: "25px", color: "white" }} />
            </a>
            <Search
                style={{ marginLeft: "10%", marginTop: "3px" }}
                loading={loading}
                placeholder='Search...'
                onResultSelect={(e, data) => dispatch({ type: 'UPDATE_SELECTION', selection: data.result.title })}
                onSearchChange={handleSearchChange}
                results={results}
                value={value}
            />
            <Menu.Menu position='right'>
                {user && (
                    <Menu.Item>
                        <div style={{
                            position: 'relative',
                            display: 'inline - block',
                            margin: '20px 20px 10px 20px'
                        }}>
                            <span style={{
                                backgroundColor: '#0378d5',
                                color: '#fff',
                                top: -10,
                                transform: 'translateX(-50 %)', borderRadius: '1em',
                                fontSize: 12,
                                height: 18,
                                left: '60%',
                                fontWeight:700,
                                minWidth: 24,
                                display: 'flex',
                                alignItems:'center',
                                justifyContent:"center",
                                position: 'absolute',
                                width: 'auto',

                            }}>{produitHSCount}</span>
                            <Dropdown pointing='top right' style={{
                                width: 32,
                                height: 32,
                                display: 'inline - block'
                            }} icon={<BsBellFill style={{color:"white",fontSize:23}}/>} >
                                <Dropdown.Menu>
                                    <Dropdown.Item as={Link} to={`/alertStockDashboard`} text={` Vous avez ${produitHSCount} article en alert`} />
                                </Dropdown.Menu>
                            </Dropdown>
                        </div>
                        <Image avatar spaced='right' src={user.image || '/assets/user.png'} />
                        <Dropdown pointing='top right' text={user.displayName} icon='angle down'>
                            <Dropdown.Menu>
                                <Dropdown.Item as={Link} to={`/profil/${user.username}`} text='Mon profil' icon='vcard' />
                                <Dropdown.Item as={Link} to={`/register`} text='Ajouter utilisateur' icon='user' />
                                <Dropdown.Item as={Link} to={`/nouvellesCommandes`} icon={{ name: 'alarm', color: notificationCommande != 0 ? 'red' : "black" }} text={notificationCommande != 0 ? "Nouvelles commandes " + notificationCommande : "Acune commande"} />
                                <Dropdown.Item onClick={logout} text='Logout' icon='power' />
                            </Dropdown.Menu>
                        </Dropdown>
                    </Menu.Item>
                )}
            </Menu.Menu>
        </Menu>
    );
}

export default observer(NavBar);
