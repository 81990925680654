import React, { useContext, useState } from 'react';
import { BaseStoreContext } from '../../../app/store/baseStore';
import { Form, Button, Segment, Accordion, Input, Grid } from 'semantic-ui-react';
import { Form as FinalForm } from 'react-final-form';
import { observer } from 'mobx-react-lite';
import { history } from '../../../index';
import AsyncSelect from 'react-select/async';

const AddCommandeFournisseurContent: React.FC = ({ }) => {
    const baseStore = useContext(BaseStoreContext);
    const { create } = baseStore.commandeFournisseurStore;
    const [fournisseurRadioOn, setFournisseurRadioOn] = useState(true);
    const [fournisseur, setFournisseur] = useState(0);
    const [franchiseurRadioOn, setFranchiseurRadioOn] = useState(false);
    const [franchiseur, setFranchiseur] = useState<any>(null);
    const [selectedLabel, setSelectedLabel] = useState<any>("Franchiseur");
    const [network, setNetWork] = useState<string>("");

    const handleChangeSelect = (value: any) => {
        setFranchiseur(value);
        let labelSelected: any = value.agencyName + '  ' + value.companyName;
        setSelectedLabel(labelSelected);
    }
    const handleFinalFormSubmit = () => {
        let newCommandeFournisseur = {
            fournisseur: fournisseurRadioOn ? fournisseur : undefined,
            franchiseur: franchiseurRadioOn ? franchiseur : undefined,
            network: network
        };
        create(newCommandeFournisseur);
    }

    return (
        <Segment raised >
            <FinalForm
                onSubmit={handleFinalFormSubmit}
                render={({ handleSubmit, submitting }) => (
                    <Form onSubmit={handleSubmit} error >
                        <Accordion fluid styled>
                            <Accordion.Content style={{ padding: '20px 10% 20px 10%' }}>
                                <h2 style={{ textAlign: 'center', paddingBottom: 10 }}>Ajouter une commande d'achat</h2>
                                <Form.Group >
                                    <h5>Type:</h5>
                                    <label style={{ marginLeft: 20 }}>
                                        <input
                                            defaultChecked={true}
                                            type="radio"
                                            name='radioGroup'
                                            value='Fournisseur'
                                            onClick={() => { setFournisseurRadioOn(true); setFranchiseurRadioOn(false); setFranchiseur(null) }}
                                        />  Fournisseur
                                    </label>
                                    <label style={{ marginLeft: 20 }}>
                                        <input
                                            style={{ marginLeft: 10 }}
                                            type="radio"
                                            name='radioGroup'
                                            value='Franchiseur'
                                            onClick={() => { setFournisseurRadioOn(false); setFranchiseurRadioOn(true); setFournisseur(0) }}
                                        />  Franchiseur
                                    </label>
                                </Form.Group>
                                {fournisseurRadioOn &&
                                    <Grid style={{ padding: 0, margin: 0 }}>
                                        <Grid.Column width={12} style={{ padding: 0, margin: 0, paddingRight: 20 }}>
                                        <h5>Fournisseur</h5>
                                        <AsyncSelect
                                            name="form-field-name"
                                            placeholder="Fournisseur"
                                            noOptionsMessage={() => "Code introuvable"}
                                            cacheOptions
                                            defaultOptions
                                            getOptionLabel={e => e!.text}
                                            getOptionValue={e => e!.value}
                                            loadOptions={baseStore.fournisseurStore.loadFournisseurOptions}
                                            onChange={(e) => setFournisseur(parseInt(e!.value))}
                                        />
                                        </Grid.Column>
                                        <Grid.Column width={4} style={{ padding: 0, margin: 0 }}>
                                            <h5>Réseau</h5>
                                            <Input type='text' placeholder="Réseau" onChange={(e) => setNetWork(e.target.value)} />
                                        </Grid.Column>
                                    </Grid>

                                }
                                {franchiseurRadioOn &&
                                    <div className="field">
                                        <h5>Franchiseur </h5>
                                        <AsyncSelect
                                            name="form-field-name"
                                            placeholder={selectedLabel}
                                            noOptionsMessage={() => "Code introuvable"}
                                            cacheOptions
                                            defaultOptions
                                            getOptionLabel={e => e!.text}
                                            value={franchiseur}
                                            loadOptions={baseStore.franchiseurStore.loadCompanyOptions}
                                            onChange={(e) => { setFranchiseur(e?.value); setSelectedLabel(e?.text) }}
                                        />
                                    </div>}
                                <Form.Group style={{ display: "inline-flex" }}>
                                    <Button disabled={submitting} onClick={() => history.push('/commandeFournisseurDashboard')} type='button'>Annuler</Button>
                                    <Button positive disabled={fournisseurRadioOn ? fournisseur == 0 : franchiseur == null} loading={submitting} type='submit'>Confirmer</Button>
                                </Form.Group>
                            </Accordion.Content >
                        </Accordion>
                    </Form>
                )}
            />
        </Segment >
    );
}
export default observer(AddCommandeFournisseurContent);
