import React, { Fragment, useState, useEffect } from 'react';
import { Header, Grid, Button } from 'semantic-ui-react';
import { observer } from 'mobx-react-lite';
import VideoWidgetDropzone from './VideoWidgetDropzone';

interface IProps {
    loading: boolean;
    uploadVideo: (photo: Blob) => void;
}
const VideoUploadWidget: React.FC<IProps> = ({ loading, uploadVideo }) => {

    const [files, setFiles] = useState<any[]>([]);
    const FichierPdf = '/assets/video.png';
    useEffect(() => {
        return () => {
            files.forEach(file => URL.revokeObjectURL(file.preview));
        };
    });

    return (
        <Fragment>
            <Grid>
                <Grid.Column width={8}>
                    <Header color='orange' sub content='Step 1 - Ajouter votre vidéo' style={{ textAlign: 'center' }} />
                    <VideoWidgetDropzone setFiles={setFiles} />
                </Grid.Column>
                <Grid.Column width={8}>
                    <Header sub color='orange' content='Step 2 - Charger' style={{ textAlign: 'center' }} />
                    {files.length > 0 &&
                        <Fragment>

                        <img className='img-preview' style={{ maxHeight: '110px', overflow: 'hidden', marginLeft: '40%', marginBottom: '1%' }} src={FichierPdf} />
                            <div style={{ textAlign: 'center', fontSize: 'medium', fontWeight: 'bold', width: "100%", textOverflow: "ellipsis", overflow: "hidden", whiteSpace: "nowrap" }}>
                                <label >{files[0].name!}</label>
                            </div>
                            <br />
                            <Button.Group widths={2} style={{ width: '40%', marginLeft: '30%', marginTop: '1%' }}>
                            <Button positive icon='check' loading={loading} disabled={loading} onClick={() => uploadVideo(files[0]!)} />
                                <Button negative icon='close' disabled={loading} onClick={() => setFiles([])} />
                            </Button.Group>
                        </Fragment>
                    }
                </Grid.Column>
                <Grid.Column width={6} />
            </Grid>
        </Fragment>
    );
};

export default observer(VideoUploadWidget);
