import React, { useContext, useEffect, useState } from 'react';
import { Table, Header, Button, Segment, Grid, Item, Form, Input, Modal, Icon } from 'semantic-ui-react';
import { observer } from 'mobx-react-lite';
import { BaseStoreContext } from '../../app/store/baseStore';
import { RouteComponentProps } from 'react-router-dom';
import Breadcrumbs from '../dashboard/breadcrumbs';
import { IDetailExpedition } from '../../app/models/IExpedition';
import { VscFilePdf } from 'react-icons/vsc';
import { Link } from 'react-router-dom';
import BonDeRetour from './BonDeRetour';
import TextInput from '../../app/common/form/inputText';
import { values } from 'mobx';
import { FiEdit, FiCheckSquare } from 'react-icons/fi';
import { MdOutlineCancelPresentation } from 'react-icons/md';
import UpdateOrderLineDatesModal from './UpdateOrderLineDatesModal';
import DeleteOrderDispatchModal from './DeleteOrderDispatchModal';
import CancelBL from './CancelBL';
import ListItemPlaceholder from '../../app/common/form/ListItemPlaceholder';

const getStatus = (status: string) => {
    switch (status) {
        case 'EnAttente':
            return 'En Attente';
        case 'EnCours':
            return 'Reliquat';
        case 'Termine':
            return 'Termine';
        case 'Expedie':
            return 'Expediée';
        case 'Livre':
            return 'Livrée';
        case 'Facture':
            return 'Facturée';
        case 'Retour':
            return 'Retour';
        default:
            return '';
    }
}

function exampleReducer(state: any, action: any) {
    switch (action.type) {
        case 'close':
            return { open: false }
        case 'open':
            return { open: true, size: action.size }
        default:
            throw new Error('Unsupported action...')
    }
}
interface RouteParams {
    id: string
}

interface IProps extends RouteComponentProps<RouteParams> { }
const ListExpeditionLines: React.FC<IProps> = ({ match }) => {

    const baseStore = useContext(BaseStoreContext);
    const { ITList, loadExpeditionLineByIdExpedition, loadingInitialExpedition, orderDispatch, downloading, generatePDF, downloadBLValorisé,
        loadEntrepotByPreparationLine, SortirReiliquats, loadingReliquat, edit, validate, editDispatchDate, editNbrColis, deletingOrder, loadBtnCancel } = baseStore.expeditionStore;
    const { openModal } = baseStore.modalStore;
    const [openn, setOpenn] = useState(false);
    const [editForm, setEditForm] = useState(false);
    const [editTarget, setEditTarget] = useState<number | undefined>(undefined);
    const [quantiteLivree, setQuantiteLivree] = useState<number>(0);
    const [editDate, setEditDate] = useState<boolean>(false);
    const [editColis, setEditColis] = useState<boolean>(false);
    const [date, setDate] = useState<string>(new Date().toISOString());
    const [nbrColis, setNbrColis] = useState<number>(orderDispatch?.nbrColis!);
    const [deleteTarget, setDeleteTarget] = useState<string | undefined>(undefined);
    const [modalOpen, setModalOpen] = useState(false);

    const handleFinalFormSubmit = () => {
        let newMode = {
            ...values,
            quantiteLivree: quantiteLivree,
            id: editTarget,
            orderDispatch: parseInt(match.params.id),
        };
        edit(newMode);
        setEditForm(false)
    }

    useEffect(() => {
        loadEntrepotByPreparationLine(parseInt(match.params.id));
        loadExpeditionLineByIdExpedition(parseInt(match.params.id));
    }, [])

    const handleChangeDateDispatch = (e: any) => {
        let obj: Partial<any> = {
            id: orderDispatch?.id,
            dispatchDate: date,
            modeAppro: orderDispatch!.modeAppro
        };
        editDispatchDate(obj)
        setEditDate(false)
    }

    const handleChangeNbrColisDispatch = (e: any) => {
        let obj: Partial<any> = {
            id: orderDispatch?.id,
            nbrColis: nbrColis,
        };
        editNbrColis(obj)
        setEditColis(false)
    }
    //const handlePDF = () => {
    //    if (depot == 0)
    //        return (toast.warning("Veuillez selectionner un entrepot!!!"));
    //    generatePDF(parseInt(match.params.id));
    //    dispatch({ type: 'close' });
    //}

    return (
        <>
            <BonDeRetour open={openn} setOpen={setOpenn} id={match.params.id} />
            <Breadcrumbs
                items={[
                    { key: 0, name: "Expédition", root: "" },
                    { key: 1, name: "Expédition de commande", root: "/expeditionDashboard" },
                    { key: 1, name: `Expédition N°${match.params.id}`, root: `/detailExpedition${match.params.id}` },
                ]}
            />
            <Grid>
                <Grid.Row style={{ paddingTop: 0 }}>
                    <Grid.Column width={16} style={{ paddingTop: 0 }}>
                        <Grid>
                            <Grid.Column width={16}>
                                <Grid>
                                    <Grid.Column width={16}>
                                        <Button
                                            floated="right"
                                            color="orange"
                                            icon='arrow alternate circle left outline'
                                            content="Retour"
                                            size="tiny"
                                            basic
                                            as={Link}
                                            to="/expeditionDashboard"
                                        />
                                        {orderDispatch?.isCanceled == false && orderDispatch?.dispatchState != "Facture" &&
                                            <Button
                                                floated='right'
                                                size="tiny"
                                                color={'red'}
                                                icon='trash'
                                                content={"Supprimer"}
                                                onClick={(e) => {
                                                    openModal(<DeleteOrderDispatchModal commande={orderDispatch!} />)
                                                    setDeleteTarget(e.currentTarget.name)
                                                }}
                                                loading={deletingOrder && deleteTarget === orderDispatch?.id.toString()}
                                            />
                                        }
                                        <Button
                                            style={{ display: orderDispatch?.dispatchState != "Facture" && orderDispatch?.isCanceled == false ? 'block' : 'none' }}
                                            floated="right"
                                            content="Annuler"
                                            loading={loadBtnCancel}
                                            color='red'
                                            size="tiny"
                                            basic
                                            cursor="pointer"
                                            onClick={(e) => {
                                                openModal(<CancelBL BL={orderDispatch!} />)
                                            }} />


                                        <Button
                                            style={{ display: orderDispatch?.dispatchState != "Facture" && orderDispatch?.isCanceled == true ? 'block' : 'none' }}
                                            floated="right"
                                            content="Activer"
                                            loading={loadBtnCancel}
                                            color='green'
                                            size="tiny"
                                            basic
                                            cursor="pointer"
                                            onClick={(e) => {
                                                openModal(<CancelBL BL={orderDispatch!} />)
                                            }} />
                                    </Grid.Column>
                                </Grid>
                                {loadingInitialExpedition ?
                                    <ListItemPlaceholder />
                                    : <>
                                        <Segment style={{ margin: "20px" }}>
                                            <Grid columns={16}>
                                                <Grid.Column width={12}>
                                                    <Item.Group style={{ padding: 20 }}>
                                                        <Item>
                                                            <Item.Image
                                                                size='small'
                                                                src={orderDispatch?.uri}
                                                                style={{ marginRight: 20 }}
                                                            />
                                                            {orderDispatch?.typee == 'BL' ? (
                                                                <div>
                                                                    <Header as='h3'>BL N°: {orderDispatch.reference} </Header>
                                                                    <Header as='h5'>Date BL: {orderDispatch?.dateCreation ? new Date(orderDispatch?.dateCreation!).toLocaleDateString('en-GB') : null}</Header>
                                                                </div>
                                                            ) :
                                                                (
                                                                    <Header as='h3'>BR N°: {match.params.id} </Header>

                                                                )}
                                                            {orderDispatch?.refOrder &&
                                                                <div style={{ borderRadius: 5, marginLeft: 50, padding: 5, textAlign: 'center', width: 240, border: '1px solid grey' }}>
                                                                    <div>
                                                                        <Header as='h3' style={{ margin: 0 }}>{orderDispatch?.refOrder}</Header>
                                                                        <Header as='h4' style={{ margin: 0 }}>{orderDispatch?.codeClient} - {orderDispatch?.companyName}</Header>
                                                                    </div>
                                                                </div>
                                                            }
                                                            <Button
                                                                content="PDF valorisé"
                                                                color={'green'}
                                                                size="small"

                                                                style={{ right: 40, position: "absolute", cursor: 'pointer', fontSize: 12, fontWeight: 700 }}
                                                                onClick={() => downloadBLValorisé(parseInt(match.params.id))}
                                                                loading={downloading}
                                                            />
                                                            <VscFilePdf
                                                                title="Télécharger PDF"
                                                                color={baseStore.expeditionStore.generatePdfMode ? 'orange' : 'green'}
                                                                onMouseEnter={() => {
                                                                    baseStore.expeditionStore.changeButtonPdfState(!baseStore.expeditionStore.generatePdfMode);
                                                                }}
                                                                onMouseLeave={() => {
                                                                    baseStore.expeditionStore.changeButtonPdfState(!baseStore.expeditionStore.generatePdfMode);
                                                                }}
                                                                style={{ right: 0, position: "absolute", fontSize: "30px", cursor: 'pointer', content: "dddd" }}
                                                                onClick={() => generatePDF(parseInt(match.params.id))}
                                                            />
                                                        </Item>
                                                        {/*<Item>*/}
                                                        {/*    <Item.Content verticalAlign='middle'>*/}
                                                        {/*        <Header as='h3'>{orderDispatch?.refOrder}</Header>*/}
                                                        {/*    </Item.Content>*/}
                                                        {/*</Item>*/}
                                                        {/*<Item>*/}
                                                        {/*    <Item.Content verticalAlign='middle'>*/}
                                                        {/*        <Header as='h4'>{orderDispatch?.companyName}</Header>*/}
                                                        {/*    </Item.Content>*/}
                                                        {/*</Item>*/}
                                                        <Item>
                                                            <Item.Content verticalAlign='middle'>
                                                                {orderDispatch?.dispatchDate &&
                                                                    <>
                                                                        <Header as='h5'>Date de livraison: </Header>
                                                                        {editDate ?
                                                                            (
                                                                                <>
                                                                                    <Input name='creationDate' onChange={(e: any) => setDate(e.target.value)} value={date!} style={{ marginLeft: 10, height: 30, width: 150 }} type='date' />
                                                                                    <FiCheckSquare color="green" style={{ marginLeft: 10, fontSize: 18 }} onClick={(e) => handleChangeDateDispatch(e)} />
                                                                                    <MdOutlineCancelPresentation color="red" style={{ marginLeft: 10, fontSize: 18 }} onClick={(e) => setEditDate(false)} />
                                                                                </>
                                                                            ) : (
                                                                                <>
                                                                                    <b style={{ marginLeft: 10 }}>{new Date(orderDispatch?.dispatchDate!).toLocaleDateString('en-GB')}</b>
                                                                                    <FiEdit style={{ marginLeft: 10 }} onClick={(e) => setEditDate(true)} />
                                                                                </>
                                                                            )
                                                                        }
                                                                    </>
                                                                }
                                                            </Item.Content>
                                                            <Item.Content verticalAlign='middle'>
                                                                {orderDispatch?.nbrColis != undefined &&
                                                                    <>
                                                                        <Header as='h5' ><span style={{ color: "#db2828" }}>Nombre de Colis: </span></Header>
                                                                        {editColis ?
                                                                            (
                                                                                <>
                                                                                    <Input name='nbrColis' onChange={(e: any) => setNbrColis(e.target.value)} defaultValue={orderDispatch?.nbrColis} type='number' min={1} style={{ marginLeft: 10, height: 25, width: 70 }} />
                                                                                    <FiCheckSquare color="green" style={{ marginLeft: 10, fontSize: 18 }} onClick={(e) => (handleChangeNbrColisDispatch(e))} />
                                                                                    <MdOutlineCancelPresentation color="red" style={{ marginLeft: 10, fontSize: 18 }} onClick={(e) => setEditColis(false)} />
                                                                                </>
                                                                            ) : (
                                                                                <>
                                                                                    <b style={{ marginLeft: 10, color: "#db2828" }}>{orderDispatch?.nbrColis}</b>
                                                                                    <FiEdit style={{ marginLeft: 10, color: "#db2828" }} onClick={(e) => setEditColis(true)} />
                                                                                </>
                                                                            )
                                                                        }
                                                                    </>
                                                                }
                                                            </Item.Content>

                                                        </Item>
                                                    </Item.Group>

                                                </Grid.Column>
                                                <Grid.Column width={4}>
                                                    {/*<Button*/}
                                                    {/*    style={{ display: orderDispatch?.dispatchState! === "Expedie" ? 'block' : 'none', fontSize: "15px" }}*/}
                                                    {/*    floated="right"*/}
                                                    {/*    content="Génerer Facture"*/}
                                                    {/*    color='orange'*/}
                                                    {/*    onClick={() => OrderDispatchFacture(parseInt(match.params.id))}*/}
                                                    {/*/>*/}
                                                    {orderDispatch?.typee == "BL" && orderDispatch?.isCanceled == false ? (
                                                        <Button
                                                            style={{ display: orderDispatch?.dispatchState! === "Livre" || orderDispatch?.dispatchState! === "Facture" ? 'block' : 'none', fontSize: "15px" }}
                                                            content="Bon de retour"
                                                            color='purple'
                                                            floated="right"
                                                            cursor="pointer"
                                                            icon='dolly'
                                                            onClick={() => { setOpenn(true) }}
                                                        />
                                                    ) : (<></>)}
                                                    {/*<Button*/}
                                                    {/*    color="orange"*/}
                                                    {/*    floated="right"*/}
                                                    {/*    cursor="pointer"*/}
                                                    {/*    content=' Facturer'*/}
                                                    {/*    style={{ width: 82, height: 40, paddingRight: 5, paddingLeft: 5, fontSize: 14, display: (orderDispatch?.dispatchState === "Expedie" && orderDispatch.isBilled == false && orderDispatch?.typee == "BL" && orderDispatch?.isCanceled == false) ? 'block' : 'none' }}*/}
                                                    {/*    icon={<FaFileInvoice style={{ fontSize: 10 }} />}*/}
                                                    {/*    onClick={*/}
                                                    {/*        () => baseStore.expeditionStore.OrderDispatchFacture(parseInt(match.params.id))}*/}
                                                    {/*/>*/}

                                                    {/*<Button*/}
                                                    {/*    color="orange"*/}
                                                    {/*    floated="right"*/}
                                                    {/*    cursor="pointer"*/}
                                                    {/*    content=' Facture Avoir'*/}
                                                    {/*    style={{ width: 100, paddingRight: 5, paddingLeft: 5, fontSize: 11, display: (orderDispatch?.dispatchState === "Livre" && orderDispatch?.typee == "BR" && orderDispatch?.isCanceled == false) ? 'block' : 'none' }}*/}
                                                    {/*    icon={<FaFileInvoice style={{ fontSize: 10 }} />}*/}
                                                    {/*    onClick={*/}
                                                    {/*        () => baseStore.expeditionStore.OrderDispatchFacture(parseInt(match.params.id))}*/}
                                                    {/*/>*/}


                                                    {/*<Button*/}
                                                    {/*    color="green"*/}
                                                    {/*    floated="right"*/}
                                                    {/*    cursor="pointer"*/}
                                                    {/*    content='Livrée'*/}
                                                    {/*    style={{ width: 70, paddingRight: 5, paddingLeft: 5, fontSize: 11, display: orderDispatch?.dispatchState === "Expedie" ? 'block' : 'none' }}*/}
                                                    {/*    icon='dolly'*/}
                                                    {/*    onClick={*/}
                                                    {/*        () => baseStore.expeditionStore.editStatusOrderDispatch(parseInt(match.params.id))*/}
                                                    {/*    }*/}
                                                    {/*/>*/}
                                                    <Button
                                                        color="orange"
                                                        cursor="pointer"
                                                        floated="right"
                                                        content='Expedier tous'
                                                        style={{ width: 100, paddingRight: 5, paddingLeft: 5, fontSize: 11, display: orderDispatch?.dispatchState === "EnAttente" && orderDispatch?.isCanceled == false ? 'block' : 'none' }}
                                                        onClick={
                                                            () => baseStore.expeditionStore.valideTous(parseInt(match.params.id))
                                                        }
                                                    />
                                                    {orderDispatch?.isCanceled == false && orderDispatch?.dispatchState != "Facture" && orderDispatch &&
                                                        <Button
                                                            cursor="pointer"
                                                            floated="right"
                                                            content='Changer dates'
                                                            style={{ backgroundColor: "#4682b4", color: "White", position: "absolute", bottom: 0, right: 0, width: 100, paddingRight: 5, paddingLeft: 5, marginBottom: 8, marginRight: 16, fontSize: 11, display: orderDispatch?.dispatchState === "EnAttente" || "EnCours" ? 'block' : 'none' }}
                                                            onClick={
                                                                () => { openModal(<UpdateOrderLineDatesModal id={orderDispatch!.id} modeAppro={orderDispatch!.modeAppro} lines={ITList} />) }
                                                            }
                                                        />
                                                    }
                                                    {orderDispatch?.isBilled == false && ITList.filter(x => x.quantiteLivree != x.quantiteALivree).length > 0 &&
                                                        <Button
                                                            cursor="pointer"
                                                            floated="right"
                                                            color="green"
                                                            loading={loadingReliquat}
                                                            content='Sortir reliquats'
                                                            style={{ color: "White", width: 100, paddingRight: 5, paddingLeft: 5, fontSize: 12 }}
                                                            onClick={() => setModalOpen(true)}
                                                        />
                                                    }
                                                </Grid.Column>
                                            </Grid>
                                        </Segment >
                                        <div style={{ margin: "20px" }}>
                                            <Table compact='very'>
                                                <Table.Header>
                                                    <Table.Row>
                                                        <Table.HeaderCell>Code Article</Table.HeaderCell>
                                                        <Table.HeaderCell>Article</Table.HeaderCell>
                                                        <Table.HeaderCell>Entrepot</Table.HeaderCell>
                                                        <Table.HeaderCell>Qt à livrer</Table.HeaderCell>
                                                        <Table.HeaderCell>Qt livrée</Table.HeaderCell>
                                                        <Table.HeaderCell>Qt Rest à livrer</Table.HeaderCell>
                                                        <Table.HeaderCell>Etat</Table.HeaderCell>
                                                        <Table.HeaderCell></Table.HeaderCell>
                                                    </Table.Row>
                                                </Table.Header>
                                                <Table.Body>
                                                    {ITList && ITList.map((stock: IDetailExpedition) => (
                                                        <>
                                                            <Table.Row key={stock.id}>
                                                                {editForm && editTarget === stock.id ? (
                                                                    <>
                                                                        <Table.Cell>{stock.articleId}</Table.Cell>
                                                                        <Table.Cell>{stock.articleLibelle}</Table.Cell>
                                                                        <Table.Cell>{stock.entrepotsLibelle}</Table.Cell>
                                                                        <Table.Cell>{stock.quantiteALivree}</Table.Cell>
                                                                        <Table.Cell selectable>
                                                                            <Form.Input name='quantiteLivree' type='number' component={TextInput} placeholder='quantiteLivree' max={stock.quantiteALivree} defaultValue={quantiteLivree} style={{ width: '100%', padding: '1%' }} onChange={(e) => setQuantiteLivree(parseInt(e.target.value))} />
                                                                        </Table.Cell>
                                                                        <Table.Cell>{stock.quantiteResteLivre}</Table.Cell>
                                                                        <Table.Cell style={{ color: 'rgb(245,128,39)', fontWeight: 'bold' }}>{getStatus(stock.dispatchLineState)}</Table.Cell>
                                                                        {orderDispatch?.dispatchState != 'Facture' &&
                                                                            <Table.Cell >
                                                                                <Button.Group fluid widths={2}>
                                                                                    <Button
                                                                                        type='button'
                                                                                        size='tiny'
                                                                                        onClick={() => {
                                                                                            setEditForm(false);
                                                                                        }}>
                                                                                        Annuler
                                                                                    </Button>
                                                                                    <Button.Or text='Ou' />
                                                                                    <Button
                                                                                        name={stock.id}
                                                                                        positive
                                                                                        type='submit'
                                                                                        size='tiny'
                                                                                        disabled={stock.quantiteLivree == quantiteLivree}
                                                                                        onClick={(e) => {
                                                                                            handleFinalFormSubmit()
                                                                                            setEditTarget(parseInt(e.currentTarget.name))
                                                                                        }}
                                                                                    >Confirmer
                                                                                    </Button>
                                                                                </Button.Group>
                                                                            </Table.Cell>
                                                                        }
                                                                    </>
                                                                ) : (
                                                                    <>
                                                                        <Table.Cell >{stock.articleId}</Table.Cell>
                                                                        <Table.Cell>
                                                                            <span>
                                                                                <div >
                                                                                    <a style={{ backgroundColor: 'none' }} href={`/detailArticle/${stock.articleId}`}>
                                                                                        {stock.articleLibelle}
                                                                                    </a>
                                                                                        <div>
                                                                                            {stock.orderPacks &&
                                                                                                stock.orderPacks.map(pack => (
                                                                                                <>

                                                                                                        <label style={{ paddingLeft: 20 }}>{pack.productId} - {pack.label} (Qte: {(quantiteLivree == 0 ? pack.quantity * stock.quantiteALivree : pack.quantity * quantiteLivree) && (stock.quantiteLivree == 0 ? stock.quantiteALivree * 0 : pack.quantity * stock.quantiteLivree)})</label>
                                                                                                            <br />
                                                                                                </>
                                                                                            ))}
                                                                                    </div>

                                                                                    <div>
                                                                                        {stock.productAttributeValues.map(productAttributeValue => (
                                                                                            <>
                                                                                                <label style={{ fontSize: 12, paddingLeft: 30 }}>
                                                                                                    <b>{productAttributeValue.productAttributeName} :</b>
                                                                                                    {productAttributeValue.value}</label>
                                                                                            </>
                                                                                        ))
                                                                                        }
                                                                                    </div>
                                                                                </div>
                                                                            </span>
                                                                        </Table.Cell>
                                                                        <Table.Cell>{stock.entrepotsLibelle}</Table.Cell>
                                                                        <Table.Cell>{stock.quantiteALivree}</Table.Cell>
                                                                        <Table.Cell>{stock.quantiteLivree}</Table.Cell>
                                                                            <Table.Cell>{stock.quantiteResteLivre}</Table.Cell>
                                                                            <Table.Cell style={{ color: 'rgb(245,128,39)', fontWeight: 'bold' }}>{getStatus(stock.dispatchLineState) == "Expediée" && stock.quantiteLivree < stock.quantiteALivree ? "Reliquat" :  getStatus(stock.dispatchLineState)}</Table.Cell>
                                                                        {orderDispatch?.dispatchState != 'Facture' && orderDispatch?.isCanceled == false &&
                                                                            <Table.Cell style={{ display: orderDispatch?.dispatchState != 'Retour' ? "block" : "none" }} >
                                                                                <Button.Group fluid widths={2}>
                                                                                    <Button
                                                                                        onClick={(e) => {
                                                                                            setEditForm(true);
                                                                                            setEditTarget(parseInt(e.currentTarget.name))
                                                                                            setQuantiteLivree(stock.quantiteALivree >= stock.quantiteLivree ? stock.quantiteALivree : stock.quantiteLivree)
                                                                                        }}
                                                                                        color="blue"
                                                                                        icon='edit outline'
                                                                                        size='tiny'
                                                                                        style={{ marginRight: '3%' }}
                                                                                        name={stock.id}
                                                                                    />
                                                                                </Button.Group>
                                                                            </Table.Cell>
                                                                        }
                                                                    </>
                                                                )}
                                                            </Table.Row>

                                                        </>

                                                    ))}
                                                </Table.Body>
                                            </Table>
                                            {orderDispatch?.dispatchState != 'Facture' && orderDispatch?.isCanceled == false ? (
                                                <div style={{ paddingBottom: "60px" }}>
                                                    <Button positive
                                                        style={{ display: orderDispatch?.dispatchState == 'Termine' || orderDispatch?.dispatchState == 'EnCours' ? "block" : "none" }}
                                                        floated='right'
                                                        loading={validate}
                                                        onClick={() => baseStore.expeditionStore.editStatusOrderDispatchLine(parseInt(match.params.id))}>
                                                        Valider
                                                    </Button> </div>) : (<></>)}

                                        </div>
                                    </>
                                }


                                <Modal
                                    open={modalOpen}
                                    size='tiny'
                                    closeOnEscape={true}
                                    closeOnRootNodeClick={true}
                                >
                                    <Modal.Header icon='add' style={{ marginLeft: 20 }} >
                                        Séléctionnez la date du bon de livraison
                                    </Modal.Header>
                                    <Modal.Content style={{ textAlign: "center" }}>
                                        <Input name='creationDate' onChange={(e: any) => setDate(e.target.value)} value={date} style={{ height: 50, borderRadius: 4, color: "green", width: 250, border: "1px solid #ccc" }} type='date' />
                                    </Modal.Content>
                                    <Modal.Actions style={{ display: 'grid' }}>
                                        <div style={{ padding: 15 }}>
                                            <Button.Group floated="right">
                                                <Button
                                                    type='button'
                                                    floated='right'
                                                    onClick={() => {
                                                        setModalOpen(false)
                                                    }}
                                                >
                                                    <Icon name='remove' /> Annuler
                                                </Button>
                                                <Button.Or text='Ou' />
                                                <Button
                                                    disabled={false}
                                                    positive
                                                    loading={loadingReliquat}
                                                    type='submit'
                                                    floated='right'
                                                    onClick={(e) => {
                                                        setModalOpen(false);
                                                        SortirReiliquats(orderDispatch!.id, date)
                                                    }}
                                                >
                                                    <Icon name='checkmark' /> Confirmer
                                                </Button>
                                            </Button.Group>
                                        </div>
                                    </Modal.Actions>
                                </Modal>
                            </Grid.Column>
                        </Grid>
                    </Grid.Column>
                </Grid.Row>

            </Grid>

        </>
    );
}
export default observer(ListExpeditionLines);