import React, { useContext, useState } from 'react';
import { Tab, Header, Image, Button, Grid } from 'semantic-ui-react';
import { BaseStoreContext } from '../../../app/store/baseStore';
import PhotoUploadWidget from '../../../app/common/photoUpload/PhotoUploadWidget';
import { observer } from 'mobx-react-lite';
import VideoUploadWidget from '../../../app/common/videoUpload/VideoUploadWidget';
import ConfirmDeleteModal from './ConfirmDeleteModal';

const ArticlePhoto = () => {
    const baseStore = useContext(BaseStoreContext)
    const { article, uploadVideo, uploadingVideo, updatePhoto, uploadPhoto, uploadingPhoto, setMainPhoto, loadingPhotoAction, deletingFile } = baseStore.articleStore;
    const [addPhotoMode, setAddPhotoMode] = useState(false);
    const [addVideoMode, setAddVideoMode] = useState(false);
    const [updatePhotoMode, setUpdatePhotoMode] = useState(false);
    const [target, setTarget] = useState<string | undefined>(undefined);
    const [deleteTarget, setDeleteTarget] = useState<string | undefined>(undefined);
    const [updateTarget, setUpdateTarget] = useState<string | undefined>(undefined);
    const { openModal } = baseStore.modalStore;

    const handleUploadImage = (photo: Blob, id = article!.productId) => {
        uploadPhoto(photo, id).then(() => setAddPhotoMode(false));
    }
    const handleUploadVideo = (video: Blob, id = article!.productId) => {
        uploadVideo(video, id).then(() => setAddVideoMode(false));
    }
    const handleUpdateImage = (photo: Blob, id = article!.productId) => {
        updatePhoto(photo, id, updateTarget!).then(() => setUpdatePhotoMode(false));
    }

    return (
        <Tab.Pane>
            <Grid>
                <Grid.Column width={16} style={{ paddingBottom: 0 }}>
                    <Header floated='left' icon='image' content='Logo' />
                    {!updatePhotoMode ?
                        (<>
                            <Button
                                onClick={() => setAddPhotoMode(!addPhotoMode)}
                                floated='right'
                                basic
                                content={addPhotoMode ? 'Annuler' : 'Ajouter Logo'}
                            />
                            <Button
                                onClick={() => setAddVideoMode(!addVideoMode)}
                                floated='right'
                                basic
                                content={addVideoMode ? 'Annuler' : 'Ajouter Video'}
                            />
                        </>) : (
                            <Button
                                onClick={() => setUpdatePhotoMode(false)}
                                floated='right'
                                basic
                                content={'Annuler'}
                            />)}
                </Grid.Column>
                <Grid.Column width={16}>
                    {addPhotoMode ? (
                        <PhotoUploadWidget
                            uploadPhoto={handleUploadImage}
                            updatePhoto={handleUpdateImage}
                            loading={uploadingPhoto}
                            isUpdate={false}
                        />
                    ) : (addVideoMode ? (
                        <VideoUploadWidget
                            uploadVideo={handleUploadVideo}
                            loading={uploadingVideo}
                        />
                    ) : (
                        updatePhotoMode ? (
                            <PhotoUploadWidget
                                uploadPhoto={handleUploadImage}
                                updatePhoto={handleUpdateImage}
                                loading={uploadingPhoto}
                                isUpdate={true}
                            />) : (
                            <Grid>
                                <Grid.Row columns={5} style={{ paddingTop: 0 }}>
                                    {article &&
                                        article.articlePhotos.map(photo => (
                                            <Grid.Column key={photo.id}>
                                                <Image src={photo.url} />
                                                {(
                                                    <Button.Group fluid size="tiny" widths={3}>
                                                        <Button
                                                            onClick={e => {
                                                                setMainPhoto(photo, article!.productId);
                                                                setTarget(e.currentTarget.name);
                                                            }}
                                                            name={photo.id}
                                                            disabled={photo.isMain}
                                                            loading={loadingPhotoAction && target === photo.id}
                                                            basic
                                                            positive
                                                            content='Eco'
                                                        />
                                                        <Button
                                                            name={photo.id}
                                                            onClick={(e) => {

                                                                setUpdateTarget(e.currentTarget.name)
                                                                setUpdatePhotoMode(true)
                                                            }}
                                                            loading={loadingPhotoAction && deleteTarget === photo.id}
                                                            basic
                                                            color="grey"
                                                            icon='edit'
                                                        />
                                                        <Button
                                                            name={photo.id}
                                                            onClick={(e) => {
                                                                openModal(<ConfirmDeleteModal photo={photo} productId={article!.productId} type="photo" />)
                                                                setDeleteTarget(e.currentTarget.name)
                                                            }}
                                                            loading={loadingPhotoAction && deleteTarget === photo.id}
                                                            basic
                                                            negative
                                                            icon='trash'
                                                        />
                                                    </Button.Group>
                                                )}
                                            </Grid.Column>
                                        ))}
                                </Grid.Row>
                                <Grid.Row columns={2} style={{ padding: 0 }}>
                                    {article &&
                                        article.videos.map(video => (
                                            <Grid.Column style={{
                                                textAlign: "center", display: 'sticky'
                                            }} key={video.id}>
                                                <div >
                                                    <iframe style={{ width: '80%', height: 200 }} src={video.urlVideo} allowFullScreen></iframe>
                                                </div>
                                                <Button
                                                    name={video.id}
                                                    onClick={(e) => {
                                                        setDeleteTarget(e.currentTarget.name);
                                                        openModal(<ConfirmDeleteModal video={video} productId={article!.productId} type="vidéo" />)
                                                    }}
                                                    loading={deletingFile && deleteTarget === video.id.toString()}
                                                    basic
                                                    style={{ position: "absolute", top: 0, right: 0 }}
                                                    negative
                                                    icon='trash'
                                                />
                                            </Grid.Column>
                                        ))}
                                </Grid.Row>
                            </Grid>

                        )))}
                </Grid.Column>
            </Grid>
        </Tab.Pane>
    );
};
export default observer(ArticlePhoto);
